import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  FormControl,
  TextField,
  Button,
  Typography,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import { shapeUserCode, errorMessage, validEmail } from './tools';
import { AlertMessage } from './Alert';

const str = (text?: string | null) => (text ? String(text) : '');

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  dialog: {
    height: '100%',
    width: '100%',
    maxWidth: 'initial',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  text_area: {
    height: 240,
  },
}));

interface ForgotPasswordDialogProps {
  open: boolean;
  onClose: () => void;
  setMessages: React.Dispatch<AlertMessage>;
}

const ForgotPasswordDialog: React.FC<ForgotPasswordDialogProps> = ({
  open,
  onClose,
  setMessages,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [userCode, setUserCode] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ userCode: '', email: '' });
  const classes = useStyles();

  const sendResetPasswordMail = () => {
    const errs = { userCode: '', email: '' };
    if (!userCode) {
      errs.userCode = '社員番号を入力してください。';
    }
    if (!email) {
      errs.email = 'メールアドレスを入力してください。';
    } else if (!validEmail(email)) {
      errs.email = '有効なメールアドレスを入力してください。';
    }
    setErrors(errs);

    const existErrors = Object.values(errs).filter((err) => !!err).length > 0;
    if (!existErrors) {
      setLoading(true);
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('sendResetPasswordMail');
      func({ userCode: shapeUserCode(userCode), email: email })
        .then((result) => {
          console.log({ result });
          setLoading(false);
          setMessages({
            severity: 'success',
            content: [
              'パスワード再設定用のURLをメールアドレスに送りました。',
              'URLの有効期限は10分間です。',
            ],
          });
          onClose();
        })
        .catch((error) => {
          console.log({ error });
          setLoading(false);
          setMessages({
            severity: 'error',
            content: errorMessage(error),
          });
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth="xs"
      fullWidth
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogContent>
        <Typography variant="caption">
          社員番号および登録済みのメールアドレスを入力してください。
        </Typography>
        <form>
          <FormControl fullWidth>
            <TextField
              label="社員番号"
              value={str(userCode)}
              disabled={loading}
              error={!!errors.userCode}
              helperText={errors.userCode}
              onChange={(e) => setUserCode(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="メールアドレス"
              value={str(email)}
              disabled={loading}
              error={!!errors.email}
              helperText={errors.email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Grid
            container
            direction="column"
            justify="space-around"
            alignItems="stretch"
            style={{ margin: '10px 0' }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={sendResetPasswordMail}
            >
              再設定用のURLをメール送信
            </Button>
          </Grid>
        </form>
        {loading && (
          <Grid container direction="row" justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ForgotPasswordDialog;
