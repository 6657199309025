import React, { useState, useEffect, createContext } from 'react';

import { User, Company, CustomClaims } from './types';
import { userCodeFromEmail, errorMessage } from './tools';
import firebase from './firebase';
import 'firebase/auth';

export interface ContextType {
  currentUser: firebase.User | null;
  user: User | null;
  customClaims: CustomClaims | null;
  companies: { [code: string]: Company };
  passCode: string;
  savePassCode: React.Dispatch<string>;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const AppContext = createContext({
  currentUser: null,
  user: null,
  customClaims: null,
  companies: {},
  passCode: '',
  savePassCode: (code: string) => {},
  setUser: (user: User) => {},
} as ContextType);

export const AppContextProvider: React.FC = (props) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [companies, setCompanies] = useState<{ [code: string]: Company }>({});
  const [customClaims, setCustomClaims] = useState<CustomClaims>({});
  const [passCode, setPassCode] = useState<string>('');

  useEffect(() => {
    const pass_code = window.localStorage.getItem('passCode');
    if (pass_code) setPassCode(pass_code);
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      resetAuth();
      if (user) {
        try {
          const db = firebase.firestore();
          const token = await user.getIdTokenResult();
          setCustomClaims(token.claims);
          const role = token.claims.role;
          const admin_viewer =
            role === 'viewer' || role === 'manager' || role === 'admin';

          const userCode = userCodeFromEmail(user.email || '');
          if (userCode) {
            const snapshot = await db.collection('users').doc(userCode).get();
            const userData = snapshot.data() as User;
            if (userData) {
              setUser(userData);
              if (!userData?.firstSignInAt) {
                await db.collection('users').doc(userCode).update({
                  firstSignInAt: firebase.firestore.FieldValue.serverTimestamp(),
                });
              }
            }
          }
          if (admin_viewer) {
            const query = await db
              .collection('companies')
              .orderBy('code')
              .get();
            let obj: { [code: string]: Company } = {};
            query.forEach((doc) => {
              const company = doc.data() as Company;
              if (company.code) obj[company.code] = company;
            });
            setCompanies(obj);
          }
        } catch (error) {
          console.log({ error });
          alert(errorMessage(error));
        }
      } else {
        // No user is signed in.
        resetAuth();
      }
    });
  }, []);

  const savePassCode = (pass_code: string) => {
    setPassCode(pass_code);
    window.localStorage.setItem('passCode', pass_code);
  };

  const resetAuth = () => {
    setUser(null);
    setCustomClaims({});
    setCompanies({});
  };

  return (
    <AppContext.Provider
      value={{
        currentUser,
        user,
        customClaims,
        companies,
        passCode,
        savePassCode,
        setUser,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;
