import React, { useState, useContext } from 'react';
import {
  AppBar,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Home, Help, MoreVert } from '@material-ui/icons';
import { LogOut } from 'react-feather';
import { Link, useLocation, useHistory } from 'react-router-dom';

import {
  toInternationalPhoneNumber,
  userCodeFromEmail,
  errorMessage,
} from './tools';
import HelpPage from './HelpPage';
import AppContext from './AppContext';
import { User } from './types';
import firebase from './firebase';
import 'firebase/auth';
import './App.css';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  title: {
    marginLeft: theme.spacing(2),
    flexGrow: 1,
    textAlign: 'left',
  },
  white: { color: 'white' },
}));

const TopBar: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [pathname, setPathname] = useState<string>('');
  const { currentUser, user, savePassCode } = useContext(AppContext);
  const { customClaims } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  const admin = customClaims?.role === 'admin';
  const classes = useStyles();

  const showUserInfo = () => {
    if (currentUser) {
      const messages: string[] = [];
      messages.push('社員番号:' + userCodeFromEmail(currentUser.email || ''));
      if (user) {
        messages.push('氏名: ' + fullName(user, true));
        if (user.email) messages.push('連絡先メール: ' + user.email);
      }
      if (currentUser.phoneNumber)
        messages.push('携帯番号:' + currentUser.phoneNumber);
      alert(messages.join('\n'));
    }
  };

  const fullName = (user: User, addCode: boolean, prefix = '') => {
    if (user && user.lastName) {
      let name = `${user.lastName} ${user.firstName}`;
      if (addCode && user.code) name += `(${user.code})`;
      name += prefix;
      return name;
    }
  };

  const logout = () => {
    if (window.confirm('ログアウトしますか？')) {
      savePassCode('');
      history.push('/');
      firebase
        .auth()
        .signOut()
        .catch(function (error) {
          console.log({ error });
          alert(`ログアウトに失敗しました。(${error.code}: ${error.message})`);
        });
    }
  };

  const getAuthUserByCode = () => {
    let code = window.prompt('input userCode');
    if (code) {
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('getAuthUserByCode');
      func({ code })
        .then((result) => {
          console.log({ result });
          if (result.data.result) alert('存在する');
          else alert('存在しない');
        })
        .catch((error) => {
          console.log({ error });
          alert(errorMessage(error));
        });
    }
  };

  const getAuthUserByPhoneNumber = () => {
    let phoneNumber = window.prompt('input PhoneNumber');
    if (phoneNumber) {
      phoneNumber = toInternationalPhoneNumber(phoneNumber);
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('getAuthUserByPhoneNumber');
      func({ phoneNumber })
        .then((result) => {
          console.log({ result });
          if (result.data) alert('存在する');
          else alert('存在しない');
        })
        .catch((error) => {
          console.log({ error });
          alert(errorMessage(error));
        });
    }
  };

  const getAuthUserByEmail = () => {
    let email = window.prompt('input email');
    if (email) {
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('getAuthUserByEmail');
      func({ email })
        .then((result) => {
          console.log({ result });
          if (result.data) alert('存在する');
          else alert('存在しない');
        })
        .catch((error) => {
          console.log({ error });
          alert(errorMessage(error));
        });
    }
  };

  // const deleteUser = () => {
  //   let userCode = window.prompt('input userCode');
  //   if (userCode) {
  //     const func = firebase
  //       .app()
  //       .functions('asia-northeast1')
  //       .httpsCallable('deleteUser');
  //     func({ userCode })
  //       .then((result) => {
  //         console.log({ result });
  //         alert('データを削除しました。');
  //       })
  //       .catch((error) => {
  //         console.log({ error });
  //         alert(errorMessage(error));
  //       });
  //   }
  // };

  // const deleteIncomePaydays = async () => {
  //   const db = firebase.firestore();
  //   const incomePaydays = await db.collectionGroup('incomePaydays').get();
  //   const tasks: any[] = [];
  //   incomePaydays.forEach((doc) =>
  //     tasks.push(
  //       new Promise((resolve) => {
  //         doc.ref.delete().then(() => resolve(true));
  //       })
  //     )
  //   );
  //   await Promise.all(tasks);
  //   alert('complete!');
  // };

  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <Link to="/" className={classes.link}>
          <IconButton aria-label="home">
            <Hidden xsDown>
              <Typography variant="subtitle2" className={classes.white}>
                トップ画面
              </Typography>
            </Hidden>
            <Hidden smUp>
              <Home />
            </Hidden>
          </IconButton>
        </Link>
        <Typography variant="h6" className={classes.title}></Typography>
        {user && (
          <Typography variant="subtitle2">
            {fullName(user, true, '様')}
          </Typography>
        )}
        <IconButton
          aria-label="help"
          onClick={() => setPathname(location.pathname)}
        >
          <Hidden xsDown>
            <Typography variant="subtitle2" className={classes.white}>
              ヘルプ
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Help />
          </Hidden>
        </IconButton>
        <IconButton aria-label="logout" onClick={logout}>
          <Hidden xsDown>
            <Typography variant="subtitle2" className={classes.white}>
              ログアウト
            </Typography>
          </Hidden>
          <Hidden smUp>
            <LogOut />
          </Hidden>
        </IconButton>
        {admin && (
          <>
            <IconButton
              aria-label="display more actions"
              edge="end"
              color="inherit"
              onClick={(e: React.MouseEvent) => setAnchorEl(e.currentTarget)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={showUserInfo}>ログイン情報</MenuItem>
              <MenuItem onClick={getAuthUserByPhoneNumber}>
                認証ユーザ取得(携帯番号)
              </MenuItem>
              <MenuItem onClick={getAuthUserByEmail}>
                認証ユーザ取得(Email)
              </MenuItem>
              <MenuItem onClick={getAuthUserByCode}>
                認証ユーザ取得(社員番号)
              </MenuItem>
              {/* <MenuItem onClick={deleteUser}>認証ユーザ削除</MenuItem> */}
              {/* <MenuItem onClick={deleteIncomePaydays}>支払日情報削除</MenuItem> */}
            </Menu>
          </>
        )}
        {pathname && (
          <HelpPage
            open={!!pathname}
            pathname={pathname}
            onClose={() => setPathname('')}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
