import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { toHalfWidthChar, hiraToKana, errorMessage } from './tools';
import { Gensen, GensenHeader } from './types';
import Alert from './Alert';
import GensenPage from './GensenPage';
import GensenViewer from './GensenViewer';
import AppContext from './AppContext';

import firebase from './firebase';
import 'firebase/auth';
import 'firebase/firestore';
import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  paper: {
    padding: 30,
    backgroundColor: 'white',
  },
  table: {
    tableLayout: 'fixed',
  },
  username: {
    width: '25%',
  },
  year: {
    width: '10%',
  },
  company: {
    width: '35%',
  },
  saver: {
    width: '20%',
  },
  action: {
    width: '10%',
  },
  title: {
    border: '1px solid gray',
    width: 30,
    color: 'white',
    backgroundColor: 'royalblue',
    padding: 0,
    textAlign: 'center',
  },
  label: {
    padding: 0,
    height: 20,
    borderBottom: '1px solid gray',
    backgroundColor: 'aliceblue',
    textAlign: 'center',
    overflow: 'hidden',
  },
  value: {
    height: 30,
    textAlign: 'right',
    paddingTop: 10,
    boxSizing: 'border-box',
  },
  companyValue: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2vw',
    },
  },
  select: {
    width: 100,
  },
  selectCompany: {
    width: 250,
  },
  button: {
    margin: '0 3px',
    padding: 2,
    minWidth: 40,
    height: 24,
  },
  link: {
    textDecoration: 'none',
  },
  pageTitle: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

export const userNameWithCode = (gensen: Gensen) => {
  if (gensen) {
    let name = gensen.userName;
    if (gensen.userCode) name += `(${gensen.userCode})`;
    return name;
  } else {
    return '';
  }
};

const GensenList: React.FC = (props) => {
  const [search, setSearch] = useState<string>('');
  const [companyCode, setCompanyCode] = useState<string>('');
  const [targetUserCode, setTargetUserCode] = useState<string>('');
  const [targetYear, setTargetYear] = useState<number | null>(null);
  const [years, setYears] = useState<number[]>([]);
  const [gensens, setGensens] = useState<Gensen[]>([]);
  const [gensenHeader, setGensenHeader] = useState<GensenHeader | null>(null);
  const [queryCount, setQueryCount] = useState<number>(-1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [startRanks, setStartRanks] = useState<string[]>(['']); // 各ページ最後の rank
  const [messages, setMessages] = useState<string[]>([]);
  const [perPage, setPerPage] = useState<number>(100);
  const [disp, setDisp] = useState<'list' | 'preview'>('list');
  const { companies, customClaims } = useContext(AppContext);
  const admin =
    customClaims?.role === 'admin' || customClaims?.allowAllCompanies;
  const companyCodes =
    (admin ? Object.keys(companies) : customClaims?.companies) || [];
  const firstPos = perPage > 0 ? currentPage * perPage : 0;
  const nextFirstPos = perPage > 0 ? (currentPage + 1) * perPage : 0;
  const classes = useStyles();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const f = async () => {
      const db = firebase.firestore();
      const snapshot = await db.collection('gensenHeaders').get();
      const arrYears: number[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data() as GensenHeader;
        arrYears.push(data.year);
      });
      setYears(arrYears);
    };
    f();
  }, []);

  const queryGensens = async (page: number, start_ranks: string[]) => {
    setMessages([]);
    if (!targetYear) {
      setMessages(['対象年度を指定してください。']);
      return;
    }
    if (targetYear && companyCodes && companyCodes.length > 0) {
      setCurrentPage(page);
      try {
        const db = firebase.firestore();

        // ヘッダ情報
        const snapHeader = await db
          .collection('gensenHeaders')
          .doc(String(targetYear))
          .get();
        const gensen_header = snapHeader.data() as GensenHeader;
        setGensenHeader(gensen_header);

        // 明細データ
        const collection = db.collectionGroup('gensens');
        let query = collection.where('year', '==', targetYear);
        if (search) {
          if (search.match(/^\w+$/)) {
            query = query
              .orderBy('userCode')
              .startAt(search)
              .endAt(search + '\uf8ff');
          } else if (
            search.match(/^[ァ-ヶー　]*$/) ||
            search.match(/^[ぁ-んー　]*$/)
          ) {
            const kana = hiraToKana(search);
            query = query
              .orderBy('userKana')
              .startAt(kana)
              .endAt(kana + '\uf8ff');
          } else {
            query = query
              .orderBy('userName')
              .startAt(search)
              .endAt(search + '\uf8ff');
          }
        } else {
          // データ件数(検索文字が指定されていないときのみ)
          let queryCounts = db
            .collection('gensenCounts')
            .where('year', '==', targetYear);
          if (companyCode) {
            queryCounts = queryCounts.where('companyCode', '==', companyCode);
          }
          const snapshotCounts = await queryCounts.get();

          let query_count = 0;
          snapshotCounts.forEach((doc) => {
            const cnt = doc.data()?.count;
            if (cnt) query_count += cnt;
          });
          setQueryCount(query_count);

          query = query.orderBy('rank');
          if (start_ranks[page]) query = query.startAfter(start_ranks[page]);
        }
        if (companyCode) {
          query = query.where('companyCode', '==', companyCode);
        } else if (!admin) {
          query = query.where('companyCode', 'in', companyCodes);
        }
        if (perPage > 0) query = query.limit(perPage);

        const querySnapshot = await query.get();
        if (querySnapshot.size > 0) {
          let new_gensens: Gensen[] = [];
          querySnapshot.forEach((doc) =>
            new_gensens.push(doc.data() as Gensen)
          );
          setGensens(new_gensens);
          if (!search) {
            if (!start_ranks[page + 1]) {
              const new_start_ranks = [...start_ranks];
              new_start_ranks[page + 1] =
                new_gensens[new_gensens.length - 1].rank;
              setStartRanks(new_start_ranks);
            }
          }
        } else {
          setGensens([]);
          setMessages(['データが存在しません。']);
        }
      } catch (error) {
        console.log({ error });
        setMessages([errorMessage(error)]);
        resetData();
      }
    } else {
      resetData();
    }
  };

  const resetData = () => {
    setGensens([]);
    setQueryCount(-1);
  };

  const changeTargetYear = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'number') {
      const newTargetYear = e.target.value;
      setTargetYear(Number(newTargetYear));
    } else {
      setTargetYear(null);
    }
  };

  const changeCompanyCode = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const code = e.target.value;
      setCompanyCode(code);
    } else {
      setCompanyCode('');
    }
  };

  const changeSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const code = e.target.value;
      setSearch(code);
    } else {
      setSearch('');
    }
  };

  const showPagePos = () => {
    if (perPage > 0) {
      return `${firstPos + 1}〜${
        nextFirstPos > queryCount ? queryCount : nextFirstPos
      }/${queryCount}件`;
    } else {
      return `${queryCount}件`;
    }
  };

  const movePrevPage = () => {
    if (currentPage > 0) {
      queryGensens(currentPage - 1, startRanks);
    }
  };

  const moveNextPage = () => {
    if (nextFirstPos < queryCount) {
      queryGensens(currentPage + 1, startRanks);
    }
  };

  if (!!targetUserCode) {
    return (
      <GensenPage
        userCode={targetUserCode}
        year={targetYear}
        backLabel="明細一覧へ"
        onClose={() => setTargetUserCode('')}
      />
    );
  } else {
    return (
      <Box m={5}>
        <Container maxWidth="md">
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.pageTitle}
          >
            源泉徴収一覧
          </Typography>
          <Paper className={classes.paper}>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
              <Grid item>
                <FormControl margin="none" size="small">
                  <InputLabel id="year-label">対象年度</InputLabel>
                  <Select
                    name={'targetYear'}
                    value={targetYear}
                    labelId="year-label"
                    onChange={changeTargetYear}
                    className={classes.select}
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    {years.map((y, index) => (
                      <MenuItem key={index} value={y}>{`${y}年`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl margin="none" size="small">
                  <InputLabel id="company-label">会社</InputLabel>
                  <Select
                    name={'companyCode'}
                    value={companyCode}
                    labelId="company-label"
                    onChange={changeCompanyCode}
                    className={classes.selectCompany}
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    {companyCodes.map((code, index) => (
                      <MenuItem key={index} value={code}>
                        {toHalfWidthChar(companies[code]?.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl margin="none" size="small">
                  <TextField
                    label="番号・氏名・カナ"
                    value={search}
                    onChange={changeSearch}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    if (search) setQueryCount(-1);
                    queryGensens(0, ['']);
                  }}
                >
                  検索
                </Button>
              </Grid>
              <Grid item>
                <FormControl margin="none" size="small">
                  <InputLabel id="disp-label">表示形式</InputLabel>
                  <Select
                    value={disp}
                    onChange={(e) =>
                      setDisp(String(e?.target?.value) as 'list' | 'preview')
                    }
                    className={classes.select}
                  >
                    <MenuItem value="list">一覧表示</MenuItem>
                    <MenuItem value="preview">ﾌﾟﾚﾋﾞｭｰ</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={handlePrint}
                >
                  印刷
                </Button>
              </Grid>
            </Grid>
            <div className="gensen_print" ref={componentRef}>
              {disp === 'list' && (
                <Table size="small" className={classes.table}>
                  <colgroup>
                    <col className={classes.username} />
                    <Hidden smDown>
                      <col className={classes.year} />
                      <col className={classes.company} />
                      <col className={classes.saver} />
                    </Hidden>
                    <col className={classes.action} />
                  </colgroup>
                  <TableHead>
                    <TableRow>
                      <TableCell>氏名</TableCell>
                      <Hidden smDown>
                        <TableCell>年度</TableCell>
                        <TableCell>会社</TableCell>
                        <TableCell>更新者</TableCell>
                      </Hidden>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {gensens.map((gensen, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{userNameWithCode(gensen)}</TableCell>
                          <Hidden smDown>
                            <TableCell>{gensen.year}</TableCell>
                            <TableCell className={classes.companyValue}>
                              {toHalfWidthChar(gensen.companyName)}
                            </TableCell>
                            <TableCell>{gensen.savedBy}</TableCell>
                          </Hidden>
                          <TableCell>
                            <Button
                              variant="outlined"
                              color="inherit"
                              size="small"
                              className={classes.button}
                              onClick={() => setTargetUserCode(gensen.userCode)}
                            >
                              表示
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
              {disp === 'preview' &&
                gensenHeader &&
                targetYear &&
                gensens.map((gensen, index) => (
                  <GensenViewer
                    key={index}
                    year={targetYear}
                    gensen={gensen}
                    gensenHeader={gensenHeader}
                  />
                ))}
            </div>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                {queryCount >= 0 && (
                  <Grid container style={{ marginTop: 10 }}>
                    <Grid item>
                      {currentPage > 0 && perPage > 0 ? (
                        <Chip
                          size="small"
                          label="前へ"
                          onClick={movePrevPage}
                        />
                      ) : (
                        '前へ'
                      )}
                      &nbsp;
                      {nextFirstPos < queryCount && perPage > 0 ? (
                        <Chip
                          size="small"
                          label="次へ"
                          onClick={moveNextPage}
                        />
                      ) : (
                        '次へ'
                      )}
                    </Grid>
                    <Grid item>
                      <Typography>{showPagePos()}</Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Select
                  value={perPage}
                  onChange={(e) => setPerPage(Number(e?.target?.value))}
                >
                  {[10, 20, 50, 100, 200].map((per, index) => (
                    <MenuItem
                      key={index}
                      value={per}
                    >{`${per}件づつ表示`}</MenuItem>
                  ))}
                  <MenuItem value={-1}>全件表示</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
          {messages.length > 0 && (
            <Alert message={messages} onClose={() => setMessages([])} />
          )}
        </Container>
      </Box>
    );
  }
};

export default GensenList;
