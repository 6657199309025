import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  LinearProgress,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';

import AppContext from './AppContext';
import Alert, { AlertMessage } from './Alert';
import { validEmail, errorMessage } from './tools';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 30,
    backgroundColor: 'white',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

const ChangeEmail: React.FC = () => {
  const [account, setAccount] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const [errors, setErrors] = useState({ email: '', password: '' });
  const { currentUser, user, setUser } = useContext(AppContext);
  const email = user?.email || '';
  const classes = useStyles();

  useEffect(() => {
    setAccount((prev) => ({ ...prev, email }));
  }, [email]);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errs = { email: '', password: '' };

    if (!account.email) {
      errs.email = 'メールアドレスを入力してください。';
    } else if (account.email === user?.email) {
      errs.email = 'メールアドレスが変更されていません。';
    } else if (!validEmail(account.email)) {
      errs.email = '有効なメールアドレスを入力してください。';
    }
    if (!account.password) {
      errs.password = 'パスワードを入力してください。';
    }
    setErrors(errs);

    if (currentUser && currentUser.email && !errs.email && !errs.password) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        account.password
      );
      currentUser
        .reauthenticateWithCredential(credential)
        .then(() => {
          setLoading(true);
          const func = firebase
            .app()
            .functions('asia-northeast1')
            .httpsCallable('ChangeEmail');
          func({ email: account.email })
            .then((result) => {
              setLoading(false);
              console.log({ result });
              if (result.data.result) {
                const messages = ['メールアドレスを変更しました。'];
                if (result.data.mail)
                  messages.push(
                    `${result.data.email} 宛に確認メールを送信しました。`
                  );
                setMessages({
                  content: messages,
                  severity: 'success',
                });
                if (user) {
                  user.email = account.email;
                  setUser(user);
                }
                // history.push('/');
              } else {
                setMessages({
                  content: 'メールアドレスの変更に失敗しました。',
                  severity: 'error',
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log({ error });
              setMessages({
                content: errorMessage(error),
                severity: 'error',
              });
            });
        })
        .catch(function (error) {
          console.log({ error });
          setMessages({
            content: errorMessage(error),
            severity: 'error',
          });
        });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Container maxWidth="xs">
        <Box mt={5}>
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            メールアドレス変更
          </Typography>
          <form onSubmit={submit}>
            <Paper className={classes.paper}>
              <FormControl margin="normal" fullWidth>
                <TextField
                  label="メールアドレス"
                  value={account.email}
                  autoComplete="new-password"
                  disabled={loading}
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={(e) =>
                    setAccount({
                      ...account,
                      email: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <TextField
                  label="パスワード"
                  type="password"
                  value={account.password}
                  autoComplete="new-password"
                  disabled={loading}
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={(e) =>
                    setAccount({ ...account, password: e.target.value })
                  }
                />
              </FormControl>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  fullWidth
                >
                  メールアドレスを変更
                </Button>
              </Grid>
            </Paper>
          </form>
        </Box>
        <Box mt={2}>
          <Link to="/" className={classes.link}>
            <Button variant="outlined" color="inherit" size="medium">
              トップ画面に戻る
            </Button>
          </Link>
        </Box>
        {messages.content.length > 0 && (
          <Alert
            message={messages.content}
            severity={messages.severity}
            onClose={() => setMessages({ content: [] })}
          />
        )}
      </Container>
    </>
  );
};

export default ChangeEmail;
