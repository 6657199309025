import React, { useState, useContext } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogContent,
  Grid,
  FormControl,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { errorMessage, hankaku2Zenkaku } from './tools';
import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import Alert, { AlertMessage } from './Alert';
import AppContext from './AppContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  dialog: {
    height: '100%',
    width: '100%',
    maxWidth: 'initial',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  text_area: {
    height: 240,
  },
  card: {
    backgroundColor: 'whitesmoke',
    padding: 5,
  },
}));

interface TabPanelProps {
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {children}
    </div>
  );
};

interface SendPassCodeProps {
  open: boolean;
  onClose: () => void;
}

const SendPassCode: React.FC<SendPassCodeProps> = ({ open, onClose }) => {
  const [input, setInput] = useState<string>('');
  const [tab, setTab] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const { currentUser, user, savePassCode } = useContext(AppContext);
  const classes = useStyles();

  const requestPassCode = () => {
    if (currentUser && user && user.email) {
      setLoading(true);
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('sendPassCode');
      func({ email: user.email })
        .then((result) => {
          console.log({ result });
          setTimeout(() => setLoading(false), 1000);
          setMessages({
            content: [
              'パスコードを登録メールに送信しました。',
              'パスコードの有効期限は10分間です。',
            ],
            severity: 'success',
          });
          setTab(1);
        })
        .catch((error) => {
          console.log({ error });
          setMessages({
            content: errorMessage(error),
            severity: 'error',
          });
          setTimeout(() => setLoading(false), 1000);
        });
    } else {
      setMessages({
        content: 'メールアドレスを登録してください。',
        severity: 'error',
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth="xs"
      fullWidth
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {loading && <LinearProgress />}
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <form>
          <Tabs
            value={tab}
            onChange={(e, v) => setTab(v)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="パスコードを送信" />
            <Tab label="パスコードを入力" />
          </Tabs>
          <Box m={3}>
            <TabPanel value={tab} index={0}>
              <Grid container style={{ marginTop: 5 }}>
                <Grid item xs={7} spacing={2}>
                  <Typography variant="caption">
                    パスコード送信先
                    <br />
                    {user?.email ? (
                      user?.email
                    ) : (
                      <small>メールアドレスを登録してください</small>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={5} spacing={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={requestPassCode}
                    style={{ width: '100%', margin: 12 }}
                  >
                    パスコードを送信
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid container style={{ marginTop: 5 }}>
                <Grid item xs={7} spacing={2}>
                  <FormControl fullWidth>
                    <TextField
                      label="パスコード"
                      name="passcode"
                      value={input}
                      onChange={(e) =>
                        setInput(hankaku2Zenkaku(e.target.value))
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5} spacing={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      savePassCode(input);
                      onClose();
                    }}
                    style={{ width: '100%', margin: 12 }}
                  >
                    確定
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </form>
        {messages.content.length > 0 && (
          <Alert
            message={messages.content}
            severity={messages.severity}
            onClose={() => setMessages({ content: [] })}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SendPassCode;
