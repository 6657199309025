import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  LinearProgress,
  TextField,
  Button,
  makeStyles,
} from '@material-ui/core';
import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import AppContext from './AppContext';
import { User, AuthUser } from './types';
import {
  fromInternationalPhoneNumber,
  toInternationalPhoneNumber,
  errorMessage,
} from './tools';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  dialog: {
    height: '100%',
    width: '100%',
    maxWidth: 'initial',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  text_area: {
    height: 240,
  },
  card: {
    backgroundColor: 'whitesmoke',
    padding: 5,
  },
}));

interface UserPhoneDialogProps {
  userCode: string;
  user: User;
  open: boolean;
  onClose: () => void;
}

const UserPhoneDialog: React.FC<UserPhoneDialogProps> = ({
  userCode,
  user,
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const { currentUser } = useContext(AppContext);
  const classes = useStyles();

  useEffect(() => {
    if (currentUser && userCode) {
      setLoading(true);
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('getAuthUserByCode');
      func({ code: userCode })
        .then((result) => {
          console.log({ result });
          setAuthUser(result.data.userRecord as AuthUser);
          setLoading(false);
        })
        .catch((error) => {
          setAuthUser(null);
          console.log({ error });
          alert(errorMessage(error));
          setLoading(false);
        });
    } else {
      setAuthUser(null);
    }
  }, [currentUser, userCode]);

  const save = async () => {
    if (authUser && authUser.uid) {
      setLoading(true);
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('saveAuthUser');
      func({
        uid: authUser.uid,
        authUser: { phoneNumber: authUser.phoneNumber },
      })
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch((error) => {
          setLoading(false);
          console.log({ error });
          alert(errorMessage(error));
        });
    } else {
      setAuthUser(null);
    }
  };

  const changePhoneNumber = (e: any) => {
    if (authUser) {
      setAuthUser({
        ...authUser,
        phoneNumber: toInternationalPhoneNumber(e.target.value || ''),
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth="sm"
      fullWidth
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {loading && <LinearProgress />}
      <DialogTitle>
        {user.lastName} {user.firstName} ({user.code})
      </DialogTitle>
      <DialogContent>
        <form>
          <FormControl fullWidth>
            <TextField
              label="社員番号"
              name="userCode"
              value={userCode}
              disabled
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="phoneNumber"
              name="phoneNumber"
              value={fromInternationalPhoneNumber(authUser?.phoneNumber || '')}
              disabled={loading}
              onChange={changePhoneNumber}
            />
          </FormControl>
          <Grid
            container
            direction="column"
            justify="space-around"
            alignItems="stretch"
            style={{ margin: '10px 0' }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={save}
            >
              保存
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UserPhoneDialog;
