import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import Alert, { AlertMessage } from './Alert';
import OperationList from './OperationList';
import {
  toHalfWidthChar,
  fullName,
  userCodeFromEmail,
  errorMessage,
} from './tools';
import { GensenHeader } from './types';
import AppContext from './AppContext';

const str = (text?: string | null) => (text ? String(text) : '');

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  dialog: {
    height: '100%',
    width: '100%',
    maxWidth: 'initial',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  paper: {
    padding: 20,
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
  select: {
    width: 150,
  },
}));

interface ClearGensenProps {
  open: boolean;
  onClose: () => void;
}

const ClearGensen: React.FC<ClearGensenProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyCode, setCompanyCode] = useState<string>('');
  const [targetYear, setTargetYear] = useState<number | null>(null);
  const [years, setYears] = useState<number[]>([]);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const { companies, customClaims, currentUser, user } = useContext(AppContext);
  const admin =
    customClaims?.role === 'admin' || customClaims?.allowAllCompanies;
  const companyCodes =
    (admin ? Object.keys(companies) : customClaims?.companies) || [];
  const classes = useStyles();

  useEffect(() => {
    const f = async () => {
      const db = firebase.firestore();
      const snapshot = await db.collection('gensenHeaders').get();
      const arrYears: number[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data() as GensenHeader;
        arrYears.push(data.year);
      });
      setYears(arrYears);
    };
    f();
  }, []);

  const clearGensens = async (e: React.FormEvent) => {
    e.preventDefault();
    if (targetYear && companyCode) {
      setLoading(true);
      setMessages({ content: [] });
      try {
        const db = firebase.firestore();
        const querySnapshot = await db
          .collectionGroup('gensens')
          .where('year', '==', targetYear)
          .where('companyCode', '==', companyCode)
          .get();
        const targetRefs: firebase.firestore.DocumentReference[] = [];
        querySnapshot.forEach((doc) => targetRefs.push(doc.ref));
        const count = targetRefs.length;

        for await (const tagetRef of targetRefs) {
          await tagetRef.delete();
        }

        if (admin) {
          const existData = await existGensens(targetYear);
          if (!existData) {
            await db
              .collection('gensenHeaders')
              .doc(String(targetYear))
              .delete();
          }
        }

        // データ件数更新
        await updateGensenCounts();

        // 操作履歴
        const userCode = userCodeFromEmail(currentUser?.email || '');
        if (currentUser && userCode) {
          const userName = !!user ? fullName(user) : currentUser.email;
          await db
            .collection('users')
            .doc(userCode)
            .collection('operations')
            .add({
              userCode,
              userName,
              action: 'clearGensen',
              actionName: '源泉徴収票クリア',
              message: `会社:${companies[companyCode].name}、対象年度:${targetYear}、件数:${count}`,
              uid: currentUser.uid,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }

        setLoading(false);
        setMessages((prev) => {
          return {
            content: prev.content.concat(
              `${count}件のデータをクリアしました。`
            ),
            severity: 'success',
          };
        });
      } catch (error) {
        setLoading(false);
        console.log({ error });
        setMessages((prev) => {
          return {
            content: prev.content.concat(errorMessage(error)),
            severity: 'error',
          };
        });
      }
    }
  };

  const existGensens = async (year: number) => {
    const db = firebase.firestore();
    const snapshot = await db
      .collectionGroup('gensens')
      .where('year', '==', year)
      .limit(1)
      .get();
    return snapshot.size > 0;
  };

  const updateGensenCounts = async () => {
    if (targetYear && companyCode) {
      const db = firebase.firestore();
      try {
        const query = await db
          .collectionGroup('gensens')
          .where('year', '==', targetYear)
          .where('companyCode', '==', companyCode)
          .get();
        await db
          .collection('gensenCounts')
          .doc(`${targetYear}:${companyCode}`)
          .set({ targetYear, companyCode, count: query.size });
      } catch (error) {
        console.log({ error });
        setMessages((prev) => {
          return {
            content: prev.content.concat(errorMessage(error)),
            severity: 'error',
          };
        });
      }
    }
  };

  const changeTargetYear = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'number') {
      const newTargetYear = e.target.value;
      setTargetYear(Number(newTargetYear));
    } else {
      setTargetYear(null);
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <Container maxWidth="xs">
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            源泉徴収クリア
          </Typography>
          <Paper square className={classes.paper}>
            <form onSubmit={clearGensens}>
              <FormControl fullWidth>
                <FormControl margin="none" size="small">
                  <InputLabel id="year-label">対象年度</InputLabel>
                  <Select
                    name={'targetYear'}
                    value={targetYear}
                    labelId="year-label"
                    onChange={changeTargetYear}
                    className={classes.select}
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    {years.map((y, index) => (
                      <MenuItem key={index} value={y}>{`${y}年`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="company-label">会社</InputLabel>
                <Select
                  fullWidth
                  labelId="company-label"
                  value={str(companyCode)}
                  required
                  disabled={loading}
                  onChange={(e) => setCompanyCode(String(e?.target?.value))}
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  {companyCodes.map((code, index) => (
                    <MenuItem key={index} value={code}>
                      {toHalfWidthChar(companies[code].name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
                style={{ margin: '10px 0' }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  データを消去する
                </Button>
              </Grid>
            </form>
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
        </Container>
        <OperationList showAll={false} maxCount={20} />
        {messages.content.length > 0 && (
          <Alert
            message={messages.content}
            severity={messages.severity}
            onClose={() => setMessages({ content: [] })}
          />
        )}
      </Box>
    </>
  );
};

export default ClearGensen;
