import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  zeroPadding,
  makeMonths,
  toHalfWidthChar,
  hiraToKana,
  errorMessage,
} from './tools';
import { Income, IncomeHeader } from './types';
import Alert from './Alert';
import IncomePage from './IncomePage';
import AppContext from './AppContext';
import * as xlsx from 'xlsx';

import firebase from './firebase';
import 'firebase/auth';
import 'firebase/firestore';
import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  paper: {
    padding: 30,
    backgroundColor: 'white',
  },
  table: {
    tableLayout: 'fixed',
  },
  username: {
    width: '25%',
  },
  month: {
    width: '15%',
  },
  company: {
    width: '30%',
  },
  saver: {
    width: '20%',
  },
  action: {
    width: '10%',
  },
  title: {
    border: '1px solid gray',
    width: 30,
    color: 'white',
    backgroundColor: 'royalblue',
    padding: 0,
    textAlign: 'center',
  },
  label: {
    padding: 0,
    height: 20,
    borderBottom: '1px solid gray',
    backgroundColor: 'aliceblue',
    textAlign: 'center',
    overflow: 'hidden',
  },
  value: {
    height: 30,
    textAlign: 'right',
    paddingTop: 10,
    boxSizing: 'border-box',
  },
  companyValue: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2vw',
    },
  },
  select: {
    width: 150,
  },
  selectCompany: {
    width: 300,
  },
  button: {
    margin: '0 3px',
    padding: 2,
    minWidth: 40,
    height: 24,
  },
  link: {
    textDecoration: 'none',
  },
  pageTitle: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

const PER_PAGE = 10;

export const userNameWithCode = (income: Income) => {
  if (income) {
    let name = income.userName;
    if (income.userCode) name += `(${income.userCode})`;
    return name;
  } else {
    return '';
  }
};

const IncomeList: React.FC = (props) => {
  const [search, setSearch] = useState<string>('');
  const [companyCode, setCompanyCode] = useState<string>('');
  const [targetUserCode, setTargetUserCode] = useState<string>('');
  const [targetMonth, setTargetMonth] = useState<string>('');
  const [months, setMonths] = useState<[number, number][]>([]);
  const [incomes, setIncomes] = useState<Income[]>([]);
  const [queryCount, setQueryCount] = useState<number>(-1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [startRanks, setStartRanks] = useState<string[]>(['']); // 各ページ最後の rank
  const [messages, setMessages] = useState<string[]>([]);
  const { companies, customClaims } = useContext(AppContext);
  const admin =
    customClaims?.role === 'admin' || customClaims?.allowAllCompanies;
  const companyCodes =
    (admin ? Object.keys(companies) : customClaims?.companies) || [];
  const firstPos = currentPage * PER_PAGE;
  const nextFirstPos = (currentPage + 1) * PER_PAGE;
  const classes = useStyles();
  const db = firebase.firestore();

  useEffect(() => {
    const tmp = new Date();
    const lastMonth = new Date(
      tmp.getFullYear(),
      tmp.getMonth() + 1,
      tmp.getDate()
    );
    setMonths(
      makeMonths(
        2020,
        1,
        lastMonth.getFullYear(),
        lastMonth.getMonth() + 1
      ).reverse()
    );
  }, []);

  // 明細データ
  const searchIncomes = () => {
    const collection = db.collectionGroup('incomes');
    let query = collection.where('month', '==', targetMonth);
    if (companyCode) {
      query = query.where('companyCode', '==', companyCode);
    } else if (!admin) {
      query = query.where('companyCode', 'in', companyCodes);
    }

    if (search) {
      if (search.match(/^\w+$/)) {
        query = query
          .orderBy('userCode')
          .startAt(search)
          .endAt(search + '\uf8ff');
      } else if (
        search.match(/^[ァ-ヶー　]*$/) ||
        search.match(/^[ぁ-んー　]*$/)
      ) {
        const kana = hiraToKana(search);
        query = query
          .orderBy('userKana')
          .startAt(kana)
          .endAt(kana + '\uf8ff');
      } else {
        query = query
          .orderBy('userName')
          .startAt(search)
          .endAt(search + '\uf8ff');
      }
    } else {
      query = query.orderBy('rank');
    }
    return query;
  };

  const queryIncomes = async (page: number, start_ranks: string[]) => {
    setMessages([]);
    if (!targetMonth) {
      setMessages(['対象月を指定してください。']);
      return;
    }
    if (targetMonth && companyCodes && companyCodes.length > 0) {
      setCurrentPage(page);
      try {
        let query = searchIncomes();
        if (!search) {
          // データ件数(検索文字が指定されていないときのみ)
          let queryCounts = db
            .collection('incomeCounts')
            .where('month', '==', targetMonth);
          if (companyCode) {
            queryCounts = queryCounts.where('companyCode', '==', companyCode);
          } else if (!admin) {
            queryCounts = queryCounts.where('companyCode', 'in', companyCodes);
          }

          const snapshotCounts = await queryCounts.get();

          let query_count = 0;
          snapshotCounts.forEach((doc) => {
            const cnt = doc.data()?.count;
            if (cnt) query_count += cnt;
          });
          setQueryCount(query_count);

          // query = query.orderBy('rank');
          if (start_ranks[page]) query = query.startAfter(start_ranks[page]);
        }

        const querySnapshot = await query.limit(PER_PAGE).get();
        if (querySnapshot.size > 0) {
          let new_incomes: Income[] = [];
          querySnapshot.forEach((doc) =>
            new_incomes.push(doc.data() as Income)
          );
          setIncomes(new_incomes);
          if (!search) {
            if (!start_ranks[page + 1]) {
              const new_start_ranks = [...start_ranks];
              new_start_ranks[page + 1] =
                new_incomes[new_incomes.length - 1].rank;
              setStartRanks(new_start_ranks);
            }
          }
        } else {
          setIncomes([]);
          setMessages(['データが存在しません。']);
        }
      } catch (error) {
        console.log({ error });
        setMessages([errorMessage(error)]);
        resetData();
      }
    } else {
      resetData();
    }
  };

  const resetData = () => {
    setIncomes([]);
    setQueryCount(-1);
  };

  const changeTargetMonth = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const newTargetMonth = e.target.value;
      setTargetMonth(newTargetMonth);
    } else {
      setTargetMonth('');
    }
  };

  const changeCompanyCode = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const code = e.target.value;
      setCompanyCode(code);
    } else {
      setCompanyCode('');
    }
  };

  const changeSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const code = e.target.value;
      setSearch(code);
    } else {
      setSearch('');
    }
  };

  const showPagePos = () => {
    return `${firstPos + 1}〜${
      nextFirstPos > queryCount ? queryCount : nextFirstPos
    }/${queryCount}件`;
  };

  const movePrevPage = () => {
    if (currentPage > 0) {
      queryIncomes(currentPage - 1, startRanks);
    }
  };

  const moveNextPage = () => {
    if (nextFirstPos < queryCount) {
      queryIncomes(currentPage + 1, startRanks);
    }
  };

  const getIncomeRows = (
    incomes: Income[],
    incomeHeaders: Map<string, IncomeHeader>,
    columns: string[]
  ) => {
    const rows: string[][] = [columns];
    for (let income of incomes) {
      const headerId = `${income.payday}:${income.companyCode}`;
      const incomeHeader = incomeHeaders.get(headerId);
      const row: string[] = [];
      columns.forEach((name) => {
        const index =
          incomeHeader && incomeHeader.contents
            ? incomeHeader.contents.findIndex((itemName) => itemName === name)
            : -1;
        if (index >= 0) {
          row.push(String(income.contents[index]));
        } else {
          row.push('???');
        }
      });
      rows.push(row);
    }
    return rows;
  };

  const outputExcel = async () => {
    if (!targetMonth) {
      setMessages(['対象月を指定してください。']);
      return;
    }
    if (targetMonth && companyCodes && companyCodes.length > 0) {
      const query = await searchIncomes().get();
      const xutil = xlsx.utils; //util変数にセットしておくと楽

      const incomes: Income[] = [];
      const headerIds = new Set<string>();
      const IncomeHeaders = new Map<string, IncomeHeader>();
      query.forEach((snapshot) => {
        const data = snapshot.data();
        if (data) {
          const income = data as Income;
          incomes.push(income);
          const headerId = `${income.payday}:${income.companyCode}`;
          headerIds.add(headerId);
        }
      });
      for await (let headerId of headerIds) {
        const snapshot = await db
          .collection('incomeHeaders')
          .doc(headerId)
          .get();
        const incomeHeader = snapshot.data() as IncomeHeader;
        if (incomeHeader) {
          IncomeHeaders.set(headerId, incomeHeader);
        }
      }
      const columns = [
        '社員番号',
        '氏名',
        '普通残業',
        '深夜残業',
        '社内販売',
        '住宅手当',
      ];
      const rows = getIncomeRows(incomes, IncomeHeaders, columns);

      let wb = xutil.book_new();
      let ws = xutil.aoa_to_sheet(rows);
      let ws_name = 'sheet1';

      xutil.book_append_sheet(wb, ws, ws_name);
      xlsx.writeFile(wb, 'income.xlsx');
    }
  };

  if (!!targetUserCode) {
    return (
      <IncomePage
        userCode={targetUserCode}
        month={targetMonth}
        backLabel="明細一覧へ"
        onClose={() => setTargetUserCode('')}
      />
    );
  } else {
    return (
      <Box m={5}>
        <Container maxWidth="lg">
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.pageTitle}
          >
            給与明細一覧
          </Typography>
          <Paper className={classes.paper}>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
              <Grid item>
                <FormControl margin="none" size="small">
                  <InputLabel id="month-label">対象月</InputLabel>
                  <Select
                    name={'targetMonth'}
                    value={targetMonth}
                    labelId="month-label"
                    onChange={changeTargetMonth}
                    className={classes.select}
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    {months.map(([year, month], index) => (
                      <MenuItem
                        key={index}
                        value={`${year}${zeroPadding(month, 2)}`}
                      >{`${year}年${zeroPadding(month, 2)}月`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl margin="none" size="small">
                  <InputLabel id="company-label">会社</InputLabel>
                  <Select
                    name={'companyCode'}
                    value={companyCode}
                    labelId="company-label"
                    onChange={changeCompanyCode}
                    className={classes.selectCompany}
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    {companyCodes.map((code, index) => (
                      <MenuItem key={index} value={code}>
                        {toHalfWidthChar(companies[code]?.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl margin="none" size="small">
                  <TextField
                    label="番号・氏名・カナ"
                    value={search}
                    onChange={changeSearch}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    if (search) setQueryCount(-1);
                    queryIncomes(0, ['']);
                  }}
                >
                  検索
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={outputExcel}
                >
                  EXCEL
                </Button>
              </Grid>
            </Grid>
            <Table size="small" className={classes.table}>
              <colgroup>
                <col className={classes.username} />
                <Hidden smDown>
                  <col className={classes.month} />
                  <col className={classes.company} />
                  <col className={classes.saver} />
                </Hidden>
                <col className={classes.action} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>氏名</TableCell>
                  <Hidden smDown>
                    <TableCell>支給日</TableCell>
                    <TableCell>会社</TableCell>
                    <TableCell>更新者</TableCell>
                  </Hidden>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incomes.map((income, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{userNameWithCode(income)}</TableCell>
                      <Hidden smDown>
                        <TableCell>{income.payday}</TableCell>
                        <TableCell className={classes.companyValue}>
                          {toHalfWidthChar(income.companyName)}
                        </TableCell>
                        <TableCell>{income.savedBy}</TableCell>
                      </Hidden>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="inherit"
                          size="small"
                          className={classes.button}
                          onClick={() => setTargetUserCode(income.userCode)}
                        >
                          給与
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {queryCount >= 0 && (
              <Grid container style={{ marginTop: 10 }}>
                <Grid item>
                  {currentPage > 0 ? (
                    <Chip size="small" label="前へ" onClick={movePrevPage} />
                  ) : (
                    '前へ'
                  )}
                  &nbsp;
                  {nextFirstPos < queryCount ? (
                    <Chip size="small" label="次へ" onClick={moveNextPage} />
                  ) : (
                    '次へ'
                  )}
                </Grid>
                <Grid item>
                  <Typography>{showPagePos()}</Typography>
                </Grid>
              </Grid>
            )}
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
          {messages.length > 0 && (
            <Alert message={messages} onClose={() => setMessages([])} />
          )}
        </Container>
      </Box>
    );
  }
};

export default IncomeList;
