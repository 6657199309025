import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as xlsx from 'xlsx';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import Alert, { AlertMessage } from './Alert';
import {
  zeroPadding,
  makeMonths,
  toHalfWidthChar,
  shapeUserCode,
} from './tools';
import { getByLabelFromIncomeHeader } from './IncomeLayout';
import { User, Income, IncomeHeader, Payment } from './types';
import AppContext from './AppContext';

const request = require('request-promise');

type HEADER_INFO = {
  label: string;
  name: string | string[];
  type:
    | 'salary_string'
    | 'salary_number'
    | 'payment'
    | 'annual_income'
    | 'annual_total_income'
    | 'kkb_string'
    | 'kkb_number'
    | 'unit_price';
  store: 'monthly1' | 'monthly2' | 'bonus' | null;
  target: 'parttime' | null;
  width: number;
};

type Row = (string | number)[];

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  dialog: {
    height: '100%',
    width: '100%',
    maxWidth: 'initial',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  paper: {
    padding: 20,
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

interface ExportIncomeProps {
  open: boolean;
  onClose: () => void;
}

const KKB_URL = 'https://ebondkkb.com';

const ExportIncome: React.FC<ExportIncomeProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyCode, setCompanyCode] = useState<string>('');
  const [targetMonth, setTargetMonth] = useState<string>('');
  const [unlimited, setUnlimited] = useState<boolean>(false);
  const [months, setMonths] = useState<[number, number][]>([]);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const { companies, customClaims } = useContext(AppContext);
  const admin =
    customClaims?.role === 'admin' || customClaims?.allowAllCompanies;
  const companyCodes =
    (admin ? Object.keys(companies) : customClaims?.companies) || [];
  const classes = useStyles();

  useEffect(() => {
    let lastMonth = new Date();
    setMonths(
      makeMonths(
        2020,
        1,
        lastMonth.getFullYear(),
        lastMonth.getMonth() + 1
      ).reverse()
    );
  }, []);

  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const getIncomeValue = (
    names: string | string[],
    income: Income,
    incomeHeader: IncomeHeader,
    type: 'string' | 'number'
  ) => {
    if (Array.isArray(names)) {
      const values = names.map((name) =>
        getByLabelFromIncomeHeader(name, income, incomeHeader, '')
      );
      return type === 'string'
        ? values.join(' ')
        : values.reduce(
            (sum: number, value: string) =>
              sum + Number(value.replace(/,/g, '')),
            0
          );
    } else {
      const value = getByLabelFromIncomeHeader(names, income, incomeHeader, '');
      return type === 'string' ? value : Number(value.replace(/,/g, ''));
    }
  };

  // 子データを含む複数データをマージ
  const mergeRows = (
    rows: { [code: string]: Row[] },
    parentCodes: { [code: string]: string }
  ): Row[] => {
    const merged_rows: Row[] = [];
    const childCodes: { [code: string]: string[] } = {};
    // 親社員コード → 子社員コード配列に変換
    Object.keys(parentCodes).forEach((code) => {
      const parentCode = parentCodes[code];
      if (!childCodes[parentCode]) childCodes[parentCode] = [];
      childCodes[parentCode].push(code);
    });
    console.log({ parentCodes, childCodes });
    Object.keys(rows).forEach((code) => {
      const parentCode = parentCodes[code];
      const existParent = !!parentCode && rows[parentCode];
      if (!existParent) {
        let merged = rows[code];
        if (childCodes[code]) {
          childCodes[code].forEach((childCode) => {
            if (rows[childCode]) {
              merged = merged.concat(rows[childCode]);
            }
          });
        }
        if (merged.length > 0) {
          const row0 = merged[0];
          const merged_row = row0.map((col, index) => {
            if (typeof col === 'number') {
              return merged
                .slice(1)
                .reduce((sum, row) => sum + Number(row[index]), col);
            } else {
              return col;
            }
          });
          merged_rows.push(merged_row);
        }
      }
    });
    return merged_rows;
  };

  const isTarget = (item: HEADER_INFO, employment: string | null) => {
    if (item.target === 'parttime') {
      return !!(employment || '').match(/パート/);
    } else {
      return true;
    }
  };

  const exportIncome = async (e: React.FormEvent) => {
    e.preventDefault();

    if (targetMonth) {
      setMessages({ content: [] });
      try {
        setLoading(true);
        const db = firebase.firestore();
        const yearStr = targetMonth.slice(0, 4);
        const monthStr = targetMonth.slice(4, 6);

        // 給与情報取得
        const collection = db.collectionGroup('incomes');
        let query = collection.where('month', '==', targetMonth);
        if (companyCode) {
          query = query.where('companyCode', '==', companyCode);
        } else if (!admin) {
          query = query.where('companyCode', 'in', companyCodes);
        }

        const collectionHeader = db.collectionGroup('incomeHeaders');
        let queryHeader = collectionHeader.where('month', '==', targetMonth);
        if (companyCode) {
          queryHeader = queryHeader.where('companyCode', '==', companyCode);
        } else if (!admin) {
          queryHeader = queryHeader.where('companyCode', 'in', companyCodes);
        }
        const incomeHeaders: { [code: string]: IncomeHeader } = {};
        const querySnapHeader = await queryHeader.get();
        querySnapHeader.forEach((doc) => {
          const data = doc.data() as IncomeHeader;
          incomeHeaders[data.companyCode] = data;
        });

        const incomes: Income[] = [];
        const querySnap = await query.get();
        querySnap.forEach((doc) => {
          const income = doc.data() as Income;
          incomes.push(income);
        });

        // 支払い情報取得(賞与、社宅)
        const queryPaymentSnap = await db
          .collectionGroup('payments')
          .where('month', '==', targetMonth)
          .get();

        const payments: { [code: string]: Payment } = {};
        queryPaymentSnap.forEach((doc) => {
          const payment = doc.data() as Payment;
          payments[payment.userCode] = payment;
        });

        // 社員番号取得(給与から)
        const codes = incomes.map((income) => {
          const incomeHeader = incomeHeaders[income.companyCode];
          const code = getByLabelFromIncomeHeader(
            '社員番号',
            income,
            incomeHeader,
            ''
          );
          return shapeUserCode(code);
        });

        const tasks = codes.map((code) => {
          const f = async () => {
            const userDoc = await db.collection('users').doc(code).get();
            const data = userDoc.data();
            if (data) {
              const user = data as User;
              return { code, parentCode: user.parentCode || '' };
            } else {
              return { code, parentCode: '' };
            }
          };
          return f();
        });
        const code_pairs = await Promise.all(tasks);
        const parentCodes: { [code: string]: string } = {};
        code_pairs.forEach((code_pair) => {
          if (code_pair.parentCode)
            parentCodes[code_pair.code] = code_pair.parentCode;
        });

        // 移動登録情報(KKB)
        const date = new Date(Number(yearStr), Number(monthStr) - 1, 0); // 一月前の月末
        const y = date.getFullYear(),
          m = date.getMonth() + 1,
          d = date.getDate();
        const doc = await db
          .collection('globalValues')
          .doc('salaryPassword')
          .get();
        const docData = doc.data();
        if (!docData) throw Error('パラメータの取得に失敗しました。');
        const salary_password = docData.value;

        const url = KKB_URL + '/users/salary_infos';

        const UNIT_COUNT = 500;
        const splitUserCodes = [];
        for (let i = 0; i < codes.length / UNIT_COUNT; i++) {
          splitUserCodes.push(
            codes.slice(i * UNIT_COUNT, (i + 1) * UNIT_COUNT)
          );
        }

        type KKB_SALARY_INFO = {
          job_type: string;
          employment: string;
          company_name: string;
          payment: number;
        };
        let json: { [code: string]: KKB_SALARY_INFO } = {};
        for await (const codes2 of splitUserCodes) {
          const user_codes = codes2.join(',');
          const params = `day=${y}-${m}-${d}&codes=${user_codes}&salary_password=${salary_password}&unlimited=${unlimited}`;
          const json2: { [code: string]: KKB_SALARY_INFO } = await request({
            url: url + '?' + params,
            method: 'GET',
            json: true,
          });
          json = Object.assign(json, json2);
        }

        const header: HEADER_INFO[] = [
          {
            type: 'kkb_string',
            label: '法人',
            name: 'company_name',
            store: null,
            target: null,
            width: 40,
          },
          {
            type: 'salary_string',
            label: '社員番号',
            name: '社員番号',
            store: null,
            target: null,
            width: 10,
          },
          {
            type: 'salary_string',
            label: '氏名',
            name: '氏名',
            store: null,
            target: null,
            width: 20,
          },
          {
            type: 'kkb_string',
            label: '雇用形態',
            name: 'employment',
            store: null,
            target: null,
            width: 10,
          },
          {
            type: 'kkb_string',
            label: '職制',
            name: 'job_type',
            store: null,
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '基本給',
            name: ['基本給', '報酬'],
            store: 'monthly1',
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '出勤時間',
            name: '出勤時間',
            store: null,
            target: 'parttime',
            width: 10,
          },
          {
            type: 'unit_price',
            label: '単価',
            name: ['出勤時間', '基本給', '報酬'],
            store: null,
            target: 'parttime',
            width: 10,
          },
          {
            type: 'salary_number',
            label: '職能手当',
            name: '職能手当',
            store: 'monthly1',
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '総合職手当',
            name: '総合職手当',
            store: 'monthly1',
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '総合職手当1',
            name: '総合職手当1',
            store: 'monthly1',
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '総合職手当2',
            name: '総合職手当2',
            store: 'monthly1',
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '専門職手当1',
            name: '専門職手当1',
            store: 'monthly1',
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '専門職手当2',
            name: '専門職手当2',
            store: 'monthly1',
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '業績手当',
            name: '業績手当',
            store: 'monthly1',
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: 'その他',
            name: [
              'その他A',
              'その他B',
              '住宅手当',
              '時間外対応手当',
              'その他支給（薬剤師）',
              'その他支給（登販）',
              '地域手当',
              '管理職手当',
              'その他支給（固定）',
              'その他支給（変動）',
              '報奨手当',
              '報奨金',
              '通勤手当',
              'みなし残業手当',
            ],
            store: 'monthly1',
            target: null,
            width: 10,
          },
          {
            type: 'payment',
            label: '基本賞与',
            name: 'bonus',
            store: 'bonus',
            target: null,
            width: 10,
          },
          {
            type: 'annual_income',
            label: '年収（万円）',
            name: '',
            store: null,
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '普通残業',
            name: '普通残業',
            store: 'monthly2',
            target: null,
            width: 10,
          },
          {
            type: 'payment',
            label: '社宅',
            name: 'house',
            store: 'monthly2',
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '福利厚生',
            name: '立替経費',
            store: 'monthly2',
            target: null,
            width: 10,
          },
          {
            type: 'kkb_number',
            label: '立替経費',
            name: 'payment',
            store: 'monthly2',
            target: null,
            width: 10,
          },
          {
            type: 'annual_total_income',
            label: '年間総支給（万円）',
            name: '',
            store: null,
            target: null,
            width: 10,
          },
          {
            type: 'salary_number',
            label: '社内販売',
            name: '社内販売',
            store: null,
            target: null,
            width: 10,
          },
        ];
        const rows: { [code: string]: Row[] } = {};
        incomes.forEach((income) => {
          const row: Row = [];
          const monthly1: number[] = [];
          const monthly2: number[] = [];
          let bonusBasic = 0;
          const incomeHeader = incomeHeaders[income.companyCode];
          const code = getByLabelFromIncomeHeader(
            '社員番号',
            income,
            incomeHeader,
            ''
          );
          const userCode = shapeUserCode(code);
          const kkbUser = json[userCode];
          const employment = kkbUser ? kkbUser.employment : null;
          header.forEach((item) => {
            if (
              item.type === 'salary_string' ||
              item.type === 'salary_number'
            ) {
              const type = item.type === 'salary_number' ? 'number' : 'string';
              const value = getIncomeValue(
                item.name,
                income,
                incomeHeader,
                type
              );
              row.push(isTarget(item, employment) ? value : '');
              if (type === 'number' && item.store === 'monthly1')
                monthly1.push(Number(value));
              if (type === 'number' && item.store === 'monthly2')
                monthly2.push(Number(value));
            } else if (item.type === 'payment') {
              let value = 0;
              const payment = payments[userCode];
              if (payment && typeof item.name === 'string') {
                const column = item.name as 'bonus' | 'house';
                value = Number(payment[column] || 0);
              }
              if (item.store === 'monthly1') monthly1.push(Number(value));
              if (item.store === 'monthly2') monthly2.push(Number(value));
              if (item.store === 'bonus') bonusBasic = value;
              row.push(isTarget(item, employment) ? value : '');
            } else if (item.type === 'annual_income') {
              const value =
                12 * monthly1.reduce((sum, val) => sum + val, 0) + bonusBasic;
              row.push(
                isTarget(item, employment) ? Math.round(value / 10000) : ''
              );
            } else if (item.type === 'annual_total_income') {
              const value =
                12 * monthly1.reduce((sum, val) => sum + val, 0) +
                12 * monthly2.reduce((sum, val) => sum + val, 0) +
                bonusBasic;
              row.push(
                isTarget(item, employment) ? Math.round(value / 10000) : ''
              );
            } else if (
              item.type === 'kkb_string' ||
              item.type === 'kkb_number'
            ) {
              if (
                ((admin && unlimited) || (json && json[userCode])) &&
                typeof item.name === 'string'
              ) {
                const type = item.type === 'kkb_number' ? 'number' : 'string';
                const col_name = item.name as keyof KKB_SALARY_INFO;
                const value = kkbUser ? kkbUser[col_name] : '';
                row.push(isTarget(item, employment) ? value : '');
                if (type === 'number' && item.store === 'monthly1')
                  monthly1.push(Number(value));
                if (type === 'number' && item.store === 'monthly2')
                  monthly2.push(Number(value));
              } else {
                row.push('');
              }
            } else if (item.type === 'unit_price') {
              const workTime = getIncomeValue(
                item.name[0],
                income,
                incomeHeader,
                'number'
              );
              const total = getIncomeValue(
                item.name.slice(1),
                income,
                incomeHeader,
                'number'
              );
              const value =
                total > 0 && workTime > 0
                  ? Math.round(Number(total) / Number(workTime))
                  : '';
              row.push(isTarget(item, employment) ? value : '');
            }
          });
          if (!rows[userCode]) rows[userCode] = [];
          rows[userCode].push(row);
        });

        const array = mergeRows(rows, parentCodes);

        const dataArray: [Row[], Row[]] = [[], []];
        const jobTypes = ['薬剤師', '事務員'];
        const indexJT = header.findIndex((item) => item.label === '職制');
        const indexEM = header.findIndex((item) => item.label === '雇用形態');
        const indexTotal = header.findIndex(
          (item) => item.label === '年間総支給（万円）'
        );
        const exceptEmployments = ['役員', '顧問'];
        if (indexJT >= 0 && indexEM >= 0) {
          jobTypes.forEach((jobType, index) => {
            dataArray[index] = array.filter((row) => {
              return (
                (admin && unlimited) ||
                (row[indexJT] === jobType &&
                  row[indexEM] &&
                  !exceptEmployments.includes(String(row[indexEM])))
              );
            });
            dataArray[index].sort((row1, row2) => {
              const total1 = Number(row1[indexTotal]);
              const total2 = Number(row2[indexTotal]);
              return total2 - total1;
            });
            dataArray[index].unshift(header.map((item) => item.label)); // header
          });
        }

        const wscols = header.map((item) => ({ wch: item.width }));
        const sheets = dataArray.map((arr) => xlsx.utils.aoa_to_sheet(arr));
        const SheetNames = jobTypes.map(
          (jobType) => `提出用${jobType}(支給順)`
        );
        const Sheets: { [shet_name: string]: any } = {};
        sheets.forEach((sheet, index) => {
          sheet['!cols'] = wscols;
          Sheets[SheetNames[index]] = sheet;
        });
        const wb = {
          SheetNames,
          Sheets,
        };
        const wb_out = xlsx.write(wb, { type: 'binary' });
        var blob = new Blob([s2ab(wb_out)], {
          type: 'application/octet-stream',
        });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `annual-${targetMonth}.xlsx`;
        link.click();
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setMessages((prev) => ({
          ...prev,
          content: prev.content.concat(error.message),
        }));
      }
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <Container maxWidth="xs">
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            年収ダウンロード
          </Typography>
          <Paper square className={classes.paper}>
            <form onSubmit={exportIncome}>
              <FormControl fullWidth>
                <InputLabel id="month-label">対象月</InputLabel>
                <Select
                  fullWidth
                  value={targetMonth}
                  labelId="month-label"
                  required
                  disabled={loading}
                  onChange={(e) => setTargetMonth(String(e?.target?.value))}
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  {months.map(([year, month], index) => (
                    <MenuItem
                      key={index}
                      value={`${year}${zeroPadding(month, 2)}`}
                    >{`${year}年${zeroPadding(month, 2)}月`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="company-label">会社</InputLabel>
                <Select
                  fullWidth
                  labelId="company-label"
                  value={companyCode || ''}
                  disabled={loading}
                  onChange={(e) => setCompanyCode(String(e?.target?.value))}
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  {companyCodes.map((code, index) => (
                    <MenuItem key={index} value={code}>
                      {toHalfWidthChar(companies[code].name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {admin && (
                <FormControl margin="none">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={unlimited}
                        disabled={loading}
                        size="small"
                        onChange={(e) => setUnlimited(e.target.checked)}
                      />
                    }
                    label="全ての雇用形態(役員・顧問含む)"
                  />
                </FormControl>
              )}
              <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
                style={{ margin: '10px 0' }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  ダウンロード
                </Button>
              </Grid>
            </form>
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
        </Container>
        {messages.content.length > 0 && (
          <Alert
            message={messages.content}
            severity={messages.severity}
            onClose={() => setMessages({ content: [] })}
          />
        )}
      </Box>
    </>
  );
};

export default ExportIncome;
