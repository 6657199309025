import React, { useState, useEffect, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Box,
  Button,
  Container,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  makeStyles,
  FormControl,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import Alert from './Alert';
import AppContext from './AppContext';
import BonusViewer from './BonusViewer';
import SendPassCode from './SendPassCode';
import { BonusTable, getBonusLayout, createBonusItems } from './BonusLayout';
import { errorMessage, getTargetMonths } from './tools';
import { Bonus, BonusHeader } from './types';
import firebase from './firebase';
import 'firebase/auth';
import 'firebase/functions';
import './App.css';
import { zeroPadding, userCodeFromEmail } from './tools';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  spacer: {
    height: 20,
  },
  month: {
    width: 200,
  },
  link: {
    textDecoration: 'none',
  },
}));

const MyBonusPage: React.FC = (props) => {
  const [bonusTables, setBonusTables] = useState<BonusTable[]>([]);
  const [targetMonth, setTargetMonth] = useState<string>('');
  const [months, setMonths] = useState<[number, number][]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [openPassCode, setOpenPassCode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentUser, passCode, savePassCode } = useContext(AppContext);
  const componentRef = useRef(null);
  const userCode = userCodeFromEmail(currentUser?.email || '');
  const classes = useStyles();

  useEffect(() => {
    if (userCode && passCode && targetMonth) {
      setLoading(true);
      resetData();
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('getBonusData');
      func({
        userCode,
        passCode,
        month: targetMonth,
      })
        .then(async (result) => {
          const items = result.data as {
            bonus: Bonus;
            bonusHeader: BonusHeader;
          }[];
          if (items.length > 0) {
            for await (const item of items) {
              try {
                const bonus = item.bonus;
                const targetDate = new Date(
                  `${bonus.payday.substr(0, 4)}/${bonus.payday.substr(
                    4,
                    2
                  )}/${bonus.payday.substr(6, 2)}`
                );
                const bonusLayout = await getBonusLayout(
                  bonus.companyCode,
                  targetDate
                );
                if (bonusLayout) {
                  const bonus_table = createBonusItems(
                    item.bonus,
                    item.bonusHeader,
                    bonusLayout
                  );
                  if (bonus_table)
                    setBonusTables((prev) => prev.concat(bonus_table));
                } else {
                  setMessages((prev) =>
                    prev.concat(
                      `レイアウト情報が存在しません(会社コード:${bonus.companyCode}、支給日:${targetDate})。`
                    )
                  );
                }
              } catch (error) {
                setMessages((prev) => prev.concat(errorMessage(error)));
              }
            }
          } else {
            setMessages((prev) => prev.concat('データが存在しません。'));
          }
          setTimeout(() => setLoading(false), 1000);
        })
        .catch((error) => {
          resetData();
          savePassCode('');
          setOpenPassCode(true);
          setMessages((prev) => prev.concat(errorMessage(error)));
          console.log({ error });
          setTimeout(() => setLoading(false), 1000);
        });
    }
  }, [userCode, passCode, targetMonth, savePassCode]);

  useEffect(() => {
    const f = async () => {
      if (userCode) {
        const target_months = await getTargetMonths(userCode, 'bonus', false);
        setMonths(
          target_months.map((month) => [+month.slice(0, 4), +month.slice(4)])
        );
      }
    };
    f();
  }, [userCode]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const resetData = () => {
    setBonusTables([]);
    setMessages([]);
  };

  const changeTargetMonth = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      setTargetMonth(e.target.value);
      if (e.target.value && !passCode) setOpenPassCode(true);
    } else {
      resetData();
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <SendPassCode
        open={openPassCode}
        onClose={() => setOpenPassCode(false)}
      />
      <Container maxWidth="md">
        {messages.length > 0 && (
          <Alert message={messages} onClose={() => setMessages([])} />
        )}
        <FormControl margin="normal" className={classes.month}>
          <InputLabel id="month-label">対象月</InputLabel>
          <Select
            value={targetMonth}
            onChange={changeTargetMonth}
            labelId="month-label"
          >
            <MenuItem value="">
              <em></em>
            </MenuItem>
            {months.map(([year, month], index) => (
              <MenuItem
                key={index}
                value={`${year}${zeroPadding(month, 2)}`}
              >{`${year}年${zeroPadding(month, 2)}月`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {bonusTables.length > 0 && (
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={handlePrint}
            style={{ marginTop: 30, marginLeft: 10 }}
          >
            印刷
          </Button>
        )}
        <div className="bonus_print" ref={componentRef}>
          {bonusTables.map((bonusTable, index) => (
            <BonusViewer bonusTable={bonusTable} key={index} />
          ))}
        </div>
        <Box mt={2}>
          <Link to="/" className={classes.link}>
            <Button variant="outlined" color="inherit" size="medium">
              トップ画面に戻る
            </Button>
          </Link>
        </Box>
      </Container>
    </>
  );
};

export default MyBonusPage;
