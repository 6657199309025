import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';

import { errorMessage } from './tools';
import Alert from './Alert';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  paper: {
    padding: 20,
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

const ImportRevision: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<string[]>([]);
  const classes = useStyles();

  const ImportRevisions = async () => {
    setMessages([]);
    try {
      setLoading(true);
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('updateIncomeRevisions');
      func()
        .then(async (result) => {
          console.log({ result });
          setTimeout(() => setLoading(false), 1000);
        })
        .catch((error) => {
          console.log({ error });
          setTimeout(() => setLoading(false), 1000);
        });
    } catch (error) {
      console.log({ error });
      setLoading(false);
      setMessages((prev) => prev.concat(errorMessage(error)));
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <Container maxWidth="xs">
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            職能給明細取込
          </Typography>
          <Paper square className={classes.paper}>
            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="stretch"
            >
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={ImportRevisions}
              >
                取込実行
              </Button>
            </Grid>
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
        </Container>
        <Box m={4}>
          {messages.length > 0 && (
            <Alert message={messages} onClose={() => setMessages([])} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ImportRevision;
