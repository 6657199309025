import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  LinearProgress,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';

import {
  fromInternationalPhoneNumber,
  toInternationalPhoneNumber,
  errorMessage,
} from './tools';
import Alert, { AlertMessage } from './Alert';
import AppContext from './AppContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 30,
    backgroundColor: 'white',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

const ChangePhoneNumber: React.FC = () => {
  const [account, setAccount] = useState({
    phoneNumber: '',
    password: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const [errors, setErrors] = useState({ phoneNumber: '', password: '' });
  const { currentUser, user } = useContext(AppContext);
  const phoneNumber = currentUser?.phoneNumber
    ? fromInternationalPhoneNumber(currentUser?.phoneNumber)
    : '';
  // const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    setAccount((prev) => ({ ...prev, phoneNumber }));
  }, [phoneNumber]);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errs = {
      phoneNumber: '',
      password: '',
    };

    if (!account.phoneNumber) {
      errs.phoneNumber = '携帯番号を入力してください。';
    }
    if (!account.password) {
      errs.password = 'パスワードを入力してください。';
    }
    setErrors(errs);
    const existErrors = Object.values(errs).filter((err) => !!err).length > 0;

    if (currentUser && currentUser.email && !existErrors) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        account.password
      );
      currentUser
        .reauthenticateWithCredential(credential)
        .then(() => {
          setLoading(true);
          const phoneNumber = toInternationalPhoneNumber(account.phoneNumber);

          const func = firebase
            .app()
            .functions('asia-northeast1')
            .httpsCallable('changePhoneNumber');
          func({ ...account, phoneNumber, email: user?.email })
            .then((result) => {
              setLoading(false);
              console.log({ result });
              if (result.data.result) {
                setMessages({
                  content: '携帯番号を変更しました。',
                  severity: 'success',
                });
                firebase.auth().currentUser?.reload();
                // history.push('/');
              } else {
                setMessages({
                  content: '携帯番号の変更に失敗しました。',
                  severity: 'error',
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log({ error });
              setMessages({
                content: errorMessage(error),
                severity: 'error',
              });
            });
        })
        .catch(function (error) {
          console.log({ error });
          setMessages({
            content: errorMessage(error),
            severity: 'error',
          });
        });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Container maxWidth="xs">
        <Box mt={5}>
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            携帯番号変更
          </Typography>
          <form onSubmit={submit}>
            <Paper className={classes.paper}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  label="携帯番号(11桁、ハイフンなし)"
                  value={account.phoneNumber}
                  autoComplete="new-password"
                  disabled={loading}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                  onChange={(e) =>
                    setAccount({
                      ...account,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  label="パスワード"
                  type="password"
                  value={account.password}
                  autoComplete="new-password"
                  disabled={loading}
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={(e) =>
                    setAccount({ ...account, password: e.target.value })
                  }
                />
              </FormControl>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  fullWidth
                >
                  携帯番号を変更
                </Button>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                style={{ marginTop: 10 }}
              >
                <Typography variant="caption">
                  携帯番号でログインすることができます。
                  <br />
                  ログイン時、この番号にショートメールが届きます。
                </Typography>
              </Grid>
            </Paper>
          </form>
        </Box>
        <Box mt={2}>
          <Link to="/" className={classes.link}>
            <Button variant="outlined" color="inherit" size="medium">
              トップ画面に戻る
            </Button>
          </Link>
        </Box>
        {messages.content.length > 0 && (
          <Alert
            message={messages.content}
            severity={messages.severity}
            onClose={() => setMessages({ content: [] })}
          />
        )}
      </Container>
    </>
  );
};

export default ChangePhoneNumber;
