import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Grid,
  FormControl,
  CircularProgress,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import firebase from './firebase';
import 'firebase/auth';

import { toInternationalPhoneNumber, hankaku2Zenkaku } from './tools';
import AppContext from './AppContext';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 35,
    height: 250,
    backgroundColor: 'white',
  },
  hidden: {
    display: 'none',
  },
}));

interface Verifier {
  recaptchaVerifier: firebase.auth.RecaptchaVerifier | null;
  confirmationResult: firebase.auth.ConfirmationResult | null;
}

interface FormErrors {
  phone: string | null;
  code: string | null;
  others: string | null;
}

const SignInPhone: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [account, setAccount] = useState({
    phone: '',
    code: '',
  });
  const [verifier, setVerifier] = useState<Verifier>({
    recaptchaVerifier: null,
    confirmationResult: null,
  });
  const [errors, setErrors] = useState<FormErrors>({
    phone: '',
    code: '',
    others: '',
  });
  const { savePassCode } = useContext(AppContext);
  const classes = useStyles();

  useEffect(() => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: function (response: any) {
          console.log({ response });
        },
      }
    );
    setVerifier((prev) => ({
      ...prev,
      recaptchaVerifier,
    }));
  }, []);

  const clearErrors = () => {
    setErrors({ phone: '', code: '', others: '' });
  };

  const sendPhone = () => {
    clearErrors();
    if (!account.phone) {
      setErrors({ ...errors, phone: '携帯番号を入力してください。' });
      return;
    }
    setLoading(true);
    const phoneNumber = toInternationalPhoneNumber(account.phone);
    const func = firebase
      .app()
      .functions('asia-northeast1')
      .httpsCallable('existPhoneNumber');
    func({ phoneNumber })
      .then((result) => {
        if (result.data && verifier.recaptchaVerifier) {
          firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, verifier.recaptchaVerifier)
            .then((confirmationResult) => {
              savePassCode('');
              setLoading(false);
              setVerifier({ ...verifier, confirmationResult });
            })
            .catch((error) => {
              console.log({ error });
              setLoading(false);
              setErrors({
                ...errors,
                others: `電話認証に失敗しました。`,
              });
              console.log({ error });
            });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log({ error });
        setLoading(false);
        setErrors({
          ...errors,
          others: `携帯番号が存在しません。`,
        });
      });
  };

  const checkCode = () => {
    if (verifier.confirmationResult) {
      clearErrors();
      if (!account.code) {
        setErrors({ ...errors, code: 'コードを入力してください。' });
        return;
      }
      setLoading(true);
      verifier.confirmationResult
        .confirm(account.code)
        .then((result) => {
          setLoading(false);
          setVerifier({ ...verifier, confirmationResult: null });
          console.log({ result });
        })
        .catch((error: any) => {
          console.log({ error });
          setLoading(false);
          setErrors({
            ...errors,
            others: `ログインに失敗しました。`,
          });
        });
    }
  };

  return (
    <form>
      <Paper className={classes.paper}>
        {loading && (
          <Grid container direction="row" justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
        )}
        {errors.others && (
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography color="error" variant="caption">
              {errors.others}
            </Typography>
          </Grid>
        )}
        <FormControl
          margin="normal"
          fullWidth
          className={clsx(!!verifier.confirmationResult && classes.hidden)}
        >
          <TextField
            id="account"
            value={account.phone}
            onChange={(e) => {
              clearErrors();
              setAccount({
                ...account,
                phone: hankaku2Zenkaku(e.target.value),
              });
            }}
            placeholder="01234567890"
            disabled={loading}
            autoComplete="new-password"
            label="携帯番号(11桁、ハイフンなし)"
            error={!!errors.phone}
            helperText={errors.phone}
          />
        </FormControl>
        <FormControl
          margin="normal"
          required
          fullWidth
          className={clsx(!verifier.confirmationResult && classes.hidden)}
        >
          <TextField
            value={account.code}
            onChange={(e) => {
              clearErrors();
              setAccount({ ...account, code: hankaku2Zenkaku(e.target.value) });
            }}
            disabled={loading}
            autoComplete="new-password"
            label="コード"
            error={!!errors.code}
            helperText={errors.code}
          />
        </FormControl>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={sendPhone}
            disabled={loading}
            size="large"
            fullWidth
            className={clsx(!!verifier.confirmationResult && classes.hidden)}
          >
            SMSで認証番号を取得する
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={checkCode}
            disabled={loading}
            size="large"
            fullWidth
            className={clsx(!verifier.confirmationResult && classes.hidden)}
          >
            SMSで届いた認証番号を入力
          </Button>
        </Grid>
        <div id="recaptcha-container" style={{ display: 'none' }}></div>
      </Paper>
    </form>
  );
};

export default SignInPhone;
