import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import firebase from './firebase';
import { Allowance, IncomeRevision } from './types';
import './App.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 30,
    backgroundColor: 'white',
  },
  allowanceName: {
    margin: '15px 15%',
    fontSize: 24,
    fontWeight: 'bold',
  },
  allowanceIdea: {
    margin: '15px 15%',
  },
  allowanceDescription: {
    margin: '15px 18%',
    fontSize: 14,
  },
  revisionComment: {
    margin: '15px 15%',
  },
  table: {
    tableLayout: 'fixed',
    width: '70%',
    margin: '0 15%',
  },
  numberCol: {
    width: '10%',
    textAlign: 'center',
    border: '1px solid gray',
  },
  nameCol: {
    width: '40%',
    border: '1px solid gray',
  },
  descriptionCol: {
    width: '50%',
    border: '1px solid gray',
  },
}));

interface AllowanceDialogProps {
  open: boolean;
  month: string;
  userCode: string;
  allowanceCode: string;
  onClose: () => void;
}

const AllowanceDialog: React.FC<AllowanceDialogProps> = ({
  open,
  month,
  userCode,
  allowanceCode,
  onClose,
}) => {
  const [allowance, setAllowance] = useState<Allowance>();
  const [revision, setRevision] = useState<IncomeRevision>();
  const classes = useStyles();

  useEffect(() => {
    queryAllowance();
  }, []);

  const queryAllowance = async () => {
    const db = firebase.firestore();

    const snapIncomeRevision = await db
      .collection('users')
      .doc(userCode)
      .collection('incomeRevisions')
      .where('month', '<=', month)
      .orderBy('month', 'desc')
      .limit(1)
      .get();
    if (snapIncomeRevision.size > 0) {
      const revision = snapIncomeRevision.docs[0].data() as IncomeRevision;
      if (revision) {
        setRevision(revision);
      }
    }
    const snapAllowance = await db
      .collection('allowances')
      .doc(allowanceCode)
      .get();

    const allowanceData = snapAllowance.data() as Allowance;
    if (allowanceData) {
      setAllowance(allowanceData);
    }
  };

  if (allowance) {
    return (
      <Dialog
        open
        onClose={onClose}
        fullScreen
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogContent>
          <Paper className={classes.paper}>
            <div className={classes.allowanceName}>{allowance.name}</div>
            <div className={classes.allowanceIdea}>
              {allowance.name}の考え方
            </div>
            <div className={classes.allowanceDescription}>
              {allowance.description}
            </div>
            {allowanceCode === 'ability' && (
              <>
                <div className={classes.revisionComment}>
                  {revision?.comment}
                </div>
                <Table size={'small'} className={classes.table}>
                  <TableBody>
                    {revision?.items.map((item) => (
                      <TableRow>
                        <TableCell className={classes.numberCol}>
                          {item.mark}
                        </TableCell>
                        <TableCell className={classes.nameCol}>
                          {item.name}
                        </TableCell>
                        <TableCell className={classes.descriptionCol}>
                          {item.description}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </>
            )}
          </Paper>
          <Box mt={2}>
            <Button
              variant="outlined"
              color="inherit"
              size="medium"
              onClick={onClose}
            >
              閉じる
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
};

export default AllowanceDialog;
