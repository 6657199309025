import React, { useState, useRef, useContext } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';

import { KkbUser, User } from './types';
import {
  errorMessage,
  nameWithCode,
  shapeUserCode,
  toInternationalPhoneNumber,
  userCodeFromEmail,
  validEmail,
  zeroPadding,
} from './tools';
import Alert from './Alert';
import AppContext from './AppContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  paper: {
    padding: 20,
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

const csv = require('csv-parser');

interface Result {
  result: boolean;
  operation: 'create' | 'update' | null;
  userName: string;
  message?: string;
}

interface ImportUserProps {
  open: boolean;
  onClose: () => void;
}

const ImportUser: React.FC<ImportUserProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [enablePreProc, setEnablePreProc] = useState<boolean>(true);
  const [enableAuth, setEnableAuth] = useState<boolean>(true);
  const [enableUser, setEnableUser] = useState<boolean>(true);
  const [messages, setMessages] = useState<string[]>([]);
  const { currentUser, user } = useContext(AppContext);
  const ref = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  const currentUserName = () => {
    let name = nameWithCode(user);
    if (!name) name = currentUser?.uid || '';
    return name;
  };

  const getUsersFromCSV = async (blob: File) => {
    return new Promise<KkbUser[]>((resolve, reject) => {
      // CSV 読み込み
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const columns = {
          code: '社員番号',
          name: '氏名',
          kana: 'カナ',
          email: 'email',
          phoneNumber: '携帯番号',
          enteredAt: '入社日',
          retiredAt: '退社日',
          parentCode: '親コード',
        };
        const kkbUsers: KkbUser[] = [];
        const stream = csv();
        stream.on('data', async (data: { [key: string]: string }) => {
          const code = shapeUserCode(data[columns.code]);
          const email = data[columns.email];
          const phoneNumber = data[columns.phoneNumber];
          const [lastName, firstName] = data[columns.name].split(' ');
          const [lastKana, firstKana] = data[columns.kana].split(' ');
          const enteredAt = data[columns.enteredAt];
          const retiredAt = data[columns.retiredAt];
          const parentCode = shapeUserCode(data[columns.parentCode]);
          let kkbUser: KkbUser = {
            code,
            lastName: lastName || '',
            firstName: firstName || '',
            lastKana: lastKana || '',
            firstKana: firstKana || '',
            enteredAt,
            retiredAt,
            parentCode,
          };
          if (email) kkbUser.email = email;
          if (phoneNumber)
            kkbUser.phoneNumber = toInternationalPhoneNumber(phoneNumber);
          kkbUsers.push(kkbUser);
        });
        stream.write(e.target.result);
        resolve(kkbUsers);
      };
      reader.readAsText(blob);
    });
  };

  const createErrorInfos = (results: Result[], title: string) => {
    const msgs: string[] = [];
    const created = results.filter(
      (result) => result.operation === 'create' && result.result
    );
    const updated = results.filter(
      (result) => result.operation === 'update' && result.result
    );
    msgs.push(`${title}: 作成(${created.length}件), 更新(${updated.length}件)`);
    results
      .filter((result) => !result.result)
      .forEach((result) => {
        const operation = result.operation === 'create' ? '作成' : '更新';
        msgs.push(
          `　エラー(${operation}): ${result.userName} ${result.message}`
        );
      });
    return msgs;
  };

  // 認証データではログイン済なのに、user collection のfirstSignInAt が nullになっているデータを更新する
  const updateFirstSignInAt = async () => {
    const db = firebase.firestore();
    const querySnap = await db
      .collection('users')
      .where('firstSignInAt', '==', null)
      .get();
    const users: User[] = [];
    querySnap.forEach((doc) => {
      const data = doc.data();
      users.push(data as User);
    });
    let userRecords: any[] = [];
    const procUnit = 100;
    const indexes: number[] = [];
    for (let i = 0; i < users.length / procUnit; i++) {
      indexes.push(i * procUnit);
    }
    for await (const start of indexes) {
      const userCodes = users
        .slice(start, start + procUnit)
        .map((user) => user.code);

      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('getAuthUsers');
      const result = await func({ userCodes });
      userRecords = userRecords.concat(result.data);
    }
    const signedUsers = userRecords.filter((user) => user.lastSignInTime);
    for await (const userRecord of signedUsers) {
      const userCode = userCodeFromEmail(userRecord.email);
      if (
        userCode &&
        userRecord.lastSignInTime &&
        typeof userRecord.lastSignInTime === 'string'
      ) {
        const lastSignInTime = firebase.firestore.Timestamp.fromDate(
          new Date(userRecord.lastSignInTime)
        );
        await db.collection('users').doc(userCode).set(
          {
            firstSignInAt: lastSignInTime,
          },
          { merge: true }
        );
      }
    }
    console.log({ signedUsers });
  };

  const ImportUsers = async () => {
    const files = ref.current?.files;
    const blob = files && files[0];
    setMessages([]);
    if (blob) {
      try {
        setLoading(true);
        // 認証データではログイン済なのに、firstSignInAt が nullになっているデータを更新する
        if (enablePreProc) {
          await updateFirstSignInAt();
        }

        const db = firebase.firestore();
        const kkbUsers: KkbUser[] = await getUsersFromCSV(blob);
        const savedBy = currentUserName();
        // 社員情報作成
        if (enableUser) {
          const procUnit = 100;
          const tasks: Promise<Result[]>[] = [];
          for (let i = 0; i < kkbUsers.length / procUnit; i++) {
            const kkbUsers2 = kkbUsers.slice(i * procUnit, (i + 1) * procUnit);
            const promise = new Promise<Result[]>(async (resolve) => {
              const results: Result[] = [];
              for await (let kkbUser of kkbUsers2) {
                const result: Result = {
                  result: false,
                  operation: null,
                  userName: nameWithCode(kkbUser),
                  message: '',
                };
                try {
                  const userCode = kkbUser.code;
                  const rank = userCode?.match(/^\d+$/)
                    ? zeroPadding(Number(userCode), 10)
                    : (userCode + '').padEnd(10, '0');

                  const userParams: any = {
                    code: kkbUser.code,
                    parentCode: kkbUser.parentCode,
                    firstName: kkbUser.firstName,
                    lastName: kkbUser.lastName,
                    firstKana: kkbUser.firstKana,
                    lastKana: kkbUser.lastKana,
                    rank,
                    enteredAt: kkbUser.enteredAt
                      ? firebase.firestore.Timestamp.fromDate(
                          new Date(kkbUser.enteredAt)
                        )
                      : null,
                    retiredAt: kkbUser.retiredAt
                      ? firebase.firestore.Timestamp.fromDate(
                          new Date(kkbUser.retiredAt)
                        )
                      : null,
                    savedBy,
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                  };
                  const snapshot = await db
                    .collection('users')
                    .doc(kkbUser.code)
                    .get();
                  const user = snapshot.data();
                  if (user) {
                    result.operation = 'update';
                    if (
                      !user.firstSignInAt &&
                      kkbUser.email &&
                      kkbUser.email !== user.email &&
                      validEmail(kkbUser.email)
                    ) {
                      userParams.email = kkbUser.email;
                    }
                  } else {
                    if (kkbUser.email && validEmail(kkbUser.email)) {
                      userParams.email = kkbUser.email;
                    }
                    result.operation = 'create';
                    userParams.createdAt = firebase.firestore.FieldValue.serverTimestamp();
                    userParams.firstSignInAt = null;
                  }
                  await db
                    .collection('users')
                    .doc(kkbUser.code)
                    .set(userParams, { merge: true });
                  result.result = true;
                } catch (error) {
                  result.result = false;
                  result.message = errorMessage(error);
                }
                results.push(result);
              }
              resolve(results);
            });
            tasks.push(promise);
          }
          const res = await Promise.all(tasks);
          const results = ([] as Result[]).concat(...res); // flatten
          console.log({ results });

          const msgs1 = createErrorInfos(results, '社員情報');
          setMessages(msgs1);

          // データ件数更新
          const query = await db.collection('users').get();
          await db
            .collection('userCounts')
            .doc('all')
            .set({ count: query.size });
        }

        // 認証情報作成
        if (enableAuth) {
          const functions = firebase.app().functions('asia-northeast1');
          const func = functions.httpsCallable('registAuthUsers', {
            timeout: 540000,
          });
          const parentUsers = kkbUsers.filter((kkbUser) => !kkbUser.parentCode);
          const procUnit = 100;
          const indexes: number[] = [];
          for (let i = 0; i < parentUsers.length / procUnit; i++) {
            indexes.push(i * procUnit);
          }
          let resultAuths: Result[] = [];
          for await (const start of indexes) {
            const funcResult = await func({
              kkbUsers: parentUsers.slice(start, start + procUnit),
            });
            resultAuths = resultAuths.concat(funcResult.data as Result[]);
          }
          console.log({ resultAuths });
          const msgs2 = createErrorInfos(resultAuths, '認証情報');
          if (msgs2.length > 0) {
            if (enableUser) setMessages((prev) => prev.concat('-'.repeat(50)));
            setMessages((prev) => prev.concat(msgs2));
          }
        }

        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
        setMessages((prev) => prev.concat(errorMessage(error)));
      }
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <Container maxWidth="xs">
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            社員情報取込
          </Typography>
          <Paper square className={classes.paper}>
            <form>
              <input
                type="file"
                name="ref"
                accept=".csv"
                disabled={loading}
                ref={ref}
              />
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enableUser"
                      checked={enablePreProc}
                      disabled={loading}
                      onChange={(e) => {
                        setEnablePreProc(e.target.checked);
                      }}
                    />
                  }
                  label="前処理"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enableUser"
                      checked={enableUser}
                      disabled={loading}
                      onChange={(e) => {
                        setEnableUser(e.target.checked);
                      }}
                    />
                  }
                  label="社員情報保存"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enableAuth"
                      checked={enableAuth}
                      disabled={loading}
                      onChange={(e) => {
                        setEnableAuth(e.target.checked);
                      }}
                    />
                  }
                  label="認証情報保存"
                />
              </FormControl>

              <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={ImportUsers}
                >
                  取込実行
                </Button>
              </Grid>
            </form>
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
        </Container>
        <Box m={4}>
          {messages.length > 0 && (
            <Alert message={messages} onClose={() => setMessages([])} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default ImportUser;
