import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
  Link,
  makeStyles,
} from '@material-ui/core';
import firebase from './firebase';
import 'firebase/auth';

import Alert, { AlertMessage } from './Alert';
import AppContext from './AppContext';
import ForgotPasswordDialog from './ForgotPasswordDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 35,
    height: 250,
    backgroundColor: 'white',
  },
}));

interface FormErrors {
  code: string | null;
  password: string | null;
  others: string | null;
}

const SignIn: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [account, setAccount] = useState({ code: '', password: '' });
  const [dialog, setDialog] = useState(false);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const [errors, setErrors] = useState<FormErrors>({
    code: '',
    password: '',
    others: '',
  });
  const { currentUser, savePassCode } = useContext(AppContext);
  const classes = useStyles();

  useEffect(() => {
    setAuthorized(!!firebase.auth().currentUser);
  }, [currentUser]);

  const clearErrors = () => {
    setErrors({ code: '', password: '', others: '' });
  };

  const login = () => {
    clearErrors();
    if (!account.code) {
      setErrors({ ...errors, code: '社員番号を入力してください。' });
      return;
    }
    if (!account.password) {
      setErrors({ ...errors, password: 'パスワードを入力してください。' });
      return;
    }
    setLoading(true);
    const email = account.code + '@ebondsalary.com';
    firebase
      .auth()
      .signInWithEmailAndPassword(email, account.password)
      .then(() => {
        savePassCode('');
        setLoading(false);
      })
      .catch((error) => {
        console.log({ error });
        setLoading(false);
        setErrors({ ...errors, others: `ログインに失敗しました。` });
      });
  };

  if (authorized) {
    return <>{children}</>;
  }

  return (
    <form>
      <Paper className={classes.paper}>
        {loading && (
          <Grid container direction="row" justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
        )}
        {errors.others && (
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography color="error" variant="caption">
              {errors.others}
            </Typography>
          </Grid>
        )}
        <FormControl margin="normal" fullWidth>
          <TextField
            label="社員番号"
            value={account.code}
            autoComplete="new-password"
            disabled={loading}
            onChange={(e) => {
              clearErrors();
              setAccount({ ...account, code: e.target.value });
            }}
            error={!!errors.code}
            helperText={errors.code}
          />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <TextField
            label="パスワード"
            type="password"
            value={account.password}
            autoComplete="new-password"
            disabled={loading}
            onChange={(e) => {
              clearErrors();
              setAccount({ ...account, password: e.target.value });
            }}
            error={!!errors.password}
            helperText={errors.password}
          />
        </FormControl>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={login}
            disabled={loading}
            size="large"
            fullWidth
          >
            ログイン
          </Button>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ marginTop: 20 }}
        >
          <Typography variant="caption">
            パスワードを忘れた方は
            <Link href="#" onClick={() => setDialog(true)}>
              こちらをクリック
            </Link>
          </Typography>
        </Grid>
        {messages.content.length > 0 && (
          <Alert
            message={messages.content}
            severity={messages.severity}
            onClose={() => setMessages({ content: [] })}
          />
        )}
      </Paper>
      {dialog && (
        <ForgotPasswordDialog
          open={!!dialog}
          onClose={() => setDialog(false)}
          setMessages={setMessages}
        />
      )}
    </form>
  );
};

export default SignIn;
