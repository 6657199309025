import React from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: '20vh',
    padding: 30,
    backgroundColor: 'white',
  },
  link: {
    textDecoration: 'none',
  },
}));

const NotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xs">
      <Paper square className={classes.paper}>
        <Typography component="h1" variant="body1" align="center">
          ページが見つかりません
        </Typography>
        <Typography component="h1" variant="subtitle1" align="center">
          404 not found
        </Typography>
      </Paper>
      <Box mt={2}>
        <Link to="/" className={classes.link}>
          <Button variant="outlined" color="inherit" size="medium">
            トップ画面に戻る
          </Button>
        </Link>
      </Box>
    </Container>
  );
};

export default NotFound;
