import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { GensenLayout } from './types';
import Alert, { AlertMessage } from './Alert';

import EditGensenLayout from './EditGensenLayout';

import firebase from './firebase';
import 'firebase/auth';
import 'firebase/firestore';
import './App.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 30,
    backgroundColor: 'white',
  },
  table: {
    marginTop: 20,
  },
  year: {
    width: '60%',
  },
  action: {
    width: '40%',
  },
  button: {
    margin: '0 3px',
    padding: 2,
    minWidth: 40,
    height: 24,
  },
  button2: {
    marginLeft: 15,
    marginTop: 12,
  },
  link: {
    textDecoration: 'none',
  },
  select: {
    width: 150,
  },
  selectCompany: {
    width: 300,
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

const GensenLayoutList: React.FC = (props) => {
  const [layouts, setLayouts] = useState<{ [id: string]: GensenLayout }>({});
  const [targetLayout, setTargetLayout] = useState<{
    id: string | null;
    layout: GensenLayout;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const classes = useStyles();
  const now = new Date();

  const queryLayouts = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const querySnapshot = await db
      .collection('gensenLayouts')
      .orderBy('year')
      .get();

    if (querySnapshot.size > 0) {
      const new_layouts: { [id: string]: GensenLayout } = {};
      querySnapshot.forEach(
        (doc) => (new_layouts[doc.id] = doc.data() as GensenLayout)
      );
      setLayouts(new_layouts);
    } else {
      setLayouts({});
      setMessages({ content: 'データが存在しません。', severity: 'error' });
    }
    setLoading(false);
  };

  if (targetLayout) {
    return (
      <EditGensenLayout
        docId={targetLayout.id}
        layout={targetLayout.layout}
        onClose={() => setTargetLayout(null)}
      />
    );
  } else {
    return (
      <>
        {loading && <LinearProgress />}
        <Box m={5}>
          <Container maxWidth="sm">
            <Typography
              component="h2"
              variant="inherit"
              align="center"
              className={classes.title}
            >
              源泉徴収レイアウト
            </Typography>
            <Paper className={classes.paper}>
              <Grid container spacing={4}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    className={classes.button2}
                    onClick={() => queryLayouts()}
                  >
                    検索
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    className={classes.button2}
                    onClick={() =>
                      setTargetLayout({
                        id: null,
                        layout: {
                          year: now.getFullYear(),
                          width: 0,
                          height: 0,
                          offsetX: 0,
                          offsetY: 0,
                          scaleX: 1,
                          scaleY: 1,
                          items: [],
                          createdAt: firebase.firestore.Timestamp.fromDate(
                            new Date()
                          ),
                        },
                      })
                    }
                  >
                    新規
                  </Button>
                </Grid>
              </Grid>
              {Object.keys(layouts).length > 0 && (
                <Typography component="p" variant="caption" align="left">
                  {Object.keys(layouts).length}件のデータが見つかりました
                </Typography>
              )}
              <Table size="small" className={classes.table}>
                <colgroup>
                  <col className={classes.year} />
                  <col className={classes.action} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>年度</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(layouts).map(([id, layout], index) => (
                    <TableRow key={index}>
                      <TableCell>{layout.year}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="inherit"
                          size="small"
                          className={classes.button}
                          onClick={() => setTargetLayout({ id, layout })}
                        >
                          編集
                        </Button>
                        <Button
                          variant="outlined"
                          color="inherit"
                          size="small"
                          className={classes.button}
                          onClick={() =>
                            setTargetLayout({
                              id: null,
                              layout: {
                                ...layout,
                                year: now.getFullYear(),
                              },
                            })
                          }
                        >
                          ｺﾋﾟｰして編集
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <Box mt={2}>
              <Link to="/" className={classes.link}>
                <Button variant="outlined" color="inherit" size="medium">
                  トップ画面に戻る
                </Button>
              </Link>
            </Box>
            {messages.content.length > 0 && (
              <Alert
                message={messages.content}
                severity={messages.severity}
                onClose={() => setMessages({ content: [] })}
              />
            )}
          </Container>
        </Box>
      </>
    );
  }
};

export default GensenLayoutList;
