import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  makeStyles,
} from '@material-ui/core';
import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import { Company } from './types';
import { errorMessage } from './tools';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  dialog: {
    height: '100%',
    width: '100%',
    maxWidth: 'initial',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  text_area: {
    height: 240,
  },
}));

interface CompanyDialogProps {
  uid: string | null;
  code: string | null;
  name: string | null;
  deadline: 'end' | '15th';
  formalName?: string;
  address?: string;
  tel?: string;
  open: boolean;
  onClose: () => void;
}

const CompanyDialog: React.FC<CompanyDialogProps> = ({
  open,
  onClose,
  uid,
  code,
  name,
  deadline,
  formalName,
  address,
  tel,
}) => {
  const [company, setCompany] = useState<Company>({
    code,
    name,
    deadline,
    formalName,
    address,
    tel,
  });
  const [errors, setErrors] = useState({ code: '', name: '' });
  const classes = useStyles();

  useEffect(
    () => setCompany({ code, name, deadline, formalName, address, tel }),
    [code, name, deadline, formalName, address, tel]
  );

  const companySave = async () => {
    const db = firebase.firestore();
    const errs = {
      code: '',
      name: '',
    };
    if (!company.code) {
      errs.code = '会社コードが入力されていません。';
    }
    if (!company.name) {
      errs.name = '会社名が入力されていません。';
    }
    if (!uid && company.code) {
      const snapshot = await db.collection('companies').doc(company.code).get();
      const doc = snapshot.data();
      if (doc) {
        errs.code = '会社コードはすでに存在しています。';
      }
    }
    setErrors(errs);

    const existErrors = Object.values(errs).filter((err) => !!err).length > 0;
    if (!existErrors) {
      try {
        if (company.code) {
          await db.collection('companies').doc(company.code).set(company);
          onClose();
        }
      } catch (error) {
        console.log({ error });
        alert(errorMessage(error));
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth="xs"
      fullWidth
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogContent>
        <form>
          <FormControl fullWidth>
            <TextField
              label="会社コード"
              disabled={!!uid}
              value={company.code || ''}
              error={!!errors.code}
              helperText={errors.code}
              onChange={(e) => setCompany({ ...company, code: e.target.value })}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="会社名"
              value={company.name || ''}
              error={!!errors.name}
              helperText={errors.name}
              onChange={(e) => setCompany({ ...company, name: e.target.value })}
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="deadline">締日</InputLabel>
            <Select
              value={company.deadline}
              onChange={(e) =>
                setCompany({
                  ...company,
                  deadline: e.target.value as 'end' | '15th',
                })
              }
              labelId="deadline"
            >
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value="end">末締め</MenuItem>
              <MenuItem value="15th">15日締め</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="正式名"
              value={company.formalName || ''}
              onChange={(e) =>
                setCompany({ ...company, formalName: e.target.value })
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="住所"
              value={company.address || ''}
              onChange={(e) =>
                setCompany({ ...company, address: e.target.value })
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="電話番号"
              value={company.tel || ''}
              onChange={(e) => setCompany({ ...company, tel: e.target.value })}
            />
          </FormControl>
          <Grid
            container
            direction="column"
            justify="space-around"
            alignItems="stretch"
            style={{ margin: '10px 0' }}
          >
            <Button variant="contained" color="primary" onClick={companySave}>
              保存
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyDialog;
