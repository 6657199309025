import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import CompanyDialog from './CompanyDialog';
import { Company } from './types';
import firebase from './firebase';
import 'firebase/auth';
import 'firebase/firestore';
import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  table: {
    tableLayout: 'fixed',
  },
  cell: {
    border: '1px solid gray',
    width: '10%',
    height: 50,
    padding: 0,
  },
  code: {
    width: '15%',
  },
  name: {
    width: '55%',
  },
  deadline: {
    width: '15%',
  },
  action: {
    width: '15%',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
  label: {
    padding: 10,
  },
}));

const CompanyList: React.FC = (props) => {
  const [companies, setCompanies] = useState<{ [uid: string]: Company }>({});
  const [newCompany, setNewCompany] = useState<boolean>(false);
  const [uid, setUid] = useState<string | null>(null);
  const deadlineTexts = { end: '末締め', '15th': '15日締め' };
  const classes = useStyles();

  useEffect(() => {
    queryCompanys();
  }, []);

  const queryCompanys = () => {
    firebase
      .firestore()
      .collection('companies')
      .orderBy('code')
      .onSnapshot((querySnapshot) => {
        let new_companies: { [uid: string]: Company } = {};
        querySnapshot.forEach(
          (doc) => (new_companies[doc.id] = doc.data() as Company)
        );
        setCompanies(new_companies);
      });
  };

  return (
    <Box m={5}>
      <Container maxWidth="md">
        <Typography
          component="h2"
          variant="inherit"
          align="center"
          className={classes.title}
        >
          会社一覧
        </Typography>
        <Paper>
          {(!!uid || newCompany) && (
            <CompanyDialog
              open
              uid={uid}
              code={uid && companies[uid].code}
              name={uid && companies[uid].name}
              deadline={(uid && companies[uid].deadline) || 'end'}
              formalName={(uid && companies[uid].formalName) || ''}
              address={(uid && companies[uid].address) || ''}
              tel={(uid && companies[uid].tel) || ''}
              onClose={() => {
                setNewCompany(false);
                setUid(null);
              }}
            />
          )}
          <Typography
            component="p"
            variant="caption"
            align="left"
            className={classes.label}
          >
            {Object.keys(companies).length}件のデータが見つかりました
          </Typography>
          <Table size="small" className={classes.table}>
            <colgroup>
              <col className={classes.code} />
              <col className={classes.name} />
              <col className={classes.deadline} />
              <col className={classes.action} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>コード</TableCell>
                <TableCell>会社名</TableCell>
                <TableCell>締め日</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setNewCompany(true);
                      setUid(null);
                    }}
                  >
                    新規
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(companies).map(([uid, company], index) => (
                <TableRow key={index}>
                  <TableCell>{company.code}</TableCell>
                  <TableCell>{company.name}</TableCell>
                  <TableCell>{deadlineTexts[company.deadline]}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="inherit"
                      size="small"
                      onClick={() => setUid(uid)}
                    >
                      編集
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Box mt={2}>
          <Link to="/" className={classes.link}>
            <Button variant="outlined" color="inherit" size="medium">
              トップ画面に戻る
            </Button>
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default CompanyList;
