import React, { useState, useContext, useRef } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import * as xlsx from 'xlsx';
import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import {
  errorMessage,
  zeroPadding,
  shapeUserCode,
  fullName,
  userCodeFromEmail,
  yearFromWareki,
  nameWithCode,
} from './tools';
import Alert, { AlertMessage } from './Alert';
import OperationList from './OperationList';
import { User, Company } from './types';
import AppContext from './AppContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  paper: {
    padding: 20,
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

interface Result {
  result: boolean;
  userName: string;
  message: string;
}

interface ImportGensenProps {
  open: boolean;
  onClose: () => void;
}

const ImportGensen: React.FC<ImportGensenProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const [confirmOverwrite, setConfirmOverwrite] = useState<boolean>(false);
  const [overwrite, setOverwrite] = useState<boolean>(false);
  const { companies, currentUser, user, customClaims } = useContext(AppContext);
  const ref = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const USER_CODE = '社員番号';
  const USER_NAME = '氏名';

  const currentUserName = () => {
    let name = nameWithCode(user);
    if (!name) name = currentUser?.uid || '';
    return name;
  };

  const checkAuth = (companyCode: string | null) => {
    if (customClaims && companyCode) {
      const role = customClaims.role;
      if (role === 'admin') return true;
      if (role === 'manager') {
        return (customClaims.companies || []).includes(companyCode);
      }
    }
  };

  const getGensensFromExcel = async (blob: File) => {
    return new Promise<{
      year: number;
      company: Company;
      headers3: (string | Date)[][];
      data: any[];
    }>((resolve, reject) => {
      const columnNames = ['集計パターン名', '会社名', '集計対象', '集計方法'];
      let year: number | null = null;
      let company: Company | null = null;
      let headers3: (string | Date)[][] = [];
      const data: any[] = [];
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          var fileData = reader.result;
          var wb = xlsx.read(fileData, {
            type: 'binary',
            raw: true,
            cellDates: true,
            cellStyles: true,
            cellHTML: true,
            sheetStubs: true,
          });
          const sheet = wb.Sheets[wb.SheetNames[0]];
          const range_a1 = sheet['!ref'];
          if (range_a1) {
            const range = xlsx.utils.decode_range(range_a1);
            for (let r = range.s.r; r <= range.e.r; r++) {
              const rows: (string | Date)[] = [];
              for (let c = range.s.c; c <= range.e.c; c++) {
                const p = xlsx.utils.encode_cell({ c, r });
                const cell = sheet[p];
                const cell_type = cell?.t;
                if (cell_type === 'd') {
                  rows.push(cell.v as Date);
                } else if (cell_type === 'n' && cell.v < 0) {
                  rows.push('-' + cell.w.replace(/[()]/g, ''));
                } else {
                  rows.push(cell?.w || '');
                }
              }
              if (r < 7) {
                if (r < columnNames.length) {
                  if (columnNames[r] !== rows[0]) {
                    throw Error(`不正なヘッダ情報(${rows[0]}: ${rows[1]})。`);
                  }
                  if (r === 1) {
                    const c = Object.values(companies).find(
                      (c) => c.name === rows[1]
                    );
                    if (c) {
                      if (!checkAuth(c.code)) {
                        throw Error(`取込権限がありません(${rows[1]})。`);
                      }
                      company = c;
                    } else {
                      throw Error(`会社情報が見つかりません(${rows[1]})。`);
                    }
                  } else if (r === 2) {
                    const y = yearFromWareki(rows[1] as string);
                    if (y) {
                      year = y;
                    } else {
                      throw Error(`年度が不明です(${rows[1]})。`);
                    }
                  }
                } else {
                  headers3.push(rows);
                }
              } else {
                data.push(rows);
              }
            }
            if (year && company) {
              resolve({ year, company, headers3, data });
            } else {
              reject({ year, company, headers3, data });
            }
          }
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsBinaryString(blob);
    });
  };

  const getUserKana = (user: User) => {
    if (user.firstKana && user.lastKana) {
      return user.lastKana + ' ' + user.firstKana;
    } else {
      return (user.lastKana || '') + (user.firstKana || '');
    }
  };

  const saveGensens = async (
    year: number,
    company: Company,
    headers: (string | Date)[],
    data: any[]
  ) => {
    const db = firebase.firestore();
    if (data.length === 0) throw Error('データが存在しません。');

    // ヘッダの登録
    const cols: { [key: string]: { label: string; col: number } } = {
      userCode: { label: USER_CODE, col: -1 },
      userName: { label: USER_NAME, col: -1 },
    };
    for (const key in cols) {
      const col = headers.findIndex((name) => name === cols[key].label);
      if (col >= 0) cols[key].col = col;
      else throw Error(`${cols[key].label}の列が見つかりません。`);
    }

    const companyCode = company.code;
    const companyName = company.name;
    const savedBy = currentUserName();

    const headerId = String(year); // 年度でIDを作成
    const headerSnapshot = await db
      .collection('gensenHeaders')
      .doc(headerId)
      .get();

    const gensen_header = headerSnapshot.data();
    if (gensen_header) {
      let message = '';
      if (headers.toString() !== gensen_header.contents.toString()) {
        const diff =
          headers.length !== gensen_header.contents.length
            ? `列数: ${gensen_header.contents.length}⇒${headers.length}`
            : '';
        message = `エラー！データが既に存在し、ヘッダも異なります(${diff})。`;
        setOverwrite(false);
        setConfirmOverwrite(false);
        throw Error(message);
      }
    }

    await db.collection('gensenHeaders').doc(headerId).set({
      companyCode, // 全社共通のはずだが、一応保存
      companyName,
      year,
      savedBy,
      contents: headers,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    const procUnit = 100;
    const tasks: Promise<Result[]>[] = [];
    for (let i = 0; i < data.length / procUnit; i++) {
      const partOfData = data.slice(i * procUnit, (i + 1) * procUnit);
      const promise = new Promise<Result[]>(async (resolve) => {
        const results: Result[] = [];
        for await (let row of partOfData) {
          const userCode = shapeUserCode(row[cols.userCode.col]);
          const userName = row[cols.userName.col];
          const user_code = userCode?.match(/^\d+$/)
            ? zeroPadding(Number(userCode), 10)
            : (userCode + '').padEnd(10, '0');
          const company_code = companyCode?.match(/^\d+$/)
            ? zeroPadding(Number(companyCode), 10)
            : (companyCode + '').padEnd(10, '0');
          const rank = `${user_code}${company_code}`;
          const result: Result = { result: false, userName, message: '' };
          try {
            const userSnap = await db.collection('users').doc(userCode).get();
            const userData = userSnap?.data() as User | undefined;
            const userKana = userData ? getUserKana(userData) : '';

            if (!userData?.code) {
              const [lastName, firstName] = userName.split(/\s+/);
              const rank = userCode.match(/^\d+$/)
                ? zeroPadding(Number(userCode), 10)
                : (userCode + '').padEnd(10, '0');
              const newUserData: User = {
                code: userCode,
                firstName: firstName || '',
                lastName: lastName || '',
                firstKana: null,
                lastKana: null,
                savedBy,
                parentCode: null,
                gensenYears: [year],
                rank,
              };
              await db
                .collection('users')
                .doc(userCode)
                .set(newUserData, { merge: true });
            } else {
              await db
                .collection('users')
                .doc(userCode)
                .set(
                  {
                    gensenYears: firebase.firestore.FieldValue.arrayUnion(year),
                  },
                  { merge: true }
                );
            }

            const gensens = db
              .collection('users')
              .doc(userCode)
              .collection('gensens');

            // 源泉徴収保存
            const docId = `${year}:${companyCode}`;
            await gensens.doc(docId).set({
              companyCode,
              companyName,
              userCode,
              userName,
              userKana,
              year,
              rank,
              savedBy,
              contents: row,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
            result.result = true;
          } catch (error) {
            result.result = false;
            result.message = errorMessage(error);
          }
          results.push(result);
        }
        resolve(results);
      });
      tasks.push(promise);
    }
    const res = await Promise.all(tasks);
    const results = ([] as Result[]).concat(...res); // flatten
    return results;
  };

  const parseHeaders = (headers3: (string | Date)[][]) => {
    const headers: string[] = [];
    const level2Items = [
      '有',
      '従有',
      '老人',
      '適用数',
      '控除可能額',
      '居住開始年月日',
      '年末残高',
      '氏名',
      'フリガナ',
      '区分',
      '特別',
      '他',
      '一般',
    ];
    const level3Items = ['人', '従', '内'];
    let lastItem1 = '';
    let lastItem2 = '';
    if (
      headers3.length === 3 &&
      headers3[0].length === headers3[1].length &&
      headers3[1].length === headers3[2].length
    ) {
      for (const i in headers3[0]) {
        const item1 = headers3[0][i] as string;
        const item2 = headers3[1][i] as string;
        const item3 = headers3[2][i] as string;
        if (item1) lastItem1 = item1;
        if (item2) lastItem2 = item2;
        if (level3Items.includes(item3)) {
          const item = [lastItem1, lastItem2, item3]
            .filter((item) => !!item)
            .join('/');
          headers.push(item);
        } else if (level2Items.includes(item3)) {
          const item = [lastItem2, item3].filter((item) => !!item).join('/');
          headers.push(item);
        } else {
          headers.push(item3);
        }
      }
    }
    return headers;
  };

  const importGensens = async (e: React.FormEvent) => {
    e.preventDefault();
    const files = ref?.current?.files;
    const blob = files && files[0];
    if (blob && blob.name) {
      const db = firebase.firestore();
      setLoading(true);
      setMessages({ content: [] });
      try {
        const { year, company, headers3, data } = await getGensensFromExcel(
          blob
        );
        const headers = parseHeaders(headers3);
        const results = await saveGensens(year, company, headers, data);
        console.log({ results });
        const saved = results.filter((result) => result.result);
        setMessages((prev) => {
          return {
            content: prev.content.concat(
              `源泉徴収データ 保存: ${saved.length}件`
            ),
            severity: 'success',
          };
        });
        results
          .filter((result) => !result.result)
          .forEach((result) => {
            setMessages((prev) => {
              return {
                content: prev.content.concat(
                  `　エラー: ${result.userName} ${result.message}`
                ),
                severity: 'error',
              };
            });
          });

        // データ件数更新
        await updateGensenCounts(company, year);

        // 操作履歴
        const userCode = userCodeFromEmail(currentUser?.email || '');
        if (currentUser && userCode) {
          const userName = !!user ? fullName(user) : currentUser.email;
          await db
            .collection('users')
            .doc(userCode)
            .collection('operations')
            .add({
              userCode,
              userName,
              action: 'importGensen',
              actionName: '源泉徴収取込',
              message: `会社:${company.name}、対象年度:${year}、件数:${saved.length}`,
              uid: currentUser.uid,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }

        setConfirmOverwrite(false);
        setOverwrite(false);
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
        setMessages((prev) => {
          return {
            content: prev.content.concat(errorMessage(error)),
            severity: 'error',
          };
        });
      }
    }
  };

  const updateGensenCounts = async (company: Company, year: number) => {
    const companyCode = company?.code;
    if (year && companyCode) {
      const db = firebase.firestore();
      try {
        const query = await db
          .collectionGroup('gensens')
          .where('year', '==', year)
          .where('companyCode', '==', companyCode)
          .get();
        await db
          .collection('gensenCounts')
          .doc(`${year}:${companyCode}`)
          .set({ year, companyCode, count: query.size });
      } catch (error) {
        console.log({ error });
        setMessages((prev) => {
          return {
            content: prev.content.concat(
              errorMessage(
                error,
                'データのカウント処理でエラーが発生しました。'
              )
            ),
            severity: 'error',
          };
        });
      }
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <Container maxWidth="xs">
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            源泉徴収取込
          </Typography>
          <Paper square className={classes.paper}>
            <form onSubmit={importGensens}>
              <Box mt={2} mb={2}>
                <input
                  type="file"
                  name="ref"
                  ref={ref}
                  accept=".xlsx"
                  disabled={loading}
                  required
                />
              </Box>
              {confirmOverwrite && (
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="enableAuth"
                        checked={overwrite}
                        disabled={loading}
                        onChange={(e) => {
                          setOverwrite(e.target.checked);
                        }}
                      />
                    }
                    label="上書きする"
                  />
                </FormControl>
              )}

              <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
                style={{ margin: '10px 0' }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  取込実行
                </Button>
              </Grid>
            </form>
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
        </Container>
        <OperationList showAll={false} maxCount={20} />
        {messages.content.length > 0 && (
          <Alert
            message={messages.content}
            severity={messages.severity}
            onClose={() => setMessages({ content: [] })}
          />
        )}
      </Box>
    </>
  );
};

export default ImportGensen;
