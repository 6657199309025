import React, { useContext } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import AppContext from './AppContext';
import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  cell: {
    border: '1px solid gray',
    width: 60,
    height: 50,
    padding: 0,
  },
  title: {
    border: '1px solid gray',
    width: 30,
    color: 'white',
    backgroundColor: 'royalblue',
  },
  label: {
    padding: 0,
    height: 20,
    borderBottom: '1px solid gray',
    backgroundColor: 'aliceblue',
    textAlign: 'center',
    overflow: 'hidden',
  },
  value: {
    height: 30,
    textAlign: 'right',
    paddingTop: 10,
    boxSizing: 'border-box',
  },
  spacer: {
    height: 20,
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    width: 200,
    borderColor: '#FF8C00',
    color: '#FF8C00',
    '&:hover': {
      borderColor: '#FF8C0080',
      color: '#FF8C0080',
    },
  },
  button2: {
    width: 200,
    borderColor: '#EE6C00',
    color: '#EE6C00',
    '&:hover': {
      borderColor: '#EE6C0080',
      color: '#EE6C0080',
    },
  },
  button3: {
    width: 200,
    borderColor: '#EE4C44',
    color: '#EE4C44',
    '&:hover': {
      borderColor: '#EE6C0080',
      color: '#EE6C0080',
    },
  },
  button4: {
    width: 200,
    borderColor: '#CC4C44',
    color: '#CC4C44',
    '&:hover': {
      borderColor: '#BB6C0080',
      color: '#BB6C0080',
    },
  },
}));

interface ManagerMenuProp {
  setLoading: React.Dispatch<boolean>;
}

const ManagerMenu: React.FC<ManagerMenuProp> = () => {
  const { customClaims } = useContext(AppContext);

  const role = customClaims?.role;
  const admin = role === 'admin';
  const manager = admin || role === 'manager';
  const viewer = manager || role === 'viewer';
  const classes = useStyles();

  return (
    <Box mt={5}>
      {viewer && (
        <Box m={2}>
          <Link to="/incomes/list" className={classes.link}>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              className={classes.button}
            >
              給与明細一覧
            </Button>
          </Link>
        </Box>
      )}
      {manager && (
        <>
          <Box m={2}>
            <Link to="/incomes/import" className={classes.link}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                className={classes.button}
              >
                給与明細取込
              </Button>
            </Link>
          </Box>
          <Box m={2}>
            <Link to="/incomes/clear" className={classes.link}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                className={classes.button}
              >
                給与明細クリア
              </Button>
            </Link>
          </Box>
          <Box m={2}>&nbsp;</Box>
        </>
      )}
      {viewer && (
        <Box m={2}>
          <Link to="/bonuses/list" className={classes.link}>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              className={classes.button2}
            >
              賞与明細一覧
            </Button>
          </Link>
        </Box>
      )}
      {manager && (
        <>
          <Box m={2}>
            <Link to="/bonuses/import" className={classes.link}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                className={classes.button2}
              >
                賞与明細取込
              </Button>
            </Link>
          </Box>
          <Box m={2}>
            <Link to="/bonuses/clear" className={classes.link}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                className={classes.button2}
              >
                賞与明細クリア
              </Button>
            </Link>
          </Box>
          <Box m={2}>&nbsp;</Box>
        </>
      )}
      {viewer && (
        <Box m={2}>
          <Link to="/gensens/list" className={classes.link}>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              className={classes.button3}
            >
              源泉徴収一覧
            </Button>
          </Link>
        </Box>
      )}
      {manager && (
        <>
          <Box m={2}>
            <Link to="/gensens/import" className={classes.link}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                className={classes.button3}
              >
                源泉徴収取込
              </Button>
            </Link>
          </Box>
          <Box m={2}>
            <Link to="/gensens/clear" className={classes.link}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                className={classes.button3}
              >
                源泉徴収クリア
              </Button>
            </Link>
          </Box>
          <Box m={2}>&nbsp;</Box>
        </>
      )}
      {admin && (
        <>
          <Box m={2}>
            <Link to="/incomes/exportIncome" className={classes.link}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                className={classes.button4}
              >
                年収ダウンロード
              </Button>
            </Link>
          </Box>
          <Box m={2}>
            <Link to="/payments/import" className={classes.link}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                className={classes.button4}
              >
                社宅＆ボーナス取込
              </Button>
            </Link>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ManagerMenu;
