import React, { useState, useEffect, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Box,
  Button,
  Container,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  makeStyles,
  FormControl,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import Alert from './Alert';
import AppContext from './AppContext';
import SendPassCode from './SendPassCode';
import GensenViewer from './GensenViewer';
import { errorMessage } from './tools';
import { Gensen, GensenHeader } from './types';
import firebase from './firebase';
import 'firebase/auth';
import 'firebase/functions';
import './App.css';
import { userCodeFromEmail } from './tools';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  spacer: {
    height: 20,
  },
  year: {
    width: 150,
  },
  link: {
    textDecoration: 'none',
  },
}));

const MyGensenPage: React.FC = (props) => {
  const [targetYear, setTargetYear] = useState<number | null>(null);
  const [years, setYears] = useState<number[]>([]);
  const [gensens, setGensens] = useState<Gensen[]>([]);
  const [gensenHeader, setGensenHeader] = useState<GensenHeader | null>(null);
  const [messages, setMessages] = useState<string[]>([]);
  const [openPassCode, setOpenPassCode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { currentUser, user, passCode, savePassCode } = useContext(AppContext);
  const componentRef = useRef(null);
  const userCode = userCodeFromEmail(currentUser?.email || '');
  const classes = useStyles();

  useEffect(() => {
    const years = user?.gensenYears || [];
    setYears(years.sort());
  }, [user]);

  useEffect(() => {
    if (userCode && passCode && targetYear) {
      setLoading(true);
      resetData();
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('getGensenData');
      func({
        userCode,
        passCode,
        year: targetYear,
      })
        .then(async (result) => {
          console.log({ result });
          const {
            gensens,
            gensenHeader,
          }: { gensens: Gensen[]; gensenHeader: GensenHeader } = result.data;
          if (gensens && gensenHeader) {
            setGensens(gensens);
            setGensenHeader(gensenHeader);
          } else {
            resetData();
          }
          setTimeout(() => setLoading(false), 1000);
        })
        .catch((error) => {
          resetData();
          savePassCode('');
          setOpenPassCode(true);
          setMessages((prev) => prev.concat(errorMessage(error)));
          console.log({ error });
          setTimeout(() => setLoading(false), 1000);
        });
    }
  }, [userCode, passCode, targetYear, savePassCode]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const resetData = () => {
    setGensens([]);
    setGensenHeader(null);
    setMessages([]);
  };

  const changeTargetYear = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'number') {
      const year = Number(e.target.value);
      setTargetYear(year);
      if (e.target.value && !passCode) setOpenPassCode(true);
    } else {
      resetData();
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <SendPassCode
        open={openPassCode}
        onClose={() => setOpenPassCode(false)}
      />
      <Container maxWidth="md">
        {messages.length > 0 && (
          <Alert message={messages} onClose={() => setMessages([])} />
        )}
        <FormControl margin="normal" className={classes.year}>
          <InputLabel id="year-label">対象年度</InputLabel>
          <Select
            value={targetYear ? String(targetYear) : ''}
            onChange={changeTargetYear}
            labelId="year-label"
          >
            <MenuItem value="">
              <em></em>
            </MenuItem>
            {years.map((year) => (
              <MenuItem key={year} value={year}>{`${year}年`}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {gensens.length > 0 && (
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={handlePrint}
            style={{ marginTop: 30, marginLeft: 10 }}
          >
            印刷
          </Button>
        )}
        <div className="gensen_print" ref={componentRef}>
          {targetYear &&
            gensenHeader &&
            gensens.map((gensen, index) => (
              <GensenViewer
                key={index}
                year={targetYear}
                gensen={gensen}
                gensenHeader={gensenHeader}
              />
            ))}
        </div>
        <Box mt={2}>
          <Link to="/" className={classes.link}>
            <Button variant="outlined" color="inherit" size="medium">
              トップ画面に戻る
            </Button>
          </Link>
        </Box>
      </Container>
    </>
  );
};

export default MyGensenPage;
