import React, { useState, useEffect, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';

import { Bonus, BonusHeader } from './types';
import { zeroPadding, errorMessage, getTargetMonths } from './tools';
import Alert from './Alert';
import BonusViewer from './BonusViewer';
import { BonusTable, getBonusLayout, createBonusItems } from './BonusLayout';
import AppContext from './AppContext';

import firebase from './firebase';
import 'firebase/auth';
import 'firebase/functions';
import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  spacer: {
    height: 20,
  },
  month: {
    width: 150,
  },
}));

interface BonusPageProps {
  userCode: string;
  month: string;
  backLabel: string;
  onClose: () => void;
}

const BonusPage: React.FC<BonusPageProps> = ({
  userCode,
  month,
  backLabel,
  onClose,
}) => {
  const [bonusTables, setBonusTables] = useState<BonusTable[]>([]);
  const [targetMonth, setTargetMonth] = useState<string>(month);
  const [endMonth, setEndMonth] = useState<string>('');
  const [enableRange, setEnableRange] = useState<boolean>(false);
  const [months, setMonths] = useState<[number, number][]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { customClaims } = useContext(AppContext);
  const end_month = enableRange ? endMonth : targetMonth;
  const admin =
    customClaims?.role === 'admin' || !!customClaims?.allowAllCompanies;
  const companyCodes = customClaims?.companies;
  const classes = useStyles();

  useEffect(() => {
    const f = async () => {
      const target_months = await getTargetMonths(userCode, 'bonus', true);
      setMonths(
        target_months.map((month) => [+month.slice(0, 4), +month.slice(4)])
      );
    };
    f();
  }, [userCode]);

  useEffect(() => {
    queryBonuses(userCode, month, month, admin, companyCodes || []);
  }, [userCode, month, admin, companyCodes]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const queryBonuses = async (
    user_code: string,
    start_month: string,
    end_month: string,
    admin_mode: boolean,
    company_codes: string[]
  ) => {
    setBonusTables([]);
    setMessages([]);
    if (user_code && start_month) {
      setLoading(true);
      const db = firebase.firestore();
      const userCodes = [user_code];
      const chilren = await db
        .collection('users')
        .where('parentCode', '==', user_code)
        .get();
      chilren.forEach((child) => {
        userCodes.push(child.ref.id);
      });

      let count = 0;
      for await (const user_code of userCodes) {
        try {
          const collection = db
            .collection('users')
            .doc(user_code)
            .collection('bonuses');
          let query_bonuses = collection
            .orderBy('month')
            .startAt(start_month)
            .endAt(end_month);
          if (!admin_mode) {
            query_bonuses = query_bonuses.where(
              'companyCode',
              'in',
              company_codes
            );
          }
          const bonuses: Bonus[] = [];
          const snapBonus = await query_bonuses.get();
          snapBonus.forEach((doc) => bonuses.push(doc.data() as Bonus));

          for await (const bonus of bonuses) {
            const docId = `${bonus.payday}:${bonus.companyCode}`;
            const snapHeader = await db
              .collection('bonusHeaders')
              .doc(docId)
              .get();
            const targetDate = new Date(
              `${bonus.payday.substr(0, 4)}/${bonus.payday.substr(
                4,
                2
              )}/${bonus.payday.substr(6, 2)}`
            );
            const bonusLayout = await getBonusLayout(
              bonus.companyCode,
              targetDate
            );
            console.log({ bonus, bonusHeader: snapHeader.data(), bonusLayout });
            if (bonusLayout) {
              const bonus_table = createBonusItems(
                bonus,
                snapHeader.data() as BonusHeader,
                bonusLayout
              );
              if (bonus_table)
                setBonusTables((prev) => prev.concat(bonus_table));
            } else {
              setMessages((prev) =>
                prev.concat(
                  `レイアウト情報が存在しません(会社コード:${bonus.companyCode}、支給日:${targetDate})。`
                )
              );
            }
          }
          count += bonuses.length;
        } catch (error) {
          console.log({ error });
          setMessages((prev) => prev.concat(errorMessage(error)));
        }
      }
      if (count === 0) {
        setMessages((prev) => prev.concat('データが存在しません。'));
      }
      setLoading(false);
    }
  };

  const changeTargetMonth = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const target_month = e.target.value;
      setTargetMonth(target_month);
      if (!endMonth || target_month > endMonth) {
        setEndMonth(target_month);
      }
    }
  };

  const changeEndMonth = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const target_month = e.target.value;
      setEndMonth(target_month);
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Container maxWidth="md">
        {messages.length > 0 && (
          <Alert message={messages} onClose={() => setMessages([])} />
        )}
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <div style={{ marginTop: 38, fontSize: '0.9rem' }}>
              {/* {user.code} {user.lastName} {user.firstName} */}
            </div>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              control={
                <Checkbox
                  name="manager"
                  checked={enableRange}
                  onChange={(e) => {
                    setEnableRange(e.target.checked);
                  }}
                />
              }
              label="範囲指定"
              style={{ marginTop: 27 }}
            />
            <FormControl margin="normal" className={classes.month}>
              <InputLabel id="month-label">対象月</InputLabel>
              <Select
                value={targetMonth}
                onChange={changeTargetMonth}
                labelId="month-label"
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                {months.map(([year, month]) => (
                  <MenuItem
                    key={`${year}${zeroPadding(month, 2)}`}
                    value={`${year}${zeroPadding(month, 2)}`}
                  >{`${year}年${zeroPadding(month, 2)}月`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl margin="normal" className={classes.month}>
              <Select
                value={endMonth}
                disabled={!enableRange}
                onChange={changeEndMonth}
                style={{ marginTop: 16, marginLeft: 10 }}
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                {months.map(([year, month]) => (
                  <MenuItem
                    key={`${year}${zeroPadding(month, 2)}`}
                    value={`${year}${zeroPadding(month, 2)}`}
                  >{`${year}年${zeroPadding(month, 2)}月`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() =>
                queryBonuses(
                  userCode,
                  targetMonth,
                  end_month,
                  admin,
                  companyCodes || []
                )
              }
              style={{ marginTop: 30, marginLeft: 10 }}
            >
              検索
            </Button>
            {bonusTables.length > 0 && (
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                onClick={handlePrint}
                style={{ marginTop: 30, marginLeft: 10 }}
              >
                印刷
              </Button>
            )}
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={onClose}
              style={{ marginTop: 30, marginLeft: 10 }}
            >
              {backLabel}
            </Button>
          </Grid>
        </Grid>
        <div className="bonus_print" ref={componentRef}>
          {bonusTables.map((bonusTable, index) => (
            <BonusViewer bonusTable={bonusTable} key={index} />
          ))}
        </div>
      </Container>
    </>
  );
};

export default BonusPage;
