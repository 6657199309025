import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  LinearProgress,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { toHalfWidthChar } from './tools';
import { IncomeLayout } from './types';
import Alert, { AlertMessage } from './Alert';
import AppContext from './AppContext';

import EditLayout from './EditLayout';

import firebase from './firebase';
import 'firebase/auth';
import 'firebase/firestore';
import './App.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 30,
    backgroundColor: 'white',
  },
  table: {
    marginTop: 20,
  },
  appliedAt: {
    width: '15%',
  },
  companyCode: {
    width: '15%',
  },
  companyName: {
    width: '40%',
  },
  action: {
    width: '30%',
  },
  button: {
    margin: '0 3px',
    padding: 2,
    minWidth: 40,
    height: 24,
  },
  button2: {
    marginLeft: 15,
    marginTop: 12,
  },
  link: {
    textDecoration: 'none',
  },
  select: {
    width: 150,
  },
  selectCompany: {
    width: 300,
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

const BonusLayoutList: React.FC = (props) => {
  const [layouts, setLayouts] = useState<{ [id: string]: IncomeLayout }>({});
  const [companyCode, setCompanyCode] = useState<string>('');
  const [targetLayout, setTargetLayout] = useState<{
    id: string | null;
    layout: IncomeLayout;
  } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const { companies, customClaims } = useContext(AppContext);
  const admin =
    customClaims?.role === 'admin' || customClaims?.allowAllCompanies;
  const companyCodes =
    (admin ? Object.keys(companies) : customClaims?.companies) || [];
  const classes = useStyles();

  const queryLayouts = async (company_code: string) => {
    setLoading(true);
    const db = firebase.firestore();
    const collection = db.collection('bonusLayouts');
    let query: firebase.firestore.Query;

    if (company_code) {
      query = collection.where('companyCode', '==', company_code);
    } else {
      query = collection.orderBy('appliedAt', 'desc').orderBy('companyCode');
    }

    const querySnapshot = await query.get();

    if (querySnapshot.size > 0) {
      const new_layouts: { [id: string]: IncomeLayout } = {};
      querySnapshot.forEach(
        (doc) => (new_layouts[doc.id] = doc.data() as IncomeLayout)
      );
      setLayouts(new_layouts);
    } else {
      setLayouts({});
      setMessages({ content: 'データが存在しません。', severity: 'error' });
    }
    setLoading(false);
  };

  const changeCompanyCode = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const code = e.target.value;
      setCompanyCode(code);
      queryLayouts(code);
    } else {
      setCompanyCode('');
    }
  };

  if (targetLayout) {
    return (
      <EditLayout
        docId={targetLayout.id}
        dataType="bonus"
        layout={targetLayout.layout}
        onClose={() => setTargetLayout(null)}
      />
    );
  } else {
    return (
      <>
        {loading && <LinearProgress />}
        <Box m={5}>
          <Container maxWidth="md">
            <Typography
              component="h2"
              variant="inherit"
              align="center"
              className={classes.title}
            >
              賞与明細レイアウト
            </Typography>
            <Paper className={classes.paper}>
              <Grid container spacing={4}>
                <Grid item>
                  <FormControl margin="none" size="small">
                    <InputLabel id="company-label">会社</InputLabel>
                    <Select
                      name={'companyCode'}
                      value={companyCode}
                      labelId="company-label"
                      onChange={changeCompanyCode}
                      className={classes.selectCompany}
                    >
                      <MenuItem value="">
                        <em></em>
                      </MenuItem>
                      {companyCodes.map((code, index) => (
                        <MenuItem key={index} value={code}>
                          {toHalfWidthChar(companies[code]?.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    className={classes.button2}
                    onClick={() => queryLayouts(companyCode)}
                  >
                    検索
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="inherit"
                    className={classes.button2}
                    onClick={() =>
                      setTargetLayout({
                        id: null,
                        layout: {
                          companyCode: companyCodes[0],
                          appliedAt: firebase.firestore.Timestamp.fromDate(
                            new Date(new Date().setHours(0, 0, 0, 0))
                          ),
                          createdAt: firebase.firestore.Timestamp.fromDate(
                            new Date()
                          ),
                          rosterItems: [],
                          supplyItems: [],
                          deductItems: [],
                          totalItems: [],
                        },
                      })
                    }
                  >
                    新規
                  </Button>
                </Grid>
              </Grid>
              {Object.keys(layouts).length > 0 && (
                <Typography component="p" variant="caption" align="left">
                  {Object.keys(layouts).length}件のデータが見つかりました
                </Typography>
              )}
              <Table size="small" className={classes.table}>
                <colgroup>
                  <col className={classes.appliedAt} />
                  <col className={classes.companyCode} />
                  <col className={classes.companyName} />
                  <col className={classes.action} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>適用日</TableCell>
                    <TableCell>会社コード</TableCell>
                    <TableCell>会社名</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(layouts).map(([id, layout], index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {layout.appliedAt?.toDate()?.toLocaleDateString()}
                      </TableCell>
                      <TableCell>{layout.companyCode}</TableCell>
                      <TableCell>
                        {toHalfWidthChar(companies[layout.companyCode]?.name)}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="inherit"
                          size="small"
                          className={classes.button}
                          onClick={() => setTargetLayout({ id, layout })}
                        >
                          編集
                        </Button>
                        <Button
                          variant="outlined"
                          color="inherit"
                          size="small"
                          className={classes.button}
                          onClick={() =>
                            setTargetLayout({
                              id: null,
                              layout: {
                                ...layout,
                                appliedAt: firebase.firestore.Timestamp.fromDate(
                                  new Date(new Date().setHours(0, 0, 0, 0))
                                ),
                              },
                            })
                          }
                        >
                          ｺﾋﾟｰして編集
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <Box mt={2}>
              <Link to="/" className={classes.link}>
                <Button variant="outlined" color="inherit" size="medium">
                  トップ画面に戻る
                </Button>
              </Link>
            </Box>
            {messages.content.length > 0 && (
              <Alert
                message={messages.content}
                severity={messages.severity}
                onClose={() => setMessages({ content: [] })}
              />
            )}
          </Container>
        </Box>
      </>
    );
  }
};

export default BonusLayoutList;
