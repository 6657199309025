import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  makeStyles,
  Typography,
} from '@material-ui/core';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/storage';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  dialog: {
    // height: '100%',
    width: '100%',
  },
  img: {
    width: '100%',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 10,
  },
}));

interface HelpInfo {
  title: string;
  url: string;
  fileType: 'img' | 'video' | null;
  comment: string;
}

interface HelpPageProps {
  open: boolean;
  onClose: () => void;
  pathname: string;
}

const HelpPage: React.FC<HelpPageProps> = ({ open, onClose, pathname }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [helpInfos, setHelpInfos] = useState<HelpInfo[]>([]);
  const classes = useStyles();

  useEffect(() => {
    const f = async () => {
      if (pathname) {
        setLoading(true);
        const helps: HelpInfo[] = [];
        const db = firebase.firestore();
        const storage = firebase.storage();
        const querySnap = await db
          .collection('helps')
          .where('pathname', '==', pathname)
          .orderBy('rank')
          .get();
        for await (let doc of querySnap.docs) {
          const data = doc.data();
          const httpsReference = storage.refFromURL(data.url);
          const url = await httpsReference.getDownloadURL();
          helps.push({
            url,
            title: data.title,
            comment: data.comment,
            fileType: data.fileType,
          });
        }
        setHelpInfos(helps);
        setLoading(false);
      } else {
        setHelpInfos([]);
      }
    };
    f();
  }, [pathname]);

  if (helpInfos.length === 0 && loading) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.dialog }}
      maxWidth="md"
    >
      <DialogContent>
        {helpInfos.map((helpInfo, index) => (
          <div key={index}>
            {helpInfo.title && (
              <Typography
                component="h3"
                variant="inherit"
                align="center"
                className={classes.title}
              >
                {helpInfo.title}
              </Typography>
            )}

            {helpInfo.fileType === 'img' && (
              <img src={helpInfo.url} className={classes.img} alt="help" />
            )}
            {helpInfo.fileType === 'video' && (
              <video src={helpInfo.url} autoPlay controls width="100%"></video>
            )}
            {helpInfo.comment && (
              <Typography variant="caption">{helpInfo.comment}</Typography>
            )}
          </div>
        ))}
        {helpInfos.length === 0 && (
          <Typography>ヘルプが存在しません</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpPage;
