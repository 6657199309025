import firebase from './firebase';
import { Income, IncomeHeader, IncomeItemType } from './types';

export type UserInfoType = { [key: string]: { label: string; value: string } };

export interface IncomeTable {
  month: string;
  userInfos: UserInfoType;
  rosterItems: IncomeItemType[];
  supplyItems: IncomeItemType[];
  deductItems: IncomeItemType[];
  totalItems: IncomeItemType[];
}

export const getByLabelFromIncomeHeader = (
  label: string,
  income: Income,
  incomeHeader: IncomeHeader,
  defaultValue = ''
) => {
  const index = incomeHeader.contents.findIndex((name) => label === name);
  return index >= 0 ? income.contents[index] : defaultValue;
};

export const getIncomeLayout = async (
  company_code: string,
  target_date: Date
) => {
  const incomeLayouts = await firebase
    .firestore()
    .collection('incomeLayouts')
    .where('companyCode', '==', company_code)
    .where('appliedAt', '<=', target_date)
    .orderBy('appliedAt', 'desc')
    .limit(1)
    .get();

  if (incomeLayouts.docs.length === 0) return null;

  const incomeLayout = incomeLayouts.docs[0].data();
  return incomeLayout;
};

export const createIncomeItems = (
  income: Income,
  incomeHeader: IncomeHeader,
  incomeLayout: firebase.firestore.DocumentData
) => {
  if (income && incomeHeader) {
    const userInfos: UserInfoType = {
      usercode: { label: '社員番号', value: '' },
      username: { label: '氏名', value: '' },
      companyName: { label: '会社名', value: '' },
    };

    const rosterItems = incomeLayout.rosterItems;
    const supplyItems = incomeLayout.supplyItems;
    const deductItems = incomeLayout.deductItems;
    const totalItems = incomeLayout.totalItems;

    [rosterItems, supplyItems, deductItems, totalItems].forEach((items) => {
      items.forEach((item: IncomeItemType) => {
        item.value = getByLabelFromIncomeHeader(
          item.label,
          income,
          incomeHeader
        );
      });
    });

    Object.keys(userInfos).forEach((key) => {
      userInfos[key].value = getByLabelFromIncomeHeader(
        userInfos[key].label,
        income,
        incomeHeader,
        'x'
      );
    });
    userInfos.companyName.value = income?.companyName;
    return {
      month: income.month,
      userInfos,
      rosterItems,
      supplyItems,
      deductItems,
      totalItems,
    } as IncomeTable;
  } else {
    return null;
  }
};
