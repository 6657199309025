import React, { useState, useEffect, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

import { Gensen, GensenHeader } from './types';
import { errorMessage } from './tools';
import Alert from './Alert';
import GensenViewer from './GensenViewer';
import AppContext from './AppContext';

import firebase from './firebase';
import 'firebase/auth';
import 'firebase/functions';
import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  spacer: {
    height: 20,
  },
  year: {
    width: 150,
  },
}));

interface GensenPageProps {
  userCode: string;
  year: number | null;
  backLabel: string;
  onClose: () => void;
}

const GensenPage: React.FC<GensenPageProps> = ({
  userCode,
  year,
  backLabel,
  onClose,
}) => {
  const [targetYear, setTargetYear] = useState<number | null>(null);
  const [years, setYears] = useState<number[]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [gensens, setGensens] = useState<Gensen[]>([]);
  const [gensenHeader, setGensenHeader] = useState<GensenHeader | null>(null);
  const { customClaims } = useContext(AppContext);
  const admin =
    customClaims?.role === 'admin' || !!customClaims?.allowAllCompanies;
  const companyCodes = customClaims?.companies;
  const classes = useStyles();

  useEffect(() => {
    const f = async () => {
      const db = firebase.firestore();
      const snapshot = await db.collection('gensenHeaders').get();
      const arrYears: number[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data() as GensenHeader;
        arrYears.push(data.year);
      });
      setYears(arrYears);
    };
    f();
  }, []);

  useEffect(() => {
    setTargetYear(year);
    queryGensens(userCode, year, admin, companyCodes || []);
  }, [userCode, year, admin, companyCodes]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const queryGensens = async (
    user_code: string,
    year: number | null,
    admin_mode: boolean,
    company_codes: string[]
  ) => {
    setMessages([]);
    if (user_code && year) {
      setLoading(true);
      const db = firebase.firestore();
      const userCodes = [user_code];
      const chilren = await db
        .collection('users')
        .where('parentCode', '==', user_code)
        .get();
      chilren.forEach((child) => {
        userCodes.push(child.ref.id);
      });

      const new_gensens: Gensen[] = [];
      for await (const user_code of userCodes) {
        try {
          const collection = db
            .collection('users')
            .doc(user_code)
            .collection('gensens');
          let query_gensens = collection.where('year', '==', year);
          if (!admin_mode) {
            query_gensens = query_gensens.where(
              'companyCode',
              'in',
              company_codes
            );
          }
          const snapGensen = await query_gensens.get();
          snapGensen.forEach((doc) => new_gensens.push(doc.data() as Gensen));
        } catch (error) {
          setMessages((prev) => prev.concat(errorMessage(error)));
        }
      }
      setGensens(new_gensens);

      const snapHeader = await db
        .collection('gensenHeaders')
        .doc(String(year))
        .get();
      const gensen_header = snapHeader.data() as GensenHeader;
      setGensenHeader(gensen_header);

      console.log({ gensens: new_gensens, gensenHeader: gensen_header });

      if (new_gensens.length === 0) {
        setMessages((prev) => prev.concat('データが存在しません。'));
      }
      setLoading(false);
    }
  };

  const changeTargetYear = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'number') {
      const year = Number(e.target.value);
      setTargetYear(year);
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {messages.length > 0 && (
        <Alert message={messages} onClose={() => setMessages([])} />
      )}
      <Container maxWidth="md">
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <div style={{ marginTop: 38, fontSize: '0.9rem' }}>
              {/* {user.code} {user.lastName} {user.firstName} */}
            </div>
          </Grid>
          <Grid item xs={9}>
            <FormControl margin="normal" className={classes.year}>
              <InputLabel id="year-label">対象年度</InputLabel>
              <Select
                value={targetYear ? String(targetYear) : ''}
                onChange={changeTargetYear}
                labelId="year-label"
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>{`${year}年`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() =>
                queryGensens(userCode, targetYear, admin, companyCodes || [])
              }
              style={{ marginTop: 30, marginLeft: 10 }}
            >
              検索
            </Button>
            {gensens.length > 0 && (
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                onClick={handlePrint}
                style={{ marginTop: 30, marginLeft: 10 }}
              >
                印刷
              </Button>
            )}
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={onClose}
              style={{ marginTop: 30, marginLeft: 10 }}
            >
              {backLabel}
            </Button>
          </Grid>
        </Grid>
        <div className="gensen_print" ref={componentRef}>
          {year &&
            gensenHeader &&
            gensens.map((gensen, index) => (
              <GensenViewer
                key={index}
                year={year}
                gensen={gensen}
                gensenHeader={gensenHeader}
              />
            ))}
        </div>
      </Container>
    </>
  );
};

export default GensenPage;
