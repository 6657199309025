import React, { useState, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  LinearProgress,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { errorMessage } from './tools';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';

import Alert, { AlertMessage } from './Alert';
import AppContext from './AppContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 30,
    backgroundColor: 'white',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

const ChangePassword: React.FC = () => {
  const [account, setAccount] = useState({
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const [errors, setErrors] = useState({
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
  });
  const { currentUser, user } = useContext(AppContext);
  // const history = useHistory();
  const classes = useStyles();

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errs = {
      oldPassword: '',
      password: '',
      passwordConfirmation: '',
    };

    if (!account.oldPassword) {
      errs.oldPassword = '旧パスワードを入力してください。';
    }
    if (!account.password) {
      errs.password = 'パスワードを入力してください。';
    }
    if (account.password !== account.passwordConfirmation) {
      errs.passwordConfirmation = 'パスワード(確認用)が異なります。';
    }
    setErrors(errs);
    const existErrors = Object.values(errs).filter((err) => !!err).length > 0;

    if (currentUser && currentUser.email && !existErrors) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email,
        account.oldPassword
      );
      currentUser
        .reauthenticateWithCredential(credential)
        .then(() => {
          setLoading(true);
          const func = firebase
            .app()
            .functions('asia-northeast1')
            .httpsCallable('changePassword');
          func({ ...account, email: user?.email })
            .then((result) => {
              setLoading(false);
              console.log({ result });
              if (result.data.result) {
                setMessages({
                  content: 'パスワードを変更しました。',
                  severity: 'success',
                });
                // history.push('/');
              } else {
                setMessages({
                  content: 'パスワード変更に失敗しました。',
                  severity: 'error',
                });
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log({ error });
              setMessages({
                content: errorMessage(error),
                severity: 'error',
              });
            });
        })
        .catch(function (error) {
          console.log({ error });
          setMessages({
            content: errorMessage(error),
            severity: 'error',
          });
        });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Container maxWidth="xs">
        <Box mt={5}>
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            パスワード変更
          </Typography>
          <form onSubmit={submit}>
            <Paper className={classes.paper}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  label="旧パスワード"
                  type="password"
                  value={account.oldPassword}
                  autoComplete="new-password"
                  disabled={loading}
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword}
                  onChange={(e) =>
                    setAccount({ ...account, oldPassword: e.target.value })
                  }
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  label="新パスワード"
                  type="password"
                  value={account.password}
                  autoComplete="new-password"
                  disabled={loading}
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={(e) =>
                    setAccount({ ...account, password: e.target.value })
                  }
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  label="パスワード(確認用)"
                  type="password"
                  value={account.passwordConfirmation}
                  autoComplete="new-password"
                  disabled={loading}
                  error={!!errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation}
                  onChange={(e) =>
                    setAccount({
                      ...account,
                      passwordConfirmation: e.target.value,
                    })
                  }
                />
              </FormControl>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  fullWidth
                >
                  パスワードを変更
                </Button>
              </Grid>
              <Typography variant="caption">
                ＊パスワードは8文字以上必要
              </Typography>
            </Paper>
          </form>
        </Box>
        <Box mt={2}>
          <Link to="/" className={classes.link}>
            <Button variant="outlined" color="inherit" size="medium">
              トップ画面に戻る
            </Button>
          </Link>
        </Box>
        {messages.content.length > 0 && (
          <Alert
            message={messages.content}
            severity={messages.severity}
            onClose={() => setMessages({ content: [] })}
          />
        )}
      </Container>
    </>
  );
};

export default ChangePassword;
