import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  FormControl,
  LinearProgress,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';

import { getOrigin, errorMessage } from './tools';
import Alert, { AlertMessage } from './Alert';

interface ResetPasswordProps {
  token: string;
  disabled: boolean;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 35,
    backgroundColor: 'white',
  },
  title: {
    color: 'gray',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

const ResetPassword: React.FC<ResetPasswordProps> = ({ token, disabled }) => {
  const [account, setAccount] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const [errors, setErrors] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    setLoading(disabled);
  }, [disabled]);

  const handleClick = async () => {
    const errs = {
      password: '',
      passwordConfirmation: '',
    };
    if (!account.password) {
      errs.password = 'パスワードを入力してください。';
    }
    if (!account.passwordConfirmation) {
      errs.passwordConfirmation = 'パスワード(確認用)を入力してください。';
    } else if (account.password !== account.passwordConfirmation) {
      errs.passwordConfirmation = 'パスワード(確認用)が異なります。';
    }
    setErrors(errs);
    const existErrors = Object.values(errs).filter((err) => !!err).length > 0;

    if (!existErrors) {
      setLoading(true);
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('resetPassword');
      func({ ...account, token })
        .then((result) => {
          setLoading(false);
          console.log({ result });
          if (result.data.result) {
            setMessages({
              content: 'パスワードを変更しました。',
              severity: 'success',
            });
            window.location.href = getOrigin();
          } else {
            setMessages({
              content: 'パスワード変更に失敗しました。',
              severity: 'error',
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log({ error });
          setMessages({
            content: errorMessage(error),
            severity: 'error',
          });
        });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Container maxWidth="xs">
        <Box mt={5}>
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            パスワード再設定
          </Typography>
          <form>
            <Paper className={classes.paper}>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  label="新パスワード"
                  type="password"
                  value={account.password}
                  autoComplete="new-password"
                  disabled={loading}
                  error={!!errors.password}
                  helperText={errors.password}
                  onChange={(e) =>
                    setAccount({ ...account, password: e.target.value })
                  }
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <TextField
                  label="パスワード(確認用)"
                  type="password"
                  value={account.passwordConfirmation}
                  autoComplete="new-password"
                  disabled={loading}
                  error={!!errors.passwordConfirmation}
                  helperText={errors.passwordConfirmation}
                  onChange={(e) =>
                    setAccount({
                      ...account,
                      passwordConfirmation: e.target.value,
                    })
                  }
                />
              </FormControl>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  size="large"
                  disabled={loading}
                  fullWidth
                >
                  パスワードを変更
                </Button>
              </Grid>
              <Typography variant="caption">
                ＊パスワードは8文字以上必要
              </Typography>
              {messages.content.length > 0 && (
                <Alert
                  message={messages.content}
                  severity={messages.severity}
                  onClose={() => setMessages({ content: [] })}
                />
              )}
            </Paper>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default ResetPassword;
