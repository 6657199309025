import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { toHalfWidthChar } from './tools';
import { BonusTable } from './BonusLayout';
import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  table: {
    tableLayout: 'fixed',
  },
  cell: {
    border: '1px solid gray',
    width: '10%',
    height: 50,
    padding: 0,
  },
  company: {
    width: '24%',
  },
  usercode: {
    width: '8%',
  },
  username: {
    width: '24%',
  },
  hh: {
    width: '26%',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 22,
    },
  },
  month: {
    width: '18%',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2vw',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 13,
    },
  },
  title: {
    border: '1px solid gray',
    width: 30,
    color: 'white',
    backgroundColor: 'royalblue',
    padding: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2vw',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 13,
    },
  },
  titleSmall: {
    border: '1px solid gray',
    width: 30,
    color: 'white',
    backgroundColor: 'royalblue',
    padding: 0,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2vw',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 10,
    },
  },
  label: {
    padding: 0,
    height: 20,
    borderBottom: '1px solid gray',
    backgroundColor: 'aliceblue',
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingTop: 4,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2vw',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 12,
    },
  },
  value: {
    height: 30,
    textAlign: 'right',
    paddingTop: 10,
    paddingLeft: 2,
    paddingRight: 2,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2vw',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 13,
    },
  },
  numValue: {
    height: 30,
    textAlign: 'right',
    paddingTop: 10,
    paddingRight: 4,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5vw',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 13,
    },
  },
  companyValue: {
    height: 30,
    paddingTop: 11,
    paddingLeft: 2,
    paddingRight: 2,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2vw',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 12,
    },
  },
  spacer: {
    height: 20,
  },
  alert: {
    margin: 20,
    backgroundColor: 'orangered',
  },
  view: {
    paddingTop: 20,
    paddingBottom: 20,
    pageBreakAfter: 'always',
    '&:not(:last-child)': {
      borderBottom: '1px dashed gray',
    },
  },
}));

interface BonusHeaderViewProps {
  companyName: string;
  usercode: string;
  username: string;
  month: string;
}

const BonusHeaderView: React.FC<BonusHeaderViewProps> = ({
  companyName,
  usercode,
  username,
  month,
}) => {
  const classes = useStyles();
  const m = month.match(/(\d{4})(\d{2})/);
  const [year, month2] = m ? [m[1], m[2]] : ['', ''];

  return (
    <Table className={classes.table}>
      <colgroup>
        <col className={classes.title} />
        <col className={classes.company} />
        <col className={classes.usercode} />
        <col className={classes.username} />
        <col className={classes.hh} />
        <col className={classes.month} />
      </colgroup>
      <TableBody>
        <TableRow>
          <TableCell className={classes.titleSmall}>
            所<br />属<br />氏<br />名
          </TableCell>
          <TableCell className={clsx(classes.cell, classes.company)}>
            <div className={classes.label}>会社名</div>
            <div
              className={classes.companyValue}
              style={{ textAlign: 'center' }}
            >
              {toHalfWidthChar(companyName)}
            </div>
          </TableCell>
          <TableCell className={clsx(classes.cell, classes.usercode)}>
            <div className={classes.label}>社員番号</div>
            <div className={classes.value} style={{ textAlign: 'center' }}>
              {usercode}
            </div>
          </TableCell>
          <TableCell className={clsx(classes.cell, classes.username)}>
            <div className={classes.label}>氏名</div>
            <div className={classes.value} style={{ textAlign: 'center' }}>
              {username} 様
            </div>
          </TableCell>
          <TableCell className={clsx(classes.cell, classes.hh)}>
            <div style={{ height: 30, textAlign: 'center' }}>
              支 給 明 細 書
            </div>
          </TableCell>
          <TableCell className={clsx(classes.cell, classes.month)}>
            <div style={{ textAlign: 'center' }}>
              {year}年{month2}月 賞与
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

interface BonusTableViewerProps {
  title: string;
  maxRow: number;
  maxCol: number;
  items: {
    label: string;
    value: string;
    row: number;
    col: number;
    zeroSuppress: boolean;
  }[];
}

const BonusTableViewer: React.FC<BonusTableViewerProps> = ({
  title,
  maxRow,
  maxCol,
  items,
}) => {
  const classes = useStyles();

  if (!title && items.length === 0)
    return <div className={classes.spacer}></div>;

  let components = [];
  for (let row = 1; row <= maxRow; row++) {
    let elems = [];
    if (row === 1) {
      const elem = (
        <TableCell rowSpan={maxRow} className={classes.title} key={`${row}-0`}>
          {title.split('').map((char, index) => (
            <React.Fragment key={index}>
              {char}
              <br />
            </React.Fragment>
          ))}
        </TableCell>
      );
      elems.push(elem);
    }
    for (let col = 1; col <= maxCol; col++) {
      const cell = items.find((item) => item.row === row && item.col === col);
      const suppress = cell && cell.zeroSuppress && Number(cell?.value) === 0;
      elems.push(
        <TableCell className={classes.cell} key={`${row}-${col}`}>
          <div className={classes?.label}>{suppress ? '' : cell?.label}</div>
          <div className={classes?.numValue}>{suppress ? '' : cell?.value}</div>
        </TableCell>
      );
    }
    components.push(<TableRow key={row}>{elems}</TableRow>);
  }

  return (
    <Table className={classes.table}>
      <colgroup>
        <col className={classes.title} />
        {Array.from(new Array(10)).map((value, index) => (
          <col className={classes.cell} key={index} />
        ))}
      </colgroup>
      <TableBody>{components}</TableBody>
    </Table>
  );
};

interface BonusViewerProps {
  bonusTable: BonusTable;
}

const BonusViewer: React.FC<BonusViewerProps> = ({ bonusTable }) => {
  const {
    month,
    userInfos,
    rosterItems,
    supplyItems,
    deductItems,
    totalItems,
  } = bonusTable;
  const classes = useStyles();

  const validBonuses = () => {
    return (
      supplyItems.length > 0 && deductItems.length > 0 && totalItems.length > 0
    );
  };

  return (
    <div className={classes.view}>
      {validBonuses() && (
        <>
          <BonusHeaderView
            usercode={userInfos.usercode?.value}
            username={userInfos.username?.value}
            companyName={userInfos.companyName?.value}
            month={month}
          />
          <BonusTableViewer title="" maxRow={1} maxCol={10} items={[]} />
          <BonusTableViewer
            title="勤怠他"
            maxRow={2}
            maxCol={10}
            items={rosterItems}
          />
          <BonusTableViewer
            title="支給"
            maxRow={2}
            maxCol={10}
            items={supplyItems}
          />
          <BonusTableViewer
            title="控除"
            maxRow={2}
            maxCol={10}
            items={deductItems}
          />
          <BonusTableViewer
            title=""
            maxRow={1}
            maxCol={10}
            items={totalItems}
          />
          <BonusTableViewer title="" maxRow={1} maxCol={10} items={[]} />
          <BonusTableViewer title="内訳" maxRow={2} maxCol={10} items={[]} />
        </>
      )}
    </div>
  );
};

export default BonusViewer;
