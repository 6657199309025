import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import SignIn from './SignIn';
import { AppContextProvider } from './AppContext';
import * as serviceWorker from './serviceWorker';
import AuthRouter from './AuthRouter';

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <SignIn>
        <AuthRouter />
      </SignIn>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
