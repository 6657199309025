import firebase from './firebase';
import { Bonus, BonusHeader } from './types';

export type UserInfoType = { [key: string]: { label: string; value: string } };
export interface BonusItemType {
  label: string;
  value: string;
  row: number;
  col: number;
  zeroSuppress: boolean;
}
export interface BonusTable {
  month: string;
  userInfos: UserInfoType;
  rosterItems: BonusItemType[];
  supplyItems: BonusItemType[];
  deductItems: BonusItemType[];
  totalItems: BonusItemType[];
}

export const getByLabelFromBonusHeader = (
  label: string,
  bonus: Bonus,
  bonusHeader: BonusHeader,
  defaultValue = ''
) => {
  const index = bonusHeader.contents.findIndex((name) => label === name);
  return index >= 0 ? bonus.contents[index] : defaultValue;
};

export const getBonusLayout = async (
  company_code: string,
  target_date: Date
) => {
  const bonusLayouts = await firebase
    .firestore()
    .collection('bonusLayouts')
    .where('companyCode', '==', company_code)
    .where('appliedAt', '<=', target_date)
    .orderBy('appliedAt', 'desc')
    .limit(1)
    .get();

  if (bonusLayouts.docs.length === 0) return null;

  const bonusLayout = bonusLayouts.docs[0].data();
  return bonusLayout;
};

export const createBonusItems = (
  bonus: Bonus,
  bonusHeader: BonusHeader,
  bonusLayout: firebase.firestore.DocumentData
) => {
  if (bonus && bonusHeader) {
    const userInfos: UserInfoType = {
      usercode: { label: '社員番号', value: '' },
      username: { label: '氏名', value: '' },
      companyName: { label: '会社名', value: '' },
    };

    const rosterItems = bonusLayout.rosterItems;
    const supplyItems = bonusLayout.supplyItems;
    const deductItems = bonusLayout.deductItems;
    const totalItems = bonusLayout.totalItems;

    [rosterItems, supplyItems, deductItems, totalItems].forEach((items) => {
      items.forEach((item: BonusItemType) => {
        item.value = getByLabelFromBonusHeader(item.label, bonus, bonusHeader);
      });
    });

    Object.keys(userInfos).forEach((key) => {
      userInfos[key].value = getByLabelFromBonusHeader(
        userInfos[key].label,
        bonus,
        bonusHeader,
        'x'
      );
    });

    userInfos.companyName.value = bonus?.companyName;
    return {
      month: bonus.month,
      userInfos,
      rosterItems,
      supplyItems,
      deductItems,
      totalItems,
    } as BonusTable;
  } else {
    return null;
  }
};
