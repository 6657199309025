import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { dateStr, validEmail } from './tools';
import { User } from './types';
import firebase from './firebase';
import 'firebase/auth';
import 'firebase/firestore';
import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  paper: {
    padding: 10,
    backgroundColor: 'white',
  },
  table: {
    tableLayout: 'fixed',
  },
  cell: {
    border: '1px solid gray',
    width: '10%',
    height: 50,
    padding: 0,
  },
  usercode: {},
  username: {},
  userkana: {},
  email: {},
  entry: {},
  retire: {},
  time: {},
  action: {},
  month: {
    width: '30%',
    border: 'none',
  },
  title: {
    border: '1px solid gray',
    width: 30,
    color: 'white',
    backgroundColor: 'royalblue',
    padding: 0,
    textAlign: 'center',
  },
  label: {
    padding: 0,
    height: 20,
    borderBottom: '1px solid gray',
    backgroundColor: 'aliceblue',
    textAlign: 'center',
    overflow: 'hidden',
  },
  value: {
    height: 30,
    textAlign: 'right',
    paddingTop: 10,
    boxSizing: 'border-box',
  },
  spacer: {
    height: 20,
  },
  payday: {
    width: 200,
  },
  button: {
    margin: '0 3px',
    padding: 2,
    minWidth: 40,
    height: 24,
  },
  button2: {
    marginLeft: 5,
    marginTop: 12,
  },
  link: {
    textDecoration: 'none',
  },
  pageTitle: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

const GeneratePassword: React.FC = (props) => {
  const now = new Date();
  const [retiredAt, setRetiredAt] = useState<string>(dateStr(now));
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const classes = useStyles();

  const querySinedUsers = async () => {
    setLoading(true);
    const date0 = new Date('2020-09-18');
    const users: User[] = [];
    const db = firebase.firestore();
    const query_users = await db
      .collection('users')
      .where('firstSignInAt', '>', firebase.firestore.Timestamp.fromDate(date0))
      .get();
    query_users.forEach((doc) => {
      if (doc) users.push(doc.data() as User);
    });
    setUsers(users);
    setLoading(false);
  };

  const queryInvalidUsers = async () => {
    let errorUsers: User[] = [];
    const db = firebase.firestore();
    const queryUsers1 = await db
      .collection('users')
      .where('retiredAt', '==', null)
      .get();
    const { users: errorUsers1 } = checkValidUsers(queryUsers1, false);

    if (retiredAt) {
      const date = new Date(retiredAt);
      const queryUsers2 = await db
        .collection('users')
        .where('retiredAt', '>=', date)
        .orderBy('retiredAt')
        .get();
      const { users: errorUsers2 } = checkValidUsers(queryUsers2, false);
      errorUsers = errorUsers1.concat(errorUsers2);
    } else {
      errorUsers = errorUsers1;
    }
    if (errorUsers.length === 0) alert('無効なメールアドレスは存在しません。');
    setUsers(errorUsers);
  };

  const queryTargetUsers = async () => {
    setLoading(true);
    const db = firebase.firestore();
    const queryUsers1 = await db
      .collection('users')
      .where('retiredAt', '==', null)
      .get();
    const { users: users1 } = checkValidUsers(queryUsers1, true, true);

    if (retiredAt) {
      const date = new Date(retiredAt);
      const queryUsers2 = await db
        .collection('users')
        .where('retiredAt', '>=', date)
        .orderBy('retiredAt')
        .get();
      const { users: users2 } = checkValidUsers(queryUsers2, true, true);

      setUsers(users1.concat(users2));
    } else {
      setUsers(users1);
    }
    setLoading(false);
  };

  const GenerateAllPasswords = async () => {
    if (!window.confirm('パスワードを一括送信しますか？')) return;
    let targetUids: string[] = [];
    const targetUsers: { [uid: string]: User } = {};

    const db = firebase.firestore();
    const queryUsers1 = await db
      .collection('users')
      .where('retiredAt', '==', null)
      .get();
    const { uids: uids1, users: users1 } = checkValidUsers(
      queryUsers1,
      true,
      true
    );
    for (let i in uids1) targetUsers[uids1[i]] = users1[i];

    if (retiredAt) {
      const date = new Date(retiredAt);
      const queryUsers2 = await db
        .collection('users')
        .where('retiredAt', '>=', date)
        .orderBy('retiredAt')
        .get();
      const { uids: uids2, users: users2 } = checkValidUsers(
        queryUsers2,
        true,
        true
      );
      for (let i in uids2) targetUsers[uids2[i]] = users2[i];

      targetUids = uids1.concat(uids2);
    } else {
      targetUids = uids1;
    }

    if (targetUids.length > 0) {
      setLoading(true);
      setMessage(`...パスワード生成中: 0/${targetUids.length}`);
      const taskUnit = 20;
      const splitUids = [];
      for (let i = 0; i < targetUids.length / taskUnit; i++) {
        splitUids.push(targetUids.slice(i * taskUnit, (i + 1) * taskUnit));
      }
      let count = 0;
      let errorUids: string[] = [];
      for await (let uids of splitUids) {
        try {
          setMessage(`...パスワード生成中: ${count}/${targetUids.length}`);
          const func = firebase
            .app()
            .functions('asia-northeast1')
            .httpsCallable('generatePasswords');
          const result = await func({
            userCodes: uids,
            enableSendEmail: true,
            targetSigned: false,
          });
          console.log({ result });
          if (result.data && result.data.length > 0) {
            result.data.forEach((r: any) => {
              if (!r.result) errorUids.push(r.uid);
            });
          }
        } catch (error) {
          console.log({ error });
          errorUids = errorUids.concat(uids);
        }
        count += uids.length;
      }
      if (errorUids.length > 0) {
        let errorUsers: User[] = [];
        errorUids.forEach((uid) => {
          errorUsers.push(targetUsers[uid]);
        });
        setUsers(errorUsers);
        alert('エラーが発生しました。');
      } else {
        alert(`パスワード生成に成功しました(${targetUids.length}件)。`);
      }
      setMessage('');
      setLoading(false);
    } else {
      alert('データが存在しません。');
    }
  };

  const checkValidUsers = (
    query_users: firebase.firestore.QuerySnapshot,
    valid_email = true,
    check_not_signin = false
  ) => {
    const uids: string[] = [];
    const targetUsers: User[] = [];
    query_users.forEach((doc) => {
      const user = doc.data() as User;
      const valid_email2 = validEmail(user?.email || '');
      const conf1 = valid_email ? valid_email2 : !valid_email2;
      const conf2 = !user.parentCode;
      const conf3 = !check_not_signin || !user.firstSignInAt;
      if (conf1 && conf2 && conf3) {
        uids.push(doc.ref.id);
        targetUsers.push(user);
      }
    });
    return { uids, users: targetUsers };
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <Typography
          component="h2"
          variant="inherit"
          align="center"
          className={classes.pageTitle}
        >
          パスワード一括生成
        </Typography>
        <Paper className={classes.paper}>
          {message && <Typography variant="subtitle1">{message}</Typography>}
          <Grid container spacing={5} style={{ marginBottom: 5 }}>
            <Grid item>
              <TextField
                type="date"
                label="退職日"
                value={retiredAt}
                disabled={loading}
                onChange={(e) => setRetiredAt(e.target.value)}
              />
              <Button
                variant="outlined"
                color="inherit"
                className={classes.button2}
                disabled={loading}
                onClick={queryInvalidUsers}
              >
                無効メールチェック
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.button2}
                disabled={loading}
                onClick={querySinedUsers}
              >
                ログイン済社員
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.button2}
                disabled={loading}
                onClick={queryTargetUsers}
              >
                パスワード一括送信対象
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.button2}
                disabled={loading}
                onClick={GenerateAllPasswords}
              >
                パスワード一括送信
              </Button>
            </Grid>
          </Grid>
          {users.length > 0 && (
            <Typography variant="caption">
              {users.length}件のデータが見つかりました。
            </Typography>
          )}
          <Table size="small" className={classes.table}>
            <colgroup>
              <col className={classes.usercode} />
              <col className={classes.username} />
              <col className={classes.userkana} />
              <col className={classes.email} />
              <col className={classes.entry} />
              <col className={classes.retire} />
              <col className={classes.usercode} />
              <col className={classes.time} />
              <col className={classes.action} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <small>社員番号</small>
                </TableCell>
                <TableCell>氏名</TableCell>
                <TableCell>カナ</TableCell>
                <TableCell>email</TableCell>
                <TableCell>入社日</TableCell>
                <TableCell>退社日</TableCell>
                <TableCell>親</TableCell>
                <TableCell>初ﾛｸﾞｲﾝ</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={index}>
                  <TableCell>{user.code}</TableCell>
                  <TableCell>
                    {user.lastName} {user.firstName}
                  </TableCell>
                  <TableCell>
                    {user.lastKana} {user.firstKana}
                  </TableCell>
                  <TableCell>
                    <small>{user.email}</small>
                  </TableCell>
                  <TableCell>
                    {user.enteredAt?.toDate()?.toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {user.retiredAt?.toDate()?.toLocaleDateString()}
                  </TableCell>
                  <TableCell>{user.parentCode}</TableCell>
                  <TableCell>
                    {user.firstSignInAt?.toDate()?.toLocaleDateString()}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <Box mt={2}>
          <Link to="/" className={classes.link}>
            <Button variant="outlined" color="inherit" size="medium">
              トップ画面に戻る
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default GeneratePassword;
