import React, { useState, useContext, useRef } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import * as xlsx from 'xlsx';
import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import {
  fullName,
  errorMessage,
  zeroPadding,
  shapeUserCode,
  toHalfWidthChar,
  userCodeFromEmail,
  nameWithCode,
} from './tools';
import Alert, { AlertMessage } from './Alert';
import OperationList from './OperationList';
import { User } from './types';
import AppContext from './AppContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  paper: {
    padding: 20,
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

interface Result {
  result: boolean;
  userName: string;
  message: string;
}

interface ImportIncomeProps {
  open: boolean;
  onClose: () => void;
}

const ImportIncome: React.FC<ImportIncomeProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyCode, setCompanyCode] = useState<string>('');
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const [confirmOverwrite, setConfirmOverwrite] = useState<boolean>(false);
  const [overwrite, setOverwrite] = useState<boolean>(false);
  const { companies, customClaims, currentUser, user } = useContext(AppContext);
  const ref = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const admin =
    customClaims?.role === 'admin' || customClaims?.allowAllCompanies;
  const companyCodes =
    (admin ? Object.keys(companies) : customClaims?.companies) || [];
  const USER_CODE = '社員番号';
  const USER_NAME = '氏名';
  const PAYDAY = '支給日';

  const currentUserName = () => {
    let name = nameWithCode(user);
    if (!name) name = currentUser?.uid || '';
    return name;
  };

  const getPaydayStr = (date: Date) => {
    const year = date.getFullYear();
    const mon = zeroPadding(date.getMonth() + 1, 2);
    const day = zeroPadding(date.getDate(), 2);
    const payday = `${year}${mon}${day}`;
    const month = `${year}${mon}`;
    return { payday, month };
  };

  const getIncomesFromExcel = async (blob: File) => {
    return new Promise<{ headers: (string | Date)[]; data: any[] }>(
      (resolve, reject) => {
        let headers: (string | Date)[] = [];
        const data: any[] = [];
        const reader = new FileReader();
        reader.onload = async (e: any) => {
          try {
            var fileData = reader.result;
            var wb = xlsx.read(fileData, {
              type: 'binary',
              raw: true,
              cellDates: true,
            });
            const sheet = wb.Sheets[wb.SheetNames[0]];
            const range_a1 = sheet['!ref'];
            if (range_a1) {
              const range = xlsx.utils.decode_range(range_a1);
              for (let r = range.s.r; r <= range.e.r; r++) {
                const rows: (string | Date)[] = [];
                for (let c = range.s.c; c <= range.e.c; c++) {
                  const p = xlsx.utils.encode_cell({ c, r });
                  const cell = sheet[p];
                  const cell_type = cell?.t;
                  if (cell_type === 'd') {
                    rows.push(cell.v as Date);
                  } else if (cell_type === 'n' && cell.v < 0) {
                    rows.push('-' + cell.w.replace(/[()]/g, ''));
                  } else {
                    rows.push(cell?.w || '');
                  }
                }
                if (r === 0) {
                  headers = rows;
                } else {
                  data.push(rows);
                }
              }
              resolve({ headers, data });
            }
          } catch (error) {
            reject(error);
          }
        };
        reader.readAsBinaryString(blob);
      }
    );
  };

  const getFirstRowMonth = (headers: (string | Date)[], data: any[]) => {
    if (data.length === 0) return null;
    const col = headers.findIndex((name) => name === PAYDAY);
    if (col < 0) return null;
    const payday_v = data[0][col];
    const { month } = getPaydayStr(payday_v);
    return month;
  };

  const getUserKana = (user: User) => {
    if (user.firstKana && user.lastKana) {
      return user.lastKana + ' ' + user.firstKana;
    } else {
      return (user.lastKana || '') + (user.firstKana || '');
    }
  };

  const saveIncomes = async (headers: (string | Date)[], data: any[]) => {
    const db = firebase.firestore();
    if (data.length === 0) throw Error('データが存在しません。');
    // ヘッダの登録
    const cols: { [key: string]: { label: string; col: number } } = {
      userCode: { label: USER_CODE, col: -1 },
      userName: { label: USER_NAME, col: -1 },
      payday: { label: PAYDAY, col: -1 },
    };
    for (const key in cols) {
      const col = headers.findIndex((name) => name === cols[key].label);
      if (col >= 0) cols[key].col = col;
      else throw Error(`${cols[key].label}の列が見つかりません。`);
    }
    data = data.filter((row) => row[cols.userCode.col]);

    const payday_v = data[0][cols.payday.col];
    const companyName = companies[companyCode].name;
    const { payday, month } = getPaydayStr(payday_v);
    const savedBy = currentUserName();

    const headerId = `${payday}:${companyCode}`; // 支給日と会社コードでIDを作成
    const headerSnapshot = await db
      .collection('incomeHeaders')
      .doc(headerId)
      .get();
    const income_header = headerSnapshot.data();
    if (income_header && !overwrite) {
      setConfirmOverwrite(true);
      let message = '';
      if (headers.toString() !== income_header.contents.toString()) {
        const diff =
          headers.length !== income_header.contents.length
            ? `列数: ${income_header.contents.length}⇒${headers.length}`
            : '';
        message = `データが既に存在し、ヘッダも異なります(${diff})。`;
      } else {
        message = 'データが既に存在します。';
      }
      throw Error(message);
    }

    await db.collection('incomeHeaders').doc(headerId).set({
      companyCode,
      companyName,
      payday,
      month,
      savedBy,
      contents: headers,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
    const procUnit = 100;
    const tasks: Promise<Result[]>[] = [];
    for (let i = 0; i < data.length / procUnit; i++) {
      const partOfData = data.slice(i * procUnit, (i + 1) * procUnit);
      const promise = new Promise<Result[]>(async (resolve) => {
        const results: Result[] = [];
        for await (let row of partOfData) {
          const payday_v = row[cols.payday.col];
          const year = payday_v.getFullYear();
          const { payday, month } = getPaydayStr(payday_v);
          const userCode = shapeUserCode(row[cols.userCode.col]);
          const userName = row[cols.userName.col];
          const user_code = userCode?.match(/^\d+$/)
            ? zeroPadding(Number(userCode), 10)
            : (userCode + '').padEnd(10, '0');
          const company_code = companyCode?.match(/^\d+$/)
            ? zeroPadding(Number(companyCode), 10)
            : (companyCode + '').padEnd(10, '0');
          const rank = `${user_code}${company_code}`;
          const result: Result = { result: false, userName, message: '' };
          try {
            const userSnap = await db.collection('users').doc(userCode).get();
            const userData = userSnap?.data() as User | undefined;
            const userKana = userData ? getUserKana(userData) : '';

            if (!userData?.code) {
              const [lastName, firstName] = userName.split(/\s+/);
              const rank = userCode.match(/^\d+$/)
                ? zeroPadding(Number(userCode), 10)
                : (userCode + '').padEnd(10, '0');
              const newUserData: User = {
                code: userCode,
                firstName: firstName || '',
                lastName: lastName || '',
                firstKana: null,
                lastKana: null,
                savedBy,
                parentCode: null,
                rank,
              };
              await db
                .collection('users')
                .doc(userCode)
                .set(newUserData, { merge: true });
            }

            const incomes = db
              .collection('users')
              .doc(userCode)
              .collection('incomes');
            const incomePaydays = db
              .collection('users')
              .doc(userCode)
              .collection('incomePaydays');

            // 給与明細保存
            const docId = `${payday}:${companyCode}`;
            await incomes.doc(docId).set({
              companyCode,
              companyName,
              userCode,
              userName,
              userKana,
              payday,
              month,
              rank,
              savedBy,
              contents: row,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
            // 対象月保存
            const doc_id = `${year}:${companyCode}`;
            await incomePaydays.doc(doc_id).set(
              {
                companyCode,
                paydays: firebase.firestore.FieldValue.arrayUnion(payday),
              },
              { merge: true }
            );
            result.result = true;
          } catch (error) {
            result.result = false;
            result.message = errorMessage(error);
          }
          results.push(result);
        }
        resolve(results);
      });
      tasks.push(promise);
    }
    const res = await Promise.all(tasks);
    const results = ([] as Result[]).concat(...res); // flatten
    return results;
  };

  const importIncomes = async (e: React.FormEvent) => {
    e.preventDefault();
    const files = ref?.current?.files;
    const blob = files && files[0];
    if (blob && blob.name) {
      const db = firebase.firestore();
      setLoading(true);
      setMessages({ content: [] });
      try {
        const { headers, data } = await getIncomesFromExcel(blob);
        console.log({ headers, data });
        const results = await saveIncomes(headers, data);
        console.log({ results });
        const saved = results.filter((result) => result.result);
        setMessages((prev) => {
          return {
            content: prev.content.concat(
              `給与明細データ 保存: ${saved.length}件`
            ),
            severity: 'success',
          };
        });
        results
          .filter((result) => !result.result)
          .forEach((result) => {
            setMessages((prev) => {
              return {
                content: prev.content.concat(
                  `　エラー: ${result.userName} ${result.message}`
                ),
                severity: 'error',
              };
            });
          });

        // データ件数更新
        const month = getFirstRowMonth(headers, data);
        if (month) await updateIncomeCounts(month);

        // 操作履歴
        const userCode = userCodeFromEmail(currentUser?.email || '');
        if (currentUser && userCode) {
          const userName = !!user ? fullName(user) : currentUser.email;
          await db
            .collection('users')
            .doc(userCode)
            .collection('operations')
            .add({
              userCode,
              userName,
              action: 'importIncome',
              actionName: '給与明細取込',
              message: `会社:${companies[companyCode].name}、対象月:${month}、件数:${saved.length}`,
              uid: currentUser.uid,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }

        setConfirmOverwrite(false);
        setOverwrite(false);
        setLoading(false);
      } catch (error) {
        console.log({ error });
        setLoading(false);
        setMessages((prev) => {
          return {
            content: prev.content.concat(errorMessage(error)),
            severity: 'error',
          };
        });
      }
    }
  };

  const updateIncomeCounts = async (month: string) => {
    if (month && companyCode) {
      const db = firebase.firestore();
      try {
        const query = await db
          .collectionGroup('incomes')
          .where('month', '==', month)
          .where('companyCode', '==', companyCode)
          .get();
        await db
          .collection('incomeCounts')
          .doc(`${month}:${companyCode}`)
          .set({ month, companyCode, count: query.size });
      } catch (error) {
        console.log({ error });
        setMessages((prev) => {
          return {
            content: prev.content.concat(
              errorMessage(
                error,
                'データのカウント処理でエラーが発生しました。'
              )
            ),
            severity: 'error',
          };
        });
      }
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <Container maxWidth="xs">
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            給与明細取込
          </Typography>
          <Paper square className={classes.paper}>
            <form onSubmit={importIncomes}>
              <Select
                fullWidth
                value={companyCode || ''}
                disabled={loading}
                required
                onChange={(e) => setCompanyCode(String(e?.target?.value))}
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                {companyCodes.map(
                  (code, index) =>
                    companies[code] && (
                      <MenuItem key={index} value={code}>
                        {toHalfWidthChar(companies[code].name)}
                      </MenuItem>
                    )
                )}
              </Select>
              <Box mt={2} mb={2}>
                <input
                  type="file"
                  name="ref"
                  ref={ref}
                  accept=".xlsx"
                  disabled={loading}
                  required
                />
              </Box>
              {confirmOverwrite && (
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="enableAuth"
                        checked={overwrite}
                        disabled={loading}
                        onChange={(e) => {
                          setOverwrite(e.target.checked);
                        }}
                      />
                    }
                    label="上書きする"
                  />
                </FormControl>
              )}

              <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
                style={{ margin: '10px 0' }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  取込実行
                </Button>
              </Grid>
            </form>
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
        </Container>
        <OperationList showAll={false} maxCount={20} />
        {messages.content.length > 0 && (
          <Alert
            message={messages.content}
            severity={messages.severity}
            onClose={() => setMessages({ content: [] })}
          />
        )}
      </Box>
    </>
  );
};

export default ImportIncome;
