import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import * as xlsx from 'xlsx';
import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import {
  errorMessage,
  fullName,
  makeMonths,
  nameWithCode,
  shapeUserCode,
  userCodeFromEmail,
  zeroPadding,
} from './tools';
import Alert from './Alert';
import OperationList from './OperationList';
import AppContext from './AppContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  paper: {
    padding: 20,
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

interface ImportPaymentProps {
  open: boolean;
  onClose: () => void;
}

const ImportPayment: React.FC<ImportPaymentProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<string[]>([]);
  const [months, setMonths] = useState<[number, number][]>([]);
  const [targetMonth, setTargetMonth] = useState<string>('');
  const { currentUser, user } = useContext(AppContext);
  const ref_bonus = useRef<HTMLInputElement>(null);
  const ref_house = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  useEffect(() => {
    let lastMonth = new Date();
    setMonths(
      makeMonths(
        2020,
        1,
        lastMonth.getFullYear(),
        lastMonth.getMonth() + 1
      ).reverse()
    );
  }, []);

  const currentUserName = () => {
    let name = nameWithCode(user);
    if (!name) name = currentUser?.uid || '';
    return name;
  };

  const getBonusFromExcel = async () => {
    return new Promise<{ [code: string]: number }>((resolve, reject) => {
      let header: string[] = [];
      const data: string[][] = [];
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          const fileData = reader.result;
          const wb = xlsx.read(fileData, {
            type: 'binary',
            raw: true,
            cellDates: true,
            cellStyles: true,
            cellHTML: true,
            sheetStubs: true,
          });
          const sheet = wb.Sheets[wb.SheetNames[0]];
          const range_a1 = sheet['!ref'];
          if (range_a1) {
            const range = xlsx.utils.decode_range(range_a1);
            for (let r = range.s.r; r <= range.e.r; r++) {
              const rows: string[] = [];
              for (let c = range.s.c; c <= range.e.c; c++) {
                const p = xlsx.utils.encode_cell({ c, r });
                const cell = sheet[p];
                rows.push(cell?.w || '');
              }
              if (r === 0) {
                header = rows;
              } else {
                data.push(rows);
              }
            }
            const index_code = header.findIndex((str) => str === '社員コード');
            if (index_code < 0) reject('「社員コード」が指定されていません。');
            const index_num = header.findIndex((str) => str === '回数');
            if (index_num < 0) reject('「回数」が指定されていません。');
            const index_amount = header.findIndex((str) => str === '金額');
            if (index_amount < 0) reject('「金額」が指定されていません。');

            const bonuses: { [code: string]: number } = {};
            data.forEach((row) => {
              const userCode = shapeUserCode(row[index_code]);
              const num = Number(row[index_num]);
              const amount = Number(row[index_amount]);
              if (userCode) {
                if (!bonuses[userCode]) bonuses[userCode] = 0;
                bonuses[userCode] += num * amount;
              }
            });
            resolve(bonuses);
          }
        } catch (error) {
          reject(error);
        }
      };
      const files = ref_bonus?.current?.files;
      const blob = files && files[0];
      if (blob && blob.name) {
        reader.readAsBinaryString(blob);
      } else {
        resolve({});
      }
    });
  };

  // Timezone の問題があるので生成後、作り直す
  const createDate = (str: string) => {
    const date = new Date(str);
    if (isNaN(+date)) return date;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  const checkDate = (date: Date, startDate: Date, endDate: Date) => {
    return (
      (isNaN(+startDate) || startDate <= date) &&
      (isNaN(+endDate) || date <= endDate)
    );
  };

  const getHouseFromExcel = async () => {
    return new Promise<{ [code: string]: number }>((resolve, reject) => {
      let header: string[] = [];
      const data: string[][] = [];
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          const fileData = reader.result;
          const wb = xlsx.read(fileData, {
            type: 'binary',
            raw: true,
            cellDates: true,
            cellStyles: true,
            cellHTML: true,
            sheetStubs: true,
          });
          const sheet = wb.Sheets[wb.SheetNames[0]];
          const range_a1 = sheet['!ref'];
          if (range_a1) {
            const range = xlsx.utils.decode_range(range_a1);
            for (let r = range.s.r; r <= range.e.r; r++) {
              const rows: string[] = [];
              for (let c = range.s.c; c <= range.e.c; c++) {
                const p = xlsx.utils.encode_cell({ c, r });
                const cell = sheet[p];
                rows.push(cell?.w || '');
              }
              if (r === 0) {
                header = rows;
              } else {
                data.push(rows);
              }
            }
            const index_code = header.findIndex((str) => str === '社員コード');
            if (index_code < 0) reject('「社員コード」が指定されていません。');
            const index_start = header.findIndex((str) => str === '入居日');
            if (index_start < 0) reject('「入居日」が指定されていません。');
            const index_end = header.findIndex((str) => str === '退去日');
            if (index_end < 0) reject('「退去日」が指定されていません。');
            const index_dedct = header.findIndex((str) => str === '給与天引き');
            if (index_dedct < 0) reject('「給与天引き」が指定されていません。');
            const index_sum = header.findIndex((str) => str === '合　計');
            if (index_sum < 0) reject('「合　計」が指定されていません。');

            const yearStr = targetMonth.slice(0, 4);
            const monthStr = targetMonth.slice(4, 6);
            const date = new Date(Number(yearStr), Number(monthStr), 0); // 月末

            const houses: { [code: string]: number } = {};
            data.forEach((row) => {
              const userCode = shapeUserCode(row[index_code]);
              const deduct = Number(row[index_dedct]);
              const sum = Number(row[index_sum]);
              const startDate = createDate(row[index_start]);
              const endDate = createDate(row[index_end]);
              if (userCode) {
                if (!houses[userCode]) houses[userCode] = 0;
                if (checkDate(date, startDate, endDate)) {
                  houses[userCode] += sum - deduct;
                }
              }
            });
            resolve(houses);
          }
        } catch (error) {
          reject(error);
        }
      };
      const files = ref_house?.current?.files;
      const blob = files && files[0];
      if (blob && blob.name) {
        reader.readAsBinaryString(blob);
      } else {
        resolve({});
      }
    });
  };

  const importPayments = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessages([]);
    try {
      const bonuses = await getBonusFromExcel();
      const houses = await getHouseFromExcel();
      await savePayments(bonuses, houses);
      setLoading(false);
    } catch (error) {
      setMessages((prev) => prev.concat(errorMessage(error)));
      setLoading(false);
    }
  };

  const savePayments = async (
    bonuses: { [code: string]: number },
    houses: { [code: string]: number }
  ) => {
    const db = firebase.firestore();
    const codes = new Set(Object.keys(bonuses).concat(Object.keys(houses)));
    const userCodes = Array.from(codes);
    const savedBy = currentUserName();

    for await (const userCode of userCodes) {
      if (!userCode) continue;
      const value: {
        userCode: string;
        month: string;
        bonus?: number;
        house?: number;
        savedBy: string;
      } = {
        userCode,
        month: targetMonth,
        savedBy,
      };
      if (bonuses[userCode]) value.bonus = bonuses[userCode];
      if (houses[userCode]) value.house = houses[userCode];
      await db
        .collection('users')
        .doc(userCode)
        .collection('payments')
        .doc(targetMonth)
        .set(value, { merge: true });
    }

    // 操作履歴
    const userCode = userCodeFromEmail(currentUser?.email || '');
    if (currentUser && userCode) {
      const userName = !!user ? fullName(user) : currentUser.email;
      await db
        .collection('users')
        .doc(userCode)
        .collection('operations')
        .add({
          userCode,
          userName,
          action: 'importPayment',
          actionName: '社宅＆ボーナス取込',
          message: `対象月:${targetMonth}、人数:${userCodes.length}、ボーナス:${
            Object.keys(bonuses).length
          }、社宅:${Object.keys(houses).length}`,
          uid: currentUser.uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }
  };

  const clearPayments = async () => {
    try {
      setLoading(true);
      setMessages([]);
      if (!targetMonth) throw Error('対象月を指定してください。');

      const yearStr = targetMonth.slice(0, 4);
      const monthStr = targetMonth.slice(4, 6);
      if (
        window.confirm(
          `${yearStr}年${monthStr}月の社宅＆ボーナスを消去しますか？`
        )
      ) {
        const db = firebase.firestore();
        const querySnapshot = await db
          .collectionGroup('payments')
          .where('month', '==', targetMonth)
          .get();
        const targetRefs: firebase.firestore.DocumentReference[] = [];
        querySnapshot.forEach((doc) => {
          targetRefs.push(doc.ref);
        });
        for await (const tagetRef of targetRefs) {
          await tagetRef.delete();
        }

        // 操作履歴
        const userCode = userCodeFromEmail(currentUser?.email || '');
        if (currentUser && userCode) {
          const userName = !!user ? fullName(user) : currentUser.email;
          await db
            .collection('users')
            .doc(userCode)
            .collection('operations')
            .add({
              userCode,
              userName,
              action: 'clearPayment',
              actionName: '社宅＆ボーナスクリア',
              message: `対象月:${targetMonth}、件数:${targetRefs.length}`,
              uid: currentUser.uid,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }
      }
      setLoading(false);
    } catch (error) {
      console.log({ error });
      setMessages((prev) => prev.concat(errorMessage(error)));
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <Container maxWidth="xs">
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            社宅＆ボーナス取込
          </Typography>
          <Paper square className={classes.paper}>
            <form onSubmit={importPayments}>
              <FormControl fullWidth>
                <InputLabel id="month-label">対象月</InputLabel>
                <Select
                  fullWidth
                  value={targetMonth}
                  labelId="month-label"
                  required
                  disabled={loading}
                  onChange={(e) => setTargetMonth(String(e?.target?.value))}
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  {months.map(([year, month], index) => (
                    <MenuItem
                      key={index}
                      value={`${year}${zeroPadding(month, 2)}`}
                    >{`${year}年${zeroPadding(month, 2)}月`}</MenuItem>
                  ))}
                </Select>{' '}
              </FormControl>

              <Box mt={2} mb={2}>
                <Box>ボーナス&nbsp;</Box>
                <input
                  type="file"
                  name="ref_bonus"
                  ref={ref_bonus}
                  accept=".xlsx"
                  disabled={loading}
                />
              </Box>
              <Box mt={2} mb={2}>
                <Box>社宅&nbsp;</Box>
                <input
                  type="file"
                  name="ref_house"
                  ref={ref_house}
                  accept=".xlsx"
                  disabled={loading}
                />
              </Box>
              <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
                style={{ margin: '10px 0' }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  取込実行
                </Button>
              </Grid>
            </form>
            <Grid
              container
              direction="column"
              justify="space-around"
              alignItems="stretch"
              style={{ margin: '10px 0' }}
            >
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={clearPayments}
              >
                クリア
              </Button>
            </Grid>
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
        </Container>
        <OperationList showAll={false} maxCount={20} />
        {messages.length > 0 && (
          <Alert message={messages} onClose={() => setMessages([])} />
        )}
      </Box>
    </>
  );
};

export default ImportPayment;
