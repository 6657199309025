import React, { useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Grid,
  Hidden,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import {
  nameWithCode,
  hiraToKana,
  errorMessage,
  userCodeFromEmail,
  fromInternationalPhoneNumber,
} from './tools';
import { User } from './types';
import Alert, { AlertMessage } from './Alert';
import IncomePage from './IncomePage';
import BonusPage from './BonusPage';
import UserDialog from './UserDialog';
import UserPhoneDialog from './UserPhoneDialog';
import UserAuthDialog from './UserAuthDialog';

import firebase from './firebase';
import 'firebase/auth';
import 'firebase/firestore';
import './App.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 30,
    backgroundColor: 'white',
  },
  table: {
    tableLayout: 'fixed',
    marginTop: 20,
  },
  username: {},
  email: {},
  phone: {},
  entry: { width: 80 },
  retire: { width: 80 },
  parentCode: { width: 60 },
  time: {},
  saver: {},
  auth: { width: 70 },
  action: { width: 160 },
  button: {
    margin: '0 3px',
    padding: 2,
    minWidth: 40,
    height: 24,
  },
  button2: {
    marginLeft: 15,
    marginTop: 12,
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
  search: {
    width: 250,
  },
}));

const PER_PAGE = 20;
const NULLs = Array(PER_PAGE).fill(null);

const UserList: React.FC = (props) => {
  const [search, setSearch] = useState<string>('');
  const [users, setUsers] = useState<Map<string, User>>(
    new Map<string, User>()
  );
  const [phoneNumbers, setPhoneNumbers] = useState<{ [code: string]: string }>(
    {}
  );
  const [targetUserCode, setTargetUserCode] = useState<string | null>(null);
  const [mode, setMode] =
    useState<'list' | 'user' | 'phone' | 'auth' | 'income' | 'bonus'>('list');
  const [loading, setLoading] = useState<boolean>(false);
  const [queryCount, setQueryCount] = useState<number>(-1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [startRanks, setStartRanks] = useState<string[]>(['']); // 各ページ最後の社員番号
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const targetUser =
    (targetUserCode ? users.get(targetUserCode) : null) || null;
  const userCode = targetUser ? targetUser.code : null;
  const firstPos = currentPage * PER_PAGE;
  const nextFirstPos = (currentPage + 1) * PER_PAGE;
  const [anchorRefs, setAnchorRefs] = useState<(Element | null)[]>(NULLs);
  const classes = useStyles();

  const queryUsers = async (page: number, start_ranks: string[]) => {
    const db = firebase.firestore();
    let collection = db.collection('users');
    let query: firebase.firestore.Query;
    const REG_MAIL =
      /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
    try {
      resetData();
      setMessages({ content: [] });
      setCurrentPage(page);
      if (search) {
        if (search.match(/^\w+$/)) {
          query = collection
            .orderBy('code')
            .startAt(search)
            .endAt(search + '\uf8ff')
            .limit(PER_PAGE);
        } else if (
          search.match(/^[ァ-ヶー　]*$/) ||
          search.match(/^[ぁ-んー　]*$/)
        ) {
          const kana = hiraToKana(search);
          query = collection
            .orderBy('lastKana')
            .startAt(kana)
            .endAt(kana + '\uf8ff')
            .limit(PER_PAGE);
        } else if (search.match(REG_MAIL)) {
          query = collection
            .orderBy('email')
            .startAt(search)
            .endAt(search + '\uf8ff')
            .limit(PER_PAGE);
        } else {
          query = collection
            .orderBy('lastName')
            .startAt(search)
            .endAt(search + '\uf8ff')
            .limit(PER_PAGE);
        }
      } else {
        query = collection.orderBy('rank');
        if (start_ranks[page]) query = query.startAfter(start_ranks[page]);
        query = query.limit(PER_PAGE);
      }
      const snapshot = await query.get();
      const userMap = new Map<string, User>();
      snapshot.forEach((doc) => {
        userMap.set(doc.ref.id, doc.data() as User);
      });
      setUsers(userMap);

      // 認証情報
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('getAuthUsers');
      const result = await func({ userCodes: Array.from(userMap.keys()) });
      if (result.data) {
        const phone_numbers: { [code: string]: string } = {};
        result.data.forEach((user: any) => {
          const code = userCodeFromEmail(user.email || '');
          if (code)
            phone_numbers[code] = user.phoneNumber
              ? fromInternationalPhoneNumber(user.phoneNumber)
              : '';
        });
        setPhoneNumbers(phone_numbers);
      }

      // 開始社員番号情報更新
      if (!search) {
        if (!start_ranks[page + 1]) {
          const values = Array.from(userMap.values());
          const new_start_ranks = [...start_ranks];
          new_start_ranks[page + 1] = values[values.length - 1].rank || '';
          setStartRanks(new_start_ranks);
        }

        // データ件数(検索文字が指定されていないときのみ)
        const countSnap = await db.collection('userCounts').doc('all').get();
        const countDoc = countSnap.data();
        if (countDoc) setQueryCount(countDoc.count as number);
      }
    } catch (error) {
      console.log({ error });
      setMessages({
        content: errorMessage(error),
        severity: 'error',
      });
    }
  };

  const resetData = () => {
    setUsers(new Map<string, User>());
    setQueryCount(-1);
    setPhoneNumbers({});
  };

  const showPagePos = () => {
    return `${firstPos + 1}〜${
      nextFirstPos > queryCount ? queryCount : nextFirstPos
    }/${queryCount}件`;
  };

  const movePrevPage = () => {
    setMessages({ content: [] });
    if (currentPage > 0) {
      queryUsers(currentPage - 1, startRanks);
    }
  };

  const moveNextPage = () => {
    setMessages({ content: [] });
    if (nextFirstPos < queryCount) {
      queryUsers(currentPage + 1, startRanks);
    }
  };

  const updateUser = (user_code: string, user: User, auth: boolean) => {
    users.set(user_code, user);
    setUsers(users);
    if (auth && !phoneNumbers.hasOwnProperty(user_code))
      setPhoneNumbers((prev) => ({ ...prev, [user_code]: '' }));
  };

  const generatePassword = (userCode: string) => () => {
    if (window.confirm('パスワードを再発行しますか？')) {
      setLoading(true);
      setMessages({ content: [] });
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('generatePasswords');
      func({
        userCodes: [userCode],
        enableSendEmail: true,
        targetSigned: true,
      })
        .then((result) => {
          console.log({ result });
          setLoading(false);
          if (result?.data?.length > 0 && result.data[0].result) {
            setMessages({
              content: 'パスワードを再発行しメール通知しました。',
              severity: 'success',
            });
          } else {
            setMessages({
              content:
                result.data[0].message || 'パスワード再発行に失敗しました。',
              severity: 'error',
            });
          }
        })
        .catch((error) => {
          console.log({ error });
          setLoading(false);
          setMessages({
            content: errorMessage(error),
            severity: 'error',
          });
        });
    }
    setAnchorRefs(NULLs);
  };

  if (targetUserCode && targetUser && mode === 'phone') {
    return (
      <UserPhoneDialog
        open
        userCode={targetUserCode}
        user={targetUser}
        onClose={() => setMode('list')}
      />
    );
  } else if (targetUserCode && targetUser && mode === 'auth') {
    return (
      <UserAuthDialog
        open
        userCode={targetUserCode}
        user={targetUser}
        onClose={() => setMode('list')}
      />
    );
  } else if (userCode && mode === 'income') {
    return (
      <IncomePage
        userCode={userCode}
        month=""
        backLabel="社員一覧へ"
        onClose={() => setMode('list')}
      />
    );
  } else if (userCode && mode === 'bonus') {
    return (
      <BonusPage
        userCode={userCode}
        month=""
        backLabel="社員一覧へ"
        onClose={() => setMode('list')}
      />
    );
  } else if (mode === 'user') {
    return (
      <UserDialog
        open
        userCode={targetUserCode}
        updateUser={updateUser}
        onClose={() => setMode('list')}
      />
    );
  } else {
    return (
      <>
        {loading && <LinearProgress />}
        <Box m={5}>
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            社員一覧
          </Typography>
          <Paper className={classes.paper}>
            <Grid container spacing={4}>
              <Grid item>
                <TextField
                  label="番号・名字・カナ・email"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className={classes.search}
                />
                <Button
                  variant="outlined"
                  color="inherit"
                  className={classes.button2}
                  onClick={() => {
                    setMessages({ content: [] });
                    if (search) setQueryCount(-1);
                    queryUsers(0, ['']);
                  }}
                >
                  検索
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  className={classes.button2}
                  onClick={() => {
                    setMessages({ content: [] });
                    setTargetUserCode(null);
                    setMode('user');
                  }}
                >
                  新規
                </Button>
              </Grid>
            </Grid>
            <Table size="small" className={classes.table}>
              <colgroup>
                <col className={classes.username} />
                <Hidden smDown>
                  <col className={classes.email} />
                  <col className={classes.phone} />
                  <col className={classes.entry} />
                  <col className={classes.retire} />
                  <col className={classes.parentCode} />
                  <col className={classes.auth} />
                </Hidden>
                <Hidden mdDown>
                  <col className={classes.time} />
                  <col className={classes.saver} />
                </Hidden>
                <col className={classes.action} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>氏名</TableCell>
                  <Hidden smDown>
                    <TableCell>email</TableCell>
                    <TableCell>電話</TableCell>
                    <TableCell>
                      <small>入社日</small>
                    </TableCell>
                    <TableCell>
                      <small>退社日</small>
                    </TableCell>
                    <TableCell>親</TableCell>
                    <TableCell>
                      <small>ﾛｸﾞｲﾝ</small>
                    </TableCell>
                  </Hidden>
                  <Hidden mdDown>
                    <TableCell>
                      <small>初ﾛｸﾞｲﾝ</small>
                    </TableCell>
                    <TableCell>更新者</TableCell>
                  </Hidden>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(users.entries()).map(([user_code, user], index) => (
                  <TableRow key={index}>
                    <TableCell>{nameWithCode(user)}</TableCell>
                    <Hidden smDown>
                      <TableCell>
                        <small>{user.email}</small>
                      </TableCell>
                      <TableCell>
                        <small>{phoneNumbers[user_code]}</small>
                      </TableCell>
                      <TableCell>
                        {user.enteredAt?.toDate()?.toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        {user.retiredAt?.toDate()?.toLocaleDateString()}
                      </TableCell>
                      <TableCell>{user.parentCode}</TableCell>
                      <TableCell>
                        {phoneNumbers.hasOwnProperty(user_code) ? '○' : ''}
                      </TableCell>
                    </Hidden>
                    <Hidden mdDown>
                      <TableCell>
                        {user.firstSignInAt?.toDate()?.toLocaleDateString()}
                      </TableCell>
                      <TableCell>
                        <small>{user.savedBy}</small>
                      </TableCell>
                    </Hidden>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        className={classes.button}
                        onClick={() => {
                          setMessages({ content: [] });
                          setTargetUserCode(user_code);
                          setMode('user');
                        }}
                      >
                        編集
                      </Button>
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        className={classes.button}
                        onClick={() => {
                          setMessages({ content: [] });
                          setTargetUserCode(user_code);
                          setMode('auth');
                        }}
                      >
                        権限
                      </Button>
                      <IconButton
                        aria-label="display more actions"
                        edge="end"
                        color="inherit"
                        size="small"
                        onClick={(e: React.MouseEvent) => {
                          const anchor_refs = [...NULLs];
                          anchor_refs[index] = e.currentTarget;
                          setAnchorRefs(anchor_refs);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        anchorEl={anchorRefs[index]}
                        keepMounted
                        open={!!anchorRefs[index]}
                        onClose={() => setAnchorRefs(NULLs)}
                      >
                        <MenuItem
                          onClick={() => {
                            setMessages({ content: [] });
                            setTargetUserCode(user_code);
                            setMode('income');
                            setAnchorRefs(NULLs);
                          }}
                        >
                          給与明細
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setMessages({ content: [] });
                            setTargetUserCode(user_code);
                            setMode('bonus');
                            setAnchorRefs(NULLs);
                          }}
                        >
                          賞与明細
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setMessages({ content: [] });
                            setTargetUserCode(user_code);
                            setMode('phone');
                            setAnchorRefs(NULLs);
                          }}
                        >
                          電話変更
                        </MenuItem>
                        <MenuItem onClick={generatePassword(user_code)}>
                          パスワード再発行
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {queryCount >= 0 && (
              <Grid container style={{ marginTop: 10 }}>
                <Grid item>
                  {currentPage > 0 ? (
                    <Chip size="small" label="前へ" onClick={movePrevPage} />
                  ) : (
                    '前へ'
                  )}
                  &nbsp;
                  {nextFirstPos < queryCount ? (
                    <Chip size="small" label="次へ" onClick={moveNextPage} />
                  ) : (
                    '次へ'
                  )}
                </Grid>
                <Grid item>
                  <Typography>{showPagePos()}</Typography>
                </Grid>
              </Grid>
            )}
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
          {messages.content.length > 0 && (
            <Alert
              message={messages.content}
              severity={messages.severity}
              onClose={() => setMessages({ content: [] })}
            />
          )}
        </Box>
      </>
    );
  }
};

export default UserList;
