import { User, KkbUser } from './types';
import firebase from './firebase';

export const zeroPadding = (num: number, len: number) => {
  return String(num).padStart(len, '0');
};

// 4桁未満の数字の場合、4桁になるように0埋めする
export const shapeUserCode = (userCode: string) => {
  if (userCode.match(/^\d+$/) && userCode.length <= 5) {
    return zeroPadding(+userCode, 4);
  } else {
    return userCode;
  }
};

export const hiraToKana = (str: string) => {
  return str.replace(/[\u3041-\u3096]/g, function (match: string) {
    var chr = match.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });
};

// 国内電話番号=>国際電話番号へ変換
export const toInternationalPhoneNumber = (phoneNumber: string) => {
  let phone = phoneNumber.replace(/[-\s]/g, '');
  if (phone.length > 0 && phone[0] === '0') phone = phone.substring(1);
  return '+81' + phone;
};

// 国際電話番号=>国内電話番号へ変換
export const fromInternationalPhoneNumber = (phoneNumber: string) => {
  const m = phoneNumber.match(/^(\+81)(\d+)/);
  if (m && m[2]) {
    return '0' + m[2];
  }
  return '';
};

export const makeMonths = (
  startYear: number,
  startMonth: number,
  endYear: number,
  endMonth: number
) => {
  let months: [number, number][] = [];
  for (let year = startYear; year <= endYear; year++) {
    const start_month = year === startYear ? startMonth : 1;
    const end_month = year === endYear ? endMonth : 12;
    for (let month = start_month; month <= end_month; month++) {
      months.push([year, month]);
    }
  }
  return months;
};

export const fullName = (user: KkbUser | User | null) => {
  if (user) {
    let name = `${user.lastName} ${user.firstName}`;
    return name;
  } else {
    return '';
  }
};

export const nameWithCode = (user: KkbUser | User | null) => {
  if (user) {
    let name = `${user.lastName} ${user.firstName}`;
    if (user.code) name += `(${user.code})`;
    return name;
  } else {
    return '';
  }
};

export const getOrigin = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'http://localhost:3000';
    case 'production':
      return 'https://ebondsalary.com';
    default:
      return '';
  }
};

export const dateStr = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${zeroPadding(month, 2)}-${zeroPadding(day, 2)}`;
};

export const validEmail = (email: string) => {
  const regexp =
    /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
  return (
    email.match(regexp) && !email.match(/@ebondkkb/) && !email.match(/@kkb/)
  );
};

export const toHalfWidthChar = (str: string | null | undefined) => {
  if (str) {
    const half_str = str.replace(/[！-～]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });

    return half_str
      .replace(/゛/g, 'ﾞ')
      .replace(/゜/g, 'ﾟ')
      .replace(/”/g, '"')
      .replace(/’/g, "'")
      .replace(/‘/g, '`')
      .replace(/￥/g, '\\')
      .replace(/　/g, ' ')
      .replace(/〜/g, '~')
      .replace(/[－−]/g, '-');
  } else {
    return '';
  }
};

export const userCodeFromEmail = (email: string) => {
  const m = email.match(/(.+)@ebondsalary.com/);
  if (m && m[1]) {
    return m[1];
  } else {
    return null;
  }
};

// cloud Functions のエラー
export const httpsErrorMessage = (error: any): string | undefined => {
  // FunctionsErrorCode
  switch (error.code) {
    case 'ok':
      return error.message;
    case 'cancelled':
      return '操作はキャンセルされました。';
    case 'unknown':
      return '不明なエラーです。';
    case 'invalid-argument':
      return '不正な引数が渡されました。';
    case 'deadline-exceeded':
      return '操作が完了する前に期限が切れました。操作が正常に完了しても、このエラーが返されることがあります。';
    case 'not-found':
      return '要求されたドキュメントが見つかりませんでした。';
    case 'already-exists':
      return 'データを作成しようとしましたが、すでに存在します。';
    case 'permission-denied':
      return '指定された操作を実行する権限がありません。';
    case 'resource-exhausted':
      return 'リソース不足です。';
    case 'failed-precondition':
      return 'オペレーションは拒否されました。';
    case 'aborted':
      return 'オペレーションは中止されました。';
    case 'out-of-range':
      return '有効な範囲を超えています。';
    case 'unimplemented':
      return '操作は実装されていないか、サポートされていないか、有効ではありません。';
    case 'internal':
      return '内部的なエラー。';
    case 'unavailable':
      return 'このサービスは現在使用することができません。';
    case 'data-loss':
      return 'データが壊れています。';
    case 'unauthenticated':
      return '認証されていないユーザーです。';
  }
};

// Admin Authentication API エラー
// https://firebase.google.com/docs/auth/admin/errors?hl=ja
export const adminAuthenticationAPIMessage = (
  error: any
): string | undefined => {
  switch (error.code) {
    case 'auth/email-already-exists':
      // メールアドレス => 社員番号
      return '指定された社員番号はすでに既存のユーザーによって使用されています。';
    case 'auth/id-token-expired':
      return '指定された Firebase ID トークンは期限切れです。';
    case 'auth/id-token-revoked':
      return 'Firebase ID トークンが取り消されました。';
    case 'auth/insufficient-permission':
      return 'リソースにアクセスするための権限がありません。';
    case 'auth/internal-error':
      return 'リクエストの処理中に、予期しないエラーが発生しました。';
    case 'auth/invalid-creation-time':
      return '作成時刻は有効な UTC 日付文字列でなければなりません。';
    case 'auth/invalid-credential':
      return '認証情報は、目的のアクションの実行には使用できません。';
    case 'auth/invalid-disabled-field':
      return 'disabled に指定された値は無効です。';
    case 'auth/invalid-display-name':
      return 'displayName に指定された値は無効です。';
    case 'auth/invalid-email':
      return 'メールアドレスの形式が正しくありません。';
    case 'auth/invalid-email-verified':
      return 'emailVerified に指定された値は無効です。';
    case 'auth/invalid-id-token':
      return '指定された ID トークンは有効な Firebase ID トークンではありません。';
    case 'auth/invalid-last-sign-in-time':
      return '最終ログイン時間は、有効な UTC 日付文字列でなければなりません。';
    case 'auth/invalid-password':
      return '無効なパスワードです。8 文字以上の文字列を指定する必要があります。';
    case 'auth/invalid-phone-number':
      return '無効な携帯番号です。';
    case 'auth/invalid-uid':
      return 'uid は、128 文字以下の空でない文字列を指定する必要があります。';
    case 'auth/missing-uid':
      return '現在のオペレーションには uid 識別子が必要です。';
    case 'auth/operation-not-allowed':
      return '提供されたログイン プロバイダは Firebase プロジェクトで無効になっています。';
    case 'auth/phone-number-already-exists':
      return '携帯番号 はすでに既存のユーザーによって使用されています。';
    case 'auth/project-not-found':
      return 'Firebase プロジェクトが見つかりませんでした。';
    case 'auth/session-cookie-expired':
      return '提供された Firebase セッションの Cookie は期限切れです。';
    case 'auth/session-cookie-revoked':
      return 'Firebase セッション Cookie が取り消されました。';
    case 'auth/uid-already-exists':
      return '提供された uid はすでに既存のユーザーによって使用されています。';
    case 'auth/user-not-found':
      return 'ユーザーが存在しません。';
    case 'auth/wrong-password':
      return 'パスワードが間違っています。';
  }
};

// firebase.auth.Error
// https://firebase.google.com/docs/reference/js/firebase.auth.Error
export const authErrorMessage = (error: any): string | undefined => {
  switch (error.code) {
    case 'auth/too-many-requests':
      return 'リクエストがブロックされています。少し遅れて再試行すると、ブロックが解除されます。';
    case 'auth/user-disabled':
      return 'ユーザーアカウントが無効です。';
  }
};

// FirestoreErrorCode
// https://firebase.google.com/docs/reference/js/firebase.firestore
export const firestoreErrorMessage = (error: any): string | undefined => {
  // FirestoreErrorCode
  switch (error.code) {
    case 'cancelled':
      return '操作はキャンセルされました。';
    case 'deadline-exceeded':
      return '操作が完了する前に期限が切れました。操作が正常に完了しても、このエラーが返されることがあります。';
    case 'not-found':
      return '要求されたドキュメントが見つかりませんでした。';
    case 'permission-denied':
      return '指定された操作を実行する権限がありません。';
    case 'resource-exhausted':
      return 'リソース不足です。';
    case 'unimplemented':
      return '操作は実装されていないか、サポートされていないか、有効ではありません。';
    case 'internal':
      return '内部的なエラー。';
    case 'unauthenticated':
      return '認証されていないユーザーです。';
  }
};

export const errorMessage = (
  error: any,
  defaultMessage: string = ''
): string => {
  const err = error.details || error;
  // FunctionsErrorCode
  let message = httpsErrorMessage(err);
  if (message) return message;
  // Admin Authentication API Errors
  message = adminAuthenticationAPIMessage(err);
  if (message) return message;
  // firebase.auth.Error
  message = authErrorMessage(err);
  if (message) return message;
  // FirestoreErrorCode
  message = firestoreErrorMessage(err);
  if (message) return message;

  return err?.message || defaultMessage || 'エラーが発生しました。';
};

// payday: "YYYYMMDD"
export const monthFromPayday = (payday: string) => payday.slice(0, 6);

const MIN_PAYDAY = '20200901';

export const getTargetMonths = async (
  userCode: string,
  type: 'income' | 'bonus',
  all: boolean
) => {
  const db = firebase.firestore();
  const tableName = type === 'income' ? 'incomePaydays' : 'bonusPaydays';
  let target_months: string[] = [];
  const months = await db
    .collection('users')
    .doc(userCode)
    .collection(tableName)
    .get();
  months.forEach((doc) => {
    const data = doc.data();
    target_months = target_months.concat(data.paydays as string[]);
  });
  if (!all) {
    let date = new Date();
    if (date.getHours() < 8) date.setDate(date.getDate() - 1); // AM8:00 以降閲覧可能
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dateStr = `${year}${zeroPadding(month, 2)}${zeroPadding(day, 2)}`;
    target_months = target_months.filter((payday) => payday <= dateStr);
    target_months = target_months.filter((payday) => payday >= MIN_PAYDAY); // MIN_PAYDAY 以前は非表示
  }
  target_months = target_months.map((payday) => monthFromPayday(payday));
  target_months = target_months.sort();
  return Array.from(new Set(target_months));
};

export const hankaku2Zenkaku = (str: string): string =>
  str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) =>
    String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  );

export const yearFromWareki = (str: string): number | null => {
  const m = str.match(/^(明治|大正|昭和|平成|令和)\s?(\d+)\s?年/);
  if (m && m[1] && m[2]) {
    const eraName = m[1];
    let year = Number(m[2]);

    if (eraName === '明治') {
      year += 1867;
    } else if (eraName === '大正') {
      year += 1911;
    } else if (eraName === '昭和') {
      year += 1925;
    } else if (eraName === '平成') {
      year += 1988;
    } else if (eraName === '令和') {
      year += 2018;
    }
    return year;
  }

  return null;
};

export const warekiFromYear = (
  date: Date
): { era: string; year: number } | null => {
  const year = date.getFullYear();
  const eras = [
    { date: new Date('2019-05-01'), name: '令和' },
    { date: new Date('1989-01-08'), name: '平成' },
    { date: new Date('1926-12-25'), name: '昭和' },
    { date: new Date('1912-07-30'), name: '大正' },
    { date: new Date('1868-01-25'), name: '明治' },
  ];

  for (const era of eras) {
    const baseYear = era.date.getFullYear();
    const eraName = era.name;
    if (date >= era.date) return { era: eraName, year: year - baseYear + 1 };
  }
  return null;
};
