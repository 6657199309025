import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import AppContext from './AppContext';
import { errorMessage } from './tools';
import { User, CustomClaims } from './types';

const str = (text?: string | null) => (text ? String(text) : '');

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  dialog: {
    height: '100%',
    width: '100%',
    maxWidth: 'initial',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  text_area: {
    height: 240,
  },
  card: {
    backgroundColor: 'whitesmoke',
    padding: 5,
    marginTop: 10,
  },
  select: {
    width: 200,
  },
}));

interface UserAuthDialogProps {
  userCode: string;
  user: User;
  open: boolean;
  onClose: () => void;
}

const UserAuthDialog: React.FC<UserAuthDialogProps> = ({
  userCode,
  user,
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [uid, setUid] = useState<string | null>(null);
  const [customClaims, setCustomClaims] = useState<CustomClaims | null>(null);
  const { currentUser, companies } = useContext(AppContext);
  const isAdmin = customClaims?.role === 'admin';
  const classes = useStyles();

  useEffect(() => {
    if (currentUser && userCode) {
      setLoading(true);
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('getAuthUserByCode');
      func({ code: userCode })
        .then((result) => {
          setLoading(false);
          const userRecord = result.data.userRecord;
          setUid(userRecord.uid);
          setCustomClaims(userRecord.customClaims);
        })
        .catch((error) => {
          setLoading(false);
          setUid(null);
          setCustomClaims(null);
          console.log({ error });
          alert(errorMessage(error));
        });
    } else {
      setUid(null);
      setCustomClaims(null);
    }
  }, [currentUser, userCode]);

  const save = async () => {
    if (uid) {
      const companiesCount = Number(customClaims?.companies?.length);
      if (
        companiesCount > 10 &&
        companiesCount < Object.keys(companies).length
      ) {
        alert('全法人または10法人以下を選択してください。');
        return;
      }
      setLoading(true);
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('saveCustomClaims');
      func({ uid, customClaims })
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch((error) => {
          setLoading(false);
          console.log({ error });
          alert(errorMessage(error));
        });
    } else {
      setUid(null);
      setCustomClaims(null);
    }
  };

  const changeRole = (e: any) => {
    const role = e.target.value;
    if (
      uid &&
      (!role || role === 'viewer' || role === 'manager' || role === 'admin')
    ) {
      setCustomClaims({
        ...customClaims,
        role,
      });
    }
  };

  const changeCompanies = (companyCode: string) => (e: any) => {
    if (uid) {
      let companyCodes = customClaims?.companies || [];
      if (e.target.checked) {
        if (!companyCodes.includes(companyCode)) companyCodes.push(companyCode);
      } else {
        companyCodes = companyCodes.filter((code) => code !== companyCode);
      }

      setCustomClaims({
        ...customClaims,
        companies: companyCodes,
        allowAllCompanies:
          companyCodes.length === Object.keys(companies).length,
      });
    }
  };

  const changeAllCompanies = (e: any) => {
    let companyCodes: string[] = [];
    if (e.target.checked) {
      companyCodes = Object.keys(companies);
    }
    setCustomClaims({
      ...customClaims,
      companies: companyCodes,
      allowAllCompanies: e.target.checked,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth="sm"
      fullWidth
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {loading && <LinearProgress />}
      <DialogTitle>
        {user.lastName} {user.firstName} ({user.code})
      </DialogTitle>
      <DialogContent>
        <form>
          <FormControl fullWidth>
            <Select
              fullWidth
              value={customClaims?.role || ''}
              labelId="month-label"
              required
              disabled={loading}
              className={classes.select}
              onChange={changeRole}
            >
              <MenuItem value="">
                <em></em>
              </MenuItem>
              <MenuItem value="viewer">閲覧者</MenuItem>
              <MenuItem value="manager">労務</MenuItem>
              <MenuItem value="admin">管理者</MenuItem>
            </Select>
          </FormControl>

          <Card className={classes.card}>
            <CardContent>
              <Typography
                title="対象会社"
                variant="caption"
                component="h6"
                style={{
                  padding: 0,
                }}
              >
                対象会社
              </Typography>
              <FormControl fullWidth>
                <FormControlLabel
                  key={0}
                  control={
                    <Checkbox
                      name="changeAllCompanies"
                      checked={!!customClaims?.allowAllCompanies}
                      disabled={loading || isAdmin}
                      onChange={changeAllCompanies}
                    />
                  }
                  label="全法人"
                />
                {Object.entries(companies).map(([code, company], index) => (
                  <FormControlLabel
                    key={index + 1}
                    control={
                      <Checkbox
                        name="company.code"
                        checked={(customClaims?.companies || []).includes(
                          str(code)
                        )}
                        disabled={loading || isAdmin}
                        onChange={changeCompanies(str(code))}
                      />
                    }
                    label={str(company.name) + '(' + str(code) + ')'}
                  />
                ))}
              </FormControl>
            </CardContent>
          </Card>
          <Grid
            container
            direction="column"
            justify="space-around"
            alignItems="stretch"
            style={{ margin: '10px 0' }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={save}
            >
              権限保存
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UserAuthDialog;
