import React, { useState, useEffect, useContext } from 'react';
import {
  Checkbox,
  Grid,
  FormControl,
  LinearProgress,
  TextField,
  Button,
  Box,
  Container,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';

import { toHalfWidthChar, errorMessage, dateStr } from './tools';
import AppContext from './AppContext';
import { IncomeLayout, IncomeItemType } from './types';
import Alert, { AlertMessage } from './Alert';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    margin: 2,
  },
  selectCompany: {
    width: 300,
  },
  denseTableCell: {
    padding: '2px 10px',
  },
}));

interface EditLayoutProps {
  docId: string | null;
  dataType: 'income' | 'bonus';
  layout: IncomeLayout;
  onClose: () => void;
}

const EditLayout: React.FC<EditLayoutProps> = ({
  docId,
  dataType,
  layout,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [targetLayout, setTargetLayout] = useState<IncomeLayout>(layout);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const { companies, customClaims } = useContext(AppContext);
  const admin =
    customClaims?.role === 'admin' || customClaims?.allowAllCompanies;
  const companyCodes =
    (admin ? Object.keys(companies) : customClaims?.companies) || [];
  const classes = useStyles();

  useEffect(() => {
    setLoading(false);
  }, []);

  const changeCompanyCode = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const code = e.target.value;
      setTargetLayout({ ...targetLayout, companyCode: code });
    }
  };

  const save = async (e: React.FormEvent) => {
    e.preventDefault();
    if (targetLayout) {
      const duplicate = [
        rosterItems,
        supplyItems,
        deductItems,
        totalItems,
      ].some((items) => {
        if (existsDuplicateItem(items)) {
          setMessages({
            content: '項目が重複しています。',
            severity: 'error',
          });
          return true;
        } else {
          return false;
        }
      });
      if (duplicate) {
        return;
      }
      setLoading(true);
      try {
        const collectionName =
          dataType === 'income' ? 'incomeLayouts' : 'bonusLayouts';
        const collection = firebase.firestore().collection(collectionName);
        if (docId) {
          await collection.doc(docId).update(targetLayout);
        } else {
          const headerId = `${dateStr(targetLayout.appliedAt.toDate()).replace(
            /-/g,
            ''
          )}:${targetLayout.companyCode}`;
          targetLayout.createdAt = firebase.firestore.Timestamp.fromDate(
            new Date()
          );
          await collection.doc(headerId).set(targetLayout);
        }
        setLoading(false);
        onClose();
      } catch (error) {
        setLoading(false);
        console.log({ error });
        alert(errorMessage(error));
      }
    }
  };

  const existsDuplicateItem = (items: IncomeItemType[]) => {
    const rowsAndCols = new Set(items.map((v) => v.row * 100 + v.col));
    return rowsAndCols.size !== items.length;
  };

  const rosterItems = targetLayout.rosterItems;
  const supplyItems = targetLayout.supplyItems;
  const deductItems = targetLayout.deductItems;
  const totalItems = targetLayout.totalItems;

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <Container maxWidth="md">
          <form onSubmit={save}>
            <Paper style={{ marginBottom: 10 }}>
              <Grid container>
                <Grid item style={{ margin: 10 }}>
                  <FormControl margin="none" size="small">
                    <TextField
                      label="適用日"
                      type="date"
                      value={dateStr(targetLayout.appliedAt.toDate())}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: { style: { padding: '5px 0' } },
                      }}
                      onChange={(e) =>
                        setTargetLayout({
                          ...targetLayout,
                          appliedAt: firebase.firestore.Timestamp.fromDate(
                            new Date(e.target.value)
                          ),
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item style={{ margin: 10 }}>
                  <FormControl margin="none" size="small">
                    <InputLabel id="company-label">会社</InputLabel>
                    <Select
                      name={'companyCode'}
                      value={targetLayout.companyCode}
                      labelId="company-label"
                      onChange={changeCompanyCode}
                      className={classes.selectCompany}
                    >
                      {companyCodes.map((code, index) => (
                        <MenuItem key={index} value={code}>
                          {toHalfWidthChar(companies[code]?.name)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item style={{ margin: 10 }}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    onClick={onClose}
                    style={{ marginTop: 10 }}
                  >
                    戻る
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <Paper style={{ marginBottom: 10 }}>
              <Typography variant="h5" align="center" style={{ padding: 10 }}>
                勤怠他
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>行No.</TableCell>
                    <TableCell>列No.</TableCell>
                    <TableCell>項目名</TableCell>
                    <TableCell>0のとき非表示</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rosterItems.map((incomeItem, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Select
                            value={incomeItem.row}
                            disabled={loading}
                            variant="outlined"
                            SelectDisplayProps={{
                              style: {
                                height: 24,
                                padding: '8px 30px 2px 10px',
                              },
                            }}
                            inputProps={{
                              style: { height: 24 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.rosterItems[index].row = Number(
                                e.target.value
                              );
                              setTargetLayout(newLayout);
                            }}
                          >
                            {[1, 2].map((i) => (
                              <MenuItem key={i} value={i}>
                                {i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Select
                            value={incomeItem.col}
                            disabled={loading}
                            variant="outlined"
                            SelectDisplayProps={{
                              style: {
                                height: 24,
                                padding: '8px 30px 2px 10px',
                              },
                            }}
                            inputProps={{
                              style: { height: 24 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.rosterItems[index].col = Number(
                                e.target.value
                              );
                              setTargetLayout(newLayout);
                            }}
                          >
                            {Array.from({ length: 10 }).map((v, i) => (
                              <MenuItem key={i} value={i + 1}>
                                {i + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <TextField
                            value={incomeItem.label}
                            disabled={loading}
                            variant="outlined"
                            size="small"
                            required
                            InputLabelProps={{
                              style: { height: 30 },
                            }}
                            InputProps={{
                              style: { height: 30 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.rosterItems[index].label =
                                e.target.value;
                              setTargetLayout(newLayout);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Checkbox
                            checked={incomeItem.zeroSuppress}
                            disabled={loading}
                            size="small"
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.rosterItems[index].zeroSuppress =
                                e.target.checked;
                              setTargetLayout(newLayout);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <Button
                          variant="outlined"
                          color="inherit"
                          className={classes.button}
                          onClick={(e) => {
                            const newLayout = { ...targetLayout };
                            newLayout.rosterItems = rosterItems.filter(
                              (v, i) => i !== index
                            );
                            setTargetLayout(newLayout);
                          }}
                        >
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box p={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className={classes.button}
                  onClick={(e) => {
                    const newLayout = { ...targetLayout };
                    newLayout.rosterItems.push({
                      label: '',
                      value: '',
                      row: 1,
                      col: 1,
                      zeroSuppress: true,
                    });
                    setTargetLayout(newLayout);
                  }}
                >
                  追加
                </Button>
              </Box>
            </Paper>
            <Paper style={{ marginBottom: 10 }}>
              <Typography variant="h5" align="center" style={{ padding: 10 }}>
                支給
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>行No.</TableCell>
                    <TableCell>列No.</TableCell>
                    <TableCell>項目名</TableCell>
                    <TableCell>0のとき非表示</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {supplyItems.map((incomeItem, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Select
                            value={incomeItem.row}
                            disabled={loading}
                            variant="outlined"
                            SelectDisplayProps={{
                              style: {
                                height: 24,
                                padding: '8px 30px 2px 10px',
                              },
                            }}
                            inputProps={{
                              style: { height: 24 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.supplyItems[index].row = Number(
                                e.target.value
                              );
                              setTargetLayout(newLayout);
                            }}
                          >
                            {[1, 2].map((i) => (
                              <MenuItem key={i} value={i}>
                                {i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Select
                            value={incomeItem.col}
                            disabled={loading}
                            variant="outlined"
                            SelectDisplayProps={{
                              style: {
                                height: 24,
                                padding: '8px 30px 2px 10px',
                              },
                            }}
                            inputProps={{
                              style: { height: 24 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.supplyItems[index].col = Number(
                                e.target.value
                              );
                              setTargetLayout(newLayout);
                            }}
                          >
                            {Array.from({ length: 10 }).map((v, i) => (
                              <MenuItem key={i} value={i + 1}>
                                {i + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <TextField
                            value={incomeItem.label}
                            disabled={loading}
                            variant="outlined"
                            size="small"
                            required
                            InputLabelProps={{
                              style: { height: 30 },
                            }}
                            InputProps={{
                              style: { height: 30 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.supplyItems[index].label =
                                e.target.value;
                              setTargetLayout(newLayout);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Checkbox
                            checked={incomeItem.zeroSuppress}
                            disabled={loading}
                            size="small"
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.supplyItems[index].zeroSuppress =
                                e.target.checked;
                              setTargetLayout(newLayout);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <Button
                          variant="outlined"
                          color="inherit"
                          className={classes.button}
                          onClick={(e) => {
                            const newLayout = { ...targetLayout };
                            newLayout.supplyItems = supplyItems.filter(
                              (v, i) => i !== index
                            );
                            setTargetLayout(newLayout);
                          }}
                        >
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box p={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className={classes.button}
                  onClick={(e) => {
                    const newLayout = { ...targetLayout };
                    newLayout.supplyItems.push({
                      label: '',
                      value: '',
                      row: 1,
                      col: 1,
                      zeroSuppress: true,
                    });
                    setTargetLayout(newLayout);
                  }}
                >
                  追加
                </Button>
              </Box>
            </Paper>
            <Paper style={{ marginBottom: 10 }}>
              <Typography variant="h5" align="center" style={{ padding: 10 }}>
                控除
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>行No.</TableCell>
                    <TableCell>列No.</TableCell>
                    <TableCell>項目名</TableCell>
                    <TableCell>0のとき非表示</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deductItems.map((incomeItem, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Select
                            value={incomeItem.row}
                            disabled={loading}
                            variant="outlined"
                            SelectDisplayProps={{
                              style: {
                                height: 24,
                                padding: '8px 30px 2px 10px',
                              },
                            }}
                            inputProps={{
                              style: { height: 24 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.deductItems[index].row = Number(
                                e.target.value
                              );
                              setTargetLayout(newLayout);
                            }}
                          >
                            {[1, 2].map((i) => (
                              <MenuItem key={i} value={i}>
                                {i}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Select
                            value={incomeItem.col}
                            disabled={loading}
                            variant="outlined"
                            SelectDisplayProps={{
                              style: {
                                height: 24,
                                padding: '8px 30px 2px 10px',
                              },
                            }}
                            inputProps={{
                              style: { height: 24 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.deductItems[index].col = Number(
                                e.target.value
                              );
                              setTargetLayout(newLayout);
                            }}
                          >
                            {Array.from({ length: 10 }).map((v, i) => (
                              <MenuItem key={i} value={i + 1}>
                                {i + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <TextField
                            value={incomeItem.label}
                            disabled={loading}
                            variant="outlined"
                            size="small"
                            required
                            InputLabelProps={{
                              style: { height: 30 },
                            }}
                            InputProps={{
                              style: { height: 30 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.deductItems[index].label =
                                e.target.value;
                              setTargetLayout(newLayout);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Checkbox
                            checked={incomeItem.zeroSuppress}
                            disabled={loading}
                            size="small"
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.deductItems[index].zeroSuppress =
                                e.target.checked;
                              setTargetLayout(newLayout);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <Button
                          variant="outlined"
                          color="inherit"
                          className={classes.button}
                          onClick={(e) => {
                            const newLayout = { ...targetLayout };
                            newLayout.deductItems = deductItems.filter(
                              (v, i) => i !== index
                            );
                            setTargetLayout(newLayout);
                          }}
                        >
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box p={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className={classes.button}
                  onClick={(e) => {
                    const newLayout = { ...targetLayout };
                    newLayout.deductItems.push({
                      label: '',
                      value: '',
                      row: 1,
                      col: 1,
                      zeroSuppress: true,
                    });
                    setTargetLayout(newLayout);
                  }}
                >
                  追加
                </Button>
              </Box>
            </Paper>
            <Paper>
              <Typography variant="h5" align="center" style={{ padding: 10 }}>
                合計
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>行No.</TableCell>
                    <TableCell>列No.</TableCell>
                    <TableCell>項目名</TableCell>
                    <TableCell>0のとき非表示</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {totalItems.map((incomeItem, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Select
                            value={incomeItem.row}
                            disabled={loading}
                            variant="outlined"
                            SelectDisplayProps={{
                              style: {
                                height: 24,
                                padding: '8px 30px 2px 10px',
                              },
                            }}
                            inputProps={{
                              style: { height: 24 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.totalItems[index].row = Number(
                                e.target.value
                              );
                              setTargetLayout(newLayout);
                            }}
                          >
                            <MenuItem value={1}>{1}</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Select
                            value={incomeItem.col}
                            disabled={loading}
                            variant="outlined"
                            SelectDisplayProps={{
                              style: {
                                height: 24,
                                padding: '8px 30px 2px 10px',
                              },
                            }}
                            inputProps={{
                              style: { height: 24 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.totalItems[index].col = Number(
                                e.target.value
                              );
                              setTargetLayout(newLayout);
                            }}
                          >
                            {Array.from({ length: 10 }).map((v, i) => (
                              <MenuItem key={i} value={i + 1}>
                                {i + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <TextField
                            value={incomeItem.label}
                            disabled={loading}
                            variant="outlined"
                            size="small"
                            required
                            InputLabelProps={{
                              style: { height: 30 },
                            }}
                            InputProps={{
                              style: { height: 30 },
                            }}
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.totalItems[index].label =
                                e.target.value;
                              setTargetLayout(newLayout);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <FormControl margin="none">
                          <Checkbox
                            checked={incomeItem.zeroSuppress}
                            disabled={loading}
                            size="small"
                            onChange={(e) => {
                              const newLayout = { ...targetLayout };
                              newLayout.totalItems[index].zeroSuppress =
                                e.target.checked;
                              setTargetLayout(newLayout);
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell className={classes.denseTableCell}>
                        <Button
                          variant="outlined"
                          color="inherit"
                          className={classes.button}
                          onClick={(e) => {
                            const newLayout = { ...targetLayout };
                            newLayout.totalItems = totalItems.filter(
                              (v, i) => i !== index
                            );
                            setTargetLayout(newLayout);
                          }}
                        >
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Box p={2}>
                <Button
                  variant="outlined"
                  color="inherit"
                  className={classes.button}
                  onClick={(e) => {
                    const newLayout = { ...targetLayout };
                    newLayout.totalItems.push({
                      label: '',
                      value: '',
                      row: 1,
                      col: 1,
                      zeroSuppress: true,
                    });
                    setTargetLayout(newLayout);
                  }}
                >
                  追加
                </Button>
              </Box>
            </Paper>
            <Grid container justify="center">
              <Grid item xs={4} style={{ margin: 10 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  保存
                </Button>
              </Grid>
            </Grid>
            {messages.content.length > 0 && (
              <Alert
                message={messages.content}
                severity={messages.severity}
                onClose={() => setMessages({ content: [] })}
              />
            )}
          </form>
        </Container>
      </Box>
    </>
  );
};

export default EditLayout;
