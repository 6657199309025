import React, { useState, useContext } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';

import AppContext from './AppContext';
import NormalMenu from './NormalMenu';
import ManagerMenu from './ManagerMenu';
import AdminMenu from './AdminMenu';
import './App.css';

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { customClaims } = useContext(AppContext);
  const admin = customClaims?.role === 'admin';
  const manager = customClaims?.role === 'manager';
  const viewer = customClaims?.role === 'viewer';

  return (
    <div className="App">
      {loading && <LinearProgress />}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={5}>
            <Grid item>
              <NormalMenu setLoading={setLoading} />
            </Grid>
            {(viewer || manager || admin) && (
              <Grid item>
                <ManagerMenu setLoading={setLoading} />
              </Grid>
            )}
            {admin && (
              <Grid item>
                <AdminMenu setLoading={setLoading} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default App;
