import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import './App.css';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
  },
  button: {
    width: 200,
  },
}));

interface NormalMenuProp {
  setLoading: React.Dispatch<boolean>;
}

const App: React.FC<NormalMenuProp> = ({ setLoading }) => {
  const classes = useStyles();

  return (
    <Box mt={5}>
      <Box m={2}>
        <Link to="/incomes/mypage" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            給与明細
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/bonuses/mypage" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            賞与明細
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/gensens/mypage" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            源泉徴収票
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/users/change_password" className={classes.link}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classes.button}
          >
            パスワード変更
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/users/change_email" className={classes.link}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classes.button}
          >
            メールアドレス変更
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/users/change_phone_number" className={classes.link}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classes.button}
          >
            携帯番号変更
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default App;
