import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';
import Alert, { AlertMessage } from './Alert';
import OperationList from './OperationList';
import {
  zeroPadding,
  makeMonths,
  toHalfWidthChar,
  fullName,
  userCodeFromEmail,
  errorMessage,
} from './tools';
import AppContext from './AppContext';

const str = (text?: string | null) => (text ? String(text) : '');

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  dialog: {
    height: '100%',
    width: '100%',
    maxWidth: 'initial',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  paper: {
    padding: 20,
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
}));

interface ClearIncomeProps {
  open: boolean;
  onClose: () => void;
}

const ClearIncome: React.FC<ClearIncomeProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyCode, setCompanyCode] = useState<string>('');
  const [targetMonth, setTargetMonth] = useState<string>('');
  const [months, setMonths] = useState<[number, number][]>([]);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const { companies, customClaims, currentUser, user } = useContext(AppContext);
  const admin =
    customClaims?.role === 'admin' || customClaims?.allowAllCompanies;
  const companyCodes =
    (admin ? Object.keys(companies) : customClaims?.companies) || [];
  const classes = useStyles();
  const db = firebase.firestore();

  useEffect(() => {
    let lastMonth = new Date();
    setMonths(
      makeMonths(
        2020,
        1,
        lastMonth.getFullYear(),
        lastMonth.getMonth() + 12
      ).reverse()
    );
  }, []);

  const clearIncomes = async (e: React.FormEvent) => {
    e.preventDefault();
    if (targetMonth && companyCode) {
      setLoading(true);
      setMessages({ content: [] });
      try {
        const querySnapshot = await db
          .collectionGroup('incomes')
          .where('month', '==', targetMonth)
          .where('companyCode', '==', companyCode)
          .get();
        const targetRefs: firebase.firestore.DocumentReference[] = [];
        querySnapshot.forEach((doc) => targetRefs.push(doc.ref));
        const count = targetRefs.length;

        const headerSnapshot = await db
          .collection('incomeHeaders')
          .where('month', '==', targetMonth)
          .where('companyCode', '==', companyCode)
          .get();
        headerSnapshot.forEach((doc) => targetRefs.push(doc.ref));
        for await (const tagetRef of targetRefs) {
          await tagetRef.delete();
        }

        // データ件数更新
        await updateIncomeCounts();

        // 操作履歴
        const userCode = userCodeFromEmail(currentUser?.email || '');
        if (currentUser && userCode) {
          const userName = !!user ? fullName(user) : currentUser.email;
          await db
            .collection('users')
            .doc(userCode)
            .collection('operations')
            .add({
              userCode,
              userName,
              action: 'clearIncome',
              actionName: '給与明細クリア',
              message: `会社:${companies[companyCode].name}、対象月:${targetMonth}、件数:${count}`,
              uid: currentUser.uid,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }

        setLoading(false);
        setMessages((prev) => {
          return {
            content: prev.content.concat(
              `${count}件のデータをクリアしました。`
            ),
            severity: 'success',
          };
        });
      } catch (error) {
        setLoading(false);
        setMessages((prev) => {
          return {
            content: prev.content.concat(errorMessage(error)),
            severity: 'error',
          };
        });
      }
    }
  };

  const updateIncomeCounts = async () => {
    if (targetMonth && companyCode) {
      const db = firebase.firestore();
      try {
        const query = await db
          .collectionGroup('incomes')
          .where('month', '==', targetMonth)
          .where('companyCode', '==', companyCode)
          .get();
        await db
          .collection('incomeCounts')
          .doc(`${targetMonth}:${companyCode}`)
          .set({ targetMonth, companyCode, count: query.size });
      } catch (error) {
        console.log({ error });
        setMessages((prev) => {
          return {
            content: prev.content.concat(errorMessage(error)),
            severity: 'error',
          };
        });
      }
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <Container maxWidth="xs">
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            給与明細クリア
          </Typography>
          <Paper square className={classes.paper}>
            <form onSubmit={clearIncomes}>
              <FormControl fullWidth>
                <InputLabel id="month-label">対象月</InputLabel>
                <Select
                  fullWidth
                  value={targetMonth}
                  labelId="month-label"
                  required
                  disabled={loading}
                  onChange={(e) => setTargetMonth(String(e?.target?.value))}
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  {months.map(([year, month], index) => (
                    <MenuItem
                      key={index}
                      value={`${year}${zeroPadding(month, 2)}`}
                    >{`${year}年${zeroPadding(month, 2)}月`}</MenuItem>
                  ))}
                </Select>{' '}
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="company-label">会社</InputLabel>
                <Select
                  fullWidth
                  labelId="company-label"
                  value={str(companyCode)}
                  required
                  disabled={loading}
                  onChange={(e) => setCompanyCode(String(e?.target?.value))}
                >
                  <MenuItem value="">
                    <em></em>
                  </MenuItem>
                  {companyCodes.map((code, index) => (
                    <MenuItem key={index} value={code}>
                      {toHalfWidthChar(companies[code].name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid
                container
                direction="column"
                justify="space-around"
                alignItems="stretch"
                style={{ margin: '10px 0' }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  データを消去する
                </Button>
              </Grid>
            </form>
          </Paper>
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
        </Container>
        <OperationList showAll={false} maxCount={20} />
        {messages.content.length > 0 && (
          <Alert
            message={messages.content}
            severity={messages.severity}
            onClose={() => setMessages({ content: [] })}
          />
        )}
      </Box>
    </>
  );
};

export default ClearIncome;
