import React, { useState, useEffect, useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';

import { Income, IncomeHeader } from './types';
import { zeroPadding, errorMessage, getTargetMonths } from './tools';
import Alert from './Alert';
import IncomeViewer from './IncomeViewer';
import {
  IncomeTable,
  getIncomeLayout,
  createIncomeItems,
} from './IncomeLayout';
import AppContext from './AppContext';

import firebase from './firebase';
import 'firebase/auth';
import 'firebase/functions';
import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  spacer: {
    height: 20,
  },
  month: {
    width: 150,
  },
}));

interface IncomePageProps {
  userCode: string;
  month: string;
  backLabel: string;
  onClose: () => void;
}

const IncomePage: React.FC<IncomePageProps> = ({
  userCode,
  month,
  backLabel,
  onClose,
}) => {
  const [incomeTables, setIncomeTables] = useState<IncomeTable[]>([]);
  const [targetMonth, setTargetMonth] = useState<string>(month);
  const [endMonth, setEndMonth] = useState<string>('');
  const [enableRange, setEnableRange] = useState<boolean>(false);
  const [months, setMonths] = useState<[number, number][]>([]);
  const [messages, setMessages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { customClaims } = useContext(AppContext);
  const end_month = enableRange ? endMonth : targetMonth;
  const admin =
    customClaims?.role === 'admin' || !!customClaims?.allowAllCompanies;
  const companyCodes = customClaims?.companies;
  const classes = useStyles();

  useEffect(() => {
    const f = async () => {
      const target_months = await getTargetMonths(userCode, 'income', true);
      setMonths(
        target_months.map((month) => [+month.slice(0, 4), +month.slice(4)])
      );
    };
    f();
  }, [userCode]);

  useEffect(() => {
    queryIncomes(userCode, month, month, admin, companyCodes || []);
  }, [userCode, month, admin, companyCodes]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const queryIncomes = async (
    user_code: string,
    start_month: string,
    end_month: string,
    admin_mode: boolean,
    company_codes: string[]
  ) => {
    setIncomeTables([]);
    setMessages([]);
    if (user_code && start_month) {
      setLoading(true);
      const db = firebase.firestore();
      const userCodes = [user_code];
      const chilren = await db
        .collection('users')
        .where('parentCode', '==', user_code)
        .get();
      chilren.forEach((child) => {
        userCodes.push(child.ref.id);
      });

      let count = 0;
      for await (const user_code of userCodes) {
        try {
          const collection = db
            .collection('users')
            .doc(user_code)
            .collection('incomes');
          let query_incomes = collection
            .orderBy('month')
            .startAt(start_month)
            .endAt(end_month);
          if (!admin_mode) {
            query_incomes = query_incomes.where(
              'companyCode',
              'in',
              company_codes
            );
          }
          const incomes: Income[] = [];
          const snapIncome = await query_incomes.get();
          snapIncome.forEach((doc) => incomes.push(doc.data() as Income));

          for await (const income of incomes) {
            const docId = `${income.payday}:${income.companyCode}`;
            const snapHeader = await db
              .collection('incomeHeaders')
              .doc(docId)
              .get();
            const targetDate = new Date(
              `${income.payday.substr(0, 4)}/${income.payday.substr(
                4,
                2
              )}/${income.payday.substr(6, 2)}`
            );
            const incomeLayout = await getIncomeLayout(
              income.companyCode,
              targetDate
            );
            if (incomeLayout) {
              const income_table = createIncomeItems(
                income,
                snapHeader.data() as IncomeHeader,
                incomeLayout
              );
              if (income_table)
                setIncomeTables((prev) => prev.concat(income_table));
            } else {
              setMessages((prev) =>
                prev.concat(
                  `レイアウト情報が存在しません(会社コード:${income.companyCode}、支給日:${targetDate})。`
                )
              );
            }
          }
          count += incomes.length;
        } catch (error) {
          setMessages((prev) => prev.concat(errorMessage(error)));
        }
      }
      if (count === 0) {
        setMessages((prev) => prev.concat('データが存在しません。'));
      }
      setLoading(false);
    }
  };

  const changeTargetMonth = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const target_month = e.target.value;
      setTargetMonth(target_month);
      if (!endMonth || target_month > endMonth) {
        setEndMonth(target_month);
      }
    }
  };

  const changeEndMonth = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value && typeof e.target.value == 'string') {
      const target_month = e.target.value;
      setEndMonth(target_month);
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Container maxWidth="md">
        {messages.length > 0 && (
          <Alert message={messages} onClose={() => setMessages([])} />
        )}
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <div style={{ marginTop: 38, fontSize: '0.9rem' }}>
              {/* {user.code} {user.lastName} {user.firstName} */}
            </div>
          </Grid>
          <Grid item xs={9}>
            <FormControlLabel
              control={
                <Checkbox
                  name="manager"
                  checked={enableRange}
                  onChange={(e) => {
                    setEnableRange(e.target.checked);
                  }}
                />
              }
              label="範囲指定"
              style={{ marginTop: 27 }}
            />
            <FormControl margin="normal" className={classes.month}>
              <InputLabel id="month-label">対象月</InputLabel>
              <Select
                value={targetMonth}
                onChange={changeTargetMonth}
                labelId="month-label"
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                {months.map(([year, month]) => (
                  <MenuItem
                    key={`${year}${zeroPadding(month, 2)}`}
                    value={`${year}${zeroPadding(month, 2)}`}
                  >{`${year}年${zeroPadding(month, 2)}月`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl margin="normal" className={classes.month}>
              <Select
                value={endMonth}
                disabled={!enableRange}
                onChange={changeEndMonth}
                style={{ marginTop: 16, marginLeft: 10 }}
              >
                <MenuItem value="">
                  <em></em>
                </MenuItem>
                {months.map(([year, month]) => (
                  <MenuItem
                    key={`${year}${zeroPadding(month, 2)}`}
                    value={`${year}${zeroPadding(month, 2)}`}
                  >{`${year}年${zeroPadding(month, 2)}月`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={() =>
                queryIncomes(
                  userCode,
                  targetMonth,
                  end_month,
                  admin,
                  companyCodes || []
                )
              }
              style={{ marginTop: 30, marginLeft: 10 }}
            >
              検索
            </Button>
            {incomeTables.length > 0 && (
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                onClick={handlePrint}
                style={{ marginTop: 30, marginLeft: 10 }}
              >
                印刷
              </Button>
            )}
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={onClose}
              style={{ marginTop: 30, marginLeft: 10 }}
            >
              {backLabel}
            </Button>
          </Grid>
        </Grid>
        <div className="income_print" ref={componentRef}>
          {incomeTables.map((incomeTable, index) => (
            <IncomeViewer incomeTable={incomeTable} key={index} />
          ))}
        </div>
      </Container>
    </>
  );
};

export default IncomePage;
