import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Grid,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { errorMessage } from './tools';
import Alert from './Alert';
import SignInUserCode from './SignInUserCode';
import SignInPhone from './SignInPhone';
import ResetPassword from './ResetPassword';
import AppContext from './AppContext';
import firebase from './firebase';
import 'firebase/auth';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '0 50px',
    backgroundColor: 'white',
  },
  tab: {
    width: '50%',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    fontSize: 'calc(14px + 0.7vw)',
    paddingBottom: 20,
  },
}));

interface TabPanelProps {
  value: number;
  index: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {children}
    </div>
  );
};
const SignIn: React.FC = ({ children }) => {
  const [tab, setTab] = useState<number>(0);
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [resetPasswordToken, setResetPasswordToken] = useState('');
  const [paramToken, setParamToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const { currentUser } = useContext(AppContext);
  const search = window.location.search;
  const classes = useStyles();

  useEffect(() => {
    setAuthorized(!!firebase.auth().currentUser);
  }, [currentUser]);

  useEffect(() => {
    let token = '';
    let urlParamStr: string = search;
    if (urlParamStr) {
      urlParamStr = urlParamStr.substring(1);
      urlParamStr.split('&').forEach((p) => {
        const temp = p.split('=');
        if (temp[0] === 'resetPasswordToken' && temp[1]) token = temp[1];
      });
    }
    setParamToken(token);
    if (token) {
      setLoading(true);
      const func = firebase
        .app()
        .functions('asia-northeast1')
        .httpsCallable('checkResetPasswordToken');
      func({ token })
        .then((result) => {
          console.log({ result });
          setLoading(false);
          if (result.data) setResetPasswordToken(token);
        })
        .catch((error) => {
          console.log({ error });
          setLoading(false);
          setMessage(errorMessage(error));
          setParamToken('');
        });
    }
  }, [search]);

  if (authorized) {
    return <>{children}</>;
  } else if (paramToken) {
    return (
      <>
        {loading && <LinearProgress />}
        <ResetPassword disabled={loading} token={resetPasswordToken} />
        {message && <Alert message={message} onClose={() => setMessage('')} />}
      </>
    );
  } else {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Typography
          component="h1"
          variant="inherit"
          align="center"
          className={classes.title}
        >
          Web 給与明細
        </Typography>
        <Container maxWidth="xs">
          <Paper>
            <Tabs
              value={tab}
              onChange={(e, v) => setTab(v)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="社員番号でログイン" className={classes.tab} />
              <Tab label="携帯番号でログイン" className={classes.tab} />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <SignInUserCode />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <SignInPhone />
            </TabPanel>
          </Paper>
        </Container>
        {message && <Alert message={message} onClose={() => setMessage('')} />}
      </Grid>
    );
  }
};
export default SignIn;
