import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormControl,
  LinearProgress,
  TextField,
  Button,
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { FileCopy, Delete } from '@material-ui/icons';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';

import { errorMessage } from './tools';
import {
  GensenHeader,
  GensenLayout,
  GensenItemType,
  TextAnchor,
  DominantBaseline,
} from './types';
import Alert, { AlertMessage } from './Alert';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: 5,
  },
  select: {
    width: 120,
  },
  button: {
    padding: 0,
    margin: 2,
  },
  name: {
    width: 'calc(100% - 800px)',
  },
  x: {
    width: 80,
  },
  y: {
    width: 80,
  },
  fontSize: {
    width: 100,
  },
  textAnchor: {
    width: 120,
  },
  dominantBaseline: {
    width: 120,
  },
  additional: {
    width: 200,
  },
  action: {
    width: 100,
  },
  measure: {
    width: 150,
    margin: 5,
  },
}));

interface EditGensenLayoutProps {
  docId: string | null;
  layout: GensenLayout;
  onClose: () => void;
}

const EditGensenLayout: React.FC<EditGensenLayoutProps> = ({
  docId,
  layout,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [targetLayout, setTargetLayout] = useState<GensenLayout>(layout);
  const [messages, setMessages] = useState<AlertMessage>({ content: [] });
  const [years, setYears] = useState<number[]>([]);
  const items = targetLayout.items;
  const classes = useStyles();

  useEffect(() => {
    const f = async () => {
      const db = firebase.firestore();
      const snapshot = await db.collection('gensenHeaders').get();
      const arrYears: number[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data() as GensenHeader;
        arrYears.push(data.year);
      });
      setYears(arrYears);
    };
    f();
  }, []);

  const save = async (e: React.FormEvent) => {
    const db = firebase.firestore();
    e.preventDefault();
    if (targetLayout && targetLayout.year) {
      setLoading(true);
      try {
        const collection = db.collection('gensenLayouts');
        if (docId) {
          await collection.doc(docId).update(targetLayout);
        } else {
          await collection.doc(String(targetLayout.year)).set(targetLayout);
        }
        setLoading(false);
        // onClose();
      } catch (error) {
        setLoading(false);
        console.log({ error });
        alert(errorMessage(error));
      }
    }
  };

  const changeItem = (
    index: number,
    name:
      | 'name'
      | 'x'
      | 'y'
      | 'fontSize'
      | 'textAnchor'
      | 'dominantBaseline'
      | 'additional'
  ) => (e: React.ChangeEvent<{ value: unknown }>) => {
    if (e.target && e.target.value) {
      const items = [...targetLayout.items];
      if (name === 'x' || name === 'y' || name === 'fontSize') {
        const value = Number(e.target.value);
        if (!isNaN(value)) items[index][name] = value;
      } else if (name === 'name' || name === 'additional') {
        items[index][name] = String(e.target.value);
      } else if (name === 'textAnchor') {
        items[index].textAnchor = e.target.value as TextAnchor;
      } else if (name === 'dominantBaseline') {
        items[index].dominantBaseline = e.target.value as DominantBaseline;
      }
      setTargetLayout({
        ...targetLayout,
        items,
      });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      <Box m={5}>
        <form onSubmit={save}>
          <Paper style={{ marginBottom: 10 }}>
            <Grid container>
              <Grid item style={{ margin: 10 }}>
                <FormControl margin="none" size="small">
                  <InputLabel id="year-label">対象年度</InputLabel>
                  <Select
                    value={targetLayout.year}
                    labelId="year-label"
                    onChange={(e) =>
                      setTargetLayout({
                        ...targetLayout,
                        year: Number(e.target.value),
                      })
                    }
                    className={classes.select}
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    {years.map((y, index) => (
                      <MenuItem key={index} value={y}>{`${y}年`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item style={{ margin: 10 }}>
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  onClick={onClose}
                  style={{ marginTop: 10 }}
                >
                  戻る
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{ marginBottom: 10 }}>
            <TextField
              label="width"
              value={targetLayout.width}
              disabled={loading}
              variant="outlined"
              size="small"
              required
              onChange={(e) =>
                setTargetLayout({
                  ...targetLayout,
                  width: Number(e.target.value),
                })
              }
              className={classes.measure}
            />
            <TextField
              label="height"
              value={targetLayout.height}
              disabled={loading}
              variant="outlined"
              size="small"
              required
              onChange={(e) =>
                setTargetLayout({
                  ...targetLayout,
                  height: Number(e.target.value),
                })
              }
              className={classes.measure}
            />
            <TextField
              label="offsetX"
              value={targetLayout.offsetX}
              disabled={loading}
              variant="outlined"
              size="small"
              required
              onChange={(e) =>
                setTargetLayout({
                  ...targetLayout,
                  offsetX: Number(e.target.value),
                })
              }
              className={classes.measure}
            />
            <TextField
              label="offsetY"
              value={targetLayout.offsetY}
              disabled={loading}
              variant="outlined"
              size="small"
              required
              onChange={(e) =>
                setTargetLayout({
                  ...targetLayout,
                  offsetY: Number(e.target.value),
                })
              }
              className={classes.measure}
            />
            <TextField
              label="scaleX"
              value={targetLayout.scaleX}
              disabled={loading}
              variant="outlined"
              size="small"
              required
              onChange={(e) =>
                setTargetLayout({
                  ...targetLayout,
                  scaleX: Number(e.target.value),
                })
              }
              className={classes.measure}
            />
            <TextField
              label="scaleY"
              value={targetLayout.scaleY}
              disabled={loading}
              variant="outlined"
              size="small"
              required
              onChange={(e) =>
                setTargetLayout({
                  ...targetLayout,
                  scaleY: Number(e.target.value),
                })
              }
              className={classes.measure}
            />
          </Paper>
          <Paper style={{ marginBottom: 10 }}>
            <Table size="small">
              <colgroup>
                <col className={classes.name} />
                <col className={classes.x} />
                <col className={classes.y} />
                <col className={classes.fontSize} />
                <col className={classes.textAnchor} />
                <col className={classes.dominantBaseline} />
                <col className={classes.additional} />
                <col className={classes.action} />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>項目名</TableCell>
                  <TableCell>x 座標</TableCell>
                  <TableCell>y 座標</TableCell>
                  <TableCell>文字ｻｲｽﾞ</TableCell>
                  <TableCell>ﾃｷｽﾄ横位置</TableCell>
                  <TableCell>ﾃｷｽﾄ縦位置</TableCell>
                  <TableCell>付加情報</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.cell}>
                      <FormControl fullWidth margin="none">
                        <TextField
                          value={item.name}
                          disabled={loading}
                          variant="outlined"
                          size="small"
                          required
                          onChange={changeItem(index, 'name')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <FormControl fullWidth margin="none">
                        <TextField
                          value={item.x}
                          disabled={loading}
                          variant="outlined"
                          size="small"
                          required
                          onChange={changeItem(index, 'x')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <FormControl fullWidth margin="none">
                        <TextField
                          value={item.y}
                          disabled={loading}
                          variant="outlined"
                          size="small"
                          required
                          onChange={changeItem(index, 'y')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <FormControl fullWidth margin="none">
                        <TextField
                          value={item.fontSize}
                          disabled={loading}
                          variant="outlined"
                          size="small"
                          required
                          onChange={changeItem(index, 'fontSize')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <FormControl fullWidth margin="none" size="small">
                        <Select
                          value={item.textAnchor}
                          onChange={changeItem(index, 'textAnchor')}
                        >
                          {['start', 'middle', 'end'].map((value, index) => (
                            <MenuItem key={index} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <FormControl fullWidth margin="none" size="small">
                        <Select
                          value={item.dominantBaseline}
                          onChange={changeItem(index, 'dominantBaseline')}
                        >
                          {[
                            'auto',
                            'text-bottom',
                            'alphabetic',
                            'ideographic',
                            'middle',
                            'central',
                            'mathematical',
                            'hanging',
                            'text-top',
                          ].map((value, index) => (
                            <MenuItem key={index} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <FormControl fullWidth margin="none">
                        <TextField
                          value={item.additional}
                          disabled={loading}
                          variant="outlined"
                          size="small"
                          onChange={changeItem(index, 'additional')}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Tooltip title="コピー" aria-label="copy-item">
                        <IconButton
                          key="close"
                          color="inherit"
                          size="small"
                          onClick={(e) => {
                            setTargetLayout({
                              ...targetLayout,
                              items: [...targetLayout.items, { ...item }],
                            });
                          }}
                        >
                          <FileCopy />
                        </IconButton>
                      </Tooltip>
                      &emsp;
                      <Tooltip title="削除" aria-label="delete-item">
                        <IconButton
                          key="delete"
                          color="inherit"
                          size="small"
                          onClick={(e) => {
                            const items = [...targetLayout.items];
                            items.splice(index, 1);
                            setTargetLayout({
                              ...targetLayout,
                              items,
                            });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box p={2}>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.button}
                onClick={(e) => {
                  const new_item: GensenItemType = {
                    name: '',
                    x: 0,
                    y: 0,
                    fontSize: 12,
                    textAnchor: 'start',
                    dominantBaseline: 'auto',
                  };
                  setTargetLayout({
                    ...targetLayout,
                    items: [...targetLayout.items, new_item],
                  });
                }}
              >
                追加
              </Button>
            </Box>
          </Paper>
          <Grid container justify="center">
            <Grid item xs={4} style={{ margin: 10 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                fullWidth
              >
                保存
              </Button>
            </Grid>
          </Grid>
          {messages.content.length > 0 && (
            <Alert
              message={messages.content}
              severity={messages.severity}
              onClose={() => setMessages({ content: [] })}
            />
          )}
        </form>
      </Box>
    </>
  );
};

export default EditGensenLayout;
