import React, { useState, useEffect, useContext } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  FormControl,
  LinearProgress,
  TextField,
  Button,
  makeStyles,
} from '@material-ui/core';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';

import { nameWithCode, errorMessage, validEmail } from './tools';
import AppContext from './AppContext';
import { User } from './types';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  dialog: {
    height: '100%',
    width: '100%',
    maxWidth: 'initial',
  },
  button: {
    padding: 0,
    margin: 2,
  },
  text_area: {
    height: 240,
  },
  card: {
    backgroundColor: 'whitesmoke',
    padding: 5,
  },
}));

interface UserDialogProps {
  userCode: string | null;
  open: boolean;
  updateUser: (user_code: string, user: User, auth: boolean) => void;
  onClose: () => void;
}

const UserDialog: React.FC<UserDialogProps> = ({
  userCode,
  open,
  updateUser,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState({ email: '' });
  const [enableAuth, setEnableAuth] = useState<{
    state: boolean;
    exist: boolean;
  }>({ state: false, exist: false });
  const [existUser, setExistUser] = useState<boolean>(!!userCode);
  const [user, setUser] = useState<User>({
    code: '',
    firstName: '',
    lastName: '',
    firstKana: '',
    lastKana: '',
    email: '',
    savedBy: '',
    parentCode: '',
  });
  const { currentUser, user: userInfo } = useContext(AppContext);
  const classes = useStyles();

  useEffect(() => {
    const f = async () => {
      if (currentUser && userCode) {
        setLoading(true);
        const db = firebase.firestore();
        const doc = await db.collection('users').doc(userCode).get();
        setUser(doc.data() as User);

        // 認証情報
        const func = firebase
          .app()
          .functions('asia-northeast1')
          .httpsCallable('existAuthUsers');
        const result = await func({ userCodes: [userCode] });
        const exist = result.data.includes(userCode);
        setEnableAuth({ state: exist, exist });
      } else {
        resetUser();
      }
      setLoading(false);
    };
    f();
  }, [currentUser, userCode]);

  const currentUserName = () => {
    let name = nameWithCode(userInfo);
    if (!name) name = currentUser?.uid || '';
    return name;
  };

  const resetUser = () => {
    setUser({
      code: '',
      firstName: '',
      lastName: '',
      firstKana: '',
      lastKana: '',
      email: '',
      savedBy: '',
      parentCode: '',
    });
    setEnableAuth((prev) => ({ ...prev, state: false }));
  };

  const save = async () => {
    const errs = {
      email: '',
    };
    if (user.email && !validEmail(user.email)) {
      errs.email = 'メールアドレスの形式が正しくありません。';
    }
    setErrors(errs);
    const existErrors = Object.values(errs).filter((err) => !!err).length > 0;
    if (!existErrors) {
      const db = firebase.firestore();
      const functions = firebase.app().functions('asia-northeast1');
      const savedBy = currentUserName();
      if (user && user.code) {
        try {
          setLoading(true);
          let existAuth = enableAuth.exist;
          // 存在チェック(認証データ)
          let errMessages: string[] = [];
          if (!enableAuth.exist) {
            const getAuthUserByCode = functions.httpsCallable(
              'getAuthUserByCode'
            );
            const res = await getAuthUserByCode({ code: user.code });
            if (res.data && res.data.result) {
              setEnableAuth({ state: true, exist: true });
              errMessages.push('認証データが既に存在します。');
              existAuth = true;
            }
          }
          // 存在チェック(社員情報)
          if (!userCode && !existUser) {
            const userSnap = await db.collection('users').doc(user.code).get();
            const doc = userSnap?.data();
            if (doc) {
              setExistUser(true);
              setUser(doc as User);
              errMessages.push('社員情報が既に存在します。');
            }
          }
          if (errMessages.length > 0) throw Error(errMessages.join('\n'));

          // 認証データ作成
          if (!enableAuth.exist && enableAuth.state) {
            const addAuthUser = functions.httpsCallable('addAuthUser');
            const result = await addAuthUser({ userCode: user.code });
            console.log({ result });
            if (result.data.result) {
              existAuth = true;
              onClose();
            } else {
              throw Error('ユーザの作成に失敗しました。');
            }
          }
          // 社員情報保存
          await db
            .collection('users')
            .doc(user.code)
            .set({ ...user, savedBy });
          // データ件数更新(新規作成時)
          const query = await db.collection('users').get();
          await db
            .collection('userCounts')
            .doc('all')
            .set({ count: query.size });
          // 一覧画面に反映
          updateUser(user.code, { ...user, savedBy }, existAuth);
          setLoading(false);
          onClose();
        } catch (error) {
          console.log({ error });
          setLoading(false);
          alert(errorMessage(error));
        }
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth="sm"
      fullWidth
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {loading && <LinearProgress />}
      <DialogContent>
        <form>
          <FormControl fullWidth>
            <TextField
              label="社員番号"
              name="code"
              value={user?.code}
              disabled={
                !!(loading || userCode || enableAuth.exist || existUser)
              }
              onChange={(e) => setUser({ ...user, code: e.target.value })}
            />
          </FormControl>
          <FormControl fullWidth>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="氏"
                  name="lastName"
                  fullWidth
                  value={user?.lastName}
                  disabled={loading}
                  onChange={(e) =>
                    setUser({ ...user, lastName: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="名"
                  name="firstName"
                  fullWidth
                  value={user?.firstName}
                  disabled={loading}
                  onChange={(e) =>
                    setUser({ ...user, firstName: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl fullWidth>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="シ"
                  name="lastKana"
                  fullWidth
                  value={user?.lastKana}
                  disabled={loading}
                  onChange={(e) =>
                    setUser({ ...user, lastKana: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="メイ"
                  name="firstKana"
                  fullWidth
                  value={user?.firstKana}
                  disabled={loading}
                  onChange={(e) =>
                    setUser({ ...user, firstKana: e.target.value })
                  }
                />
              </Grid>
            </Grid>
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="Email"
              name="email"
              value={user?.email}
              disabled={loading}
              error={!!errors.email}
              helperText={errors.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label="親コード"
              name="parentCode"
              value={user?.parentCode}
              disabled={loading}
              onChange={(e) => setUser({ ...user, parentCode: e.target.value })}
            />
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              key={0}
              control={
                <Checkbox
                  name="enableAuth"
                  checked={enableAuth.state}
                  disabled={loading || enableAuth.exist}
                  onChange={(e) =>
                    setEnableAuth({ ...enableAuth, state: e.target.checked })
                  }
                />
              }
              label="ログイン可"
            />
          </FormControl>
          <Grid
            container
            direction="column"
            justify="space-around"
            alignItems="stretch"
            style={{ margin: '10px 0' }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={save}
            >
              保存
            </Button>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UserDialog;
