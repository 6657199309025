import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import AppContext from './AppContext';
import { userCodeFromEmail } from './tools';

import firebase from './firebase';
import 'firebase/auth';
import 'firebase/firestore';
import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  paper: {
    padding: 10,
    backgroundColor: 'white',
  },
  table: {
    tableLayout: 'fixed',
    marginTop: 20,
  },
  createdAt: {
    width: '15%',
  },
  actionName: {
    width: '18%',
  },
  userName: {
    width: '17%',
  },
  message: {
    width: '50%',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    color: '#4d4d4d',
    fontWeight: 'bold',
    paddingBottom: 20,
  },
  importIncome: {
    backgroundColor: 'lightyellow',
  },
  clearIncome: {
    backgroundColor: 'lightskyblue',
  },
  importBonus: {
    backgroundColor: 'gold',
  },
  clearBonus: {
    backgroundColor: 'deepskyblue',
  },
  importGensen: {
    backgroundColor: 'orange',
  },
  clearGensen: {
    backgroundColor: 'mediumslateblue',
  },
  importPayment: {
    backgroundColor: 'chocolate',
  },
  clearPayment: {
    backgroundColor: 'steelblue',
  },
}));

interface OperationListProps {
  showAll: boolean;
  maxCount: number;
}

const OperationList: React.FC<OperationListProps> = ({
  showAll = true,
  maxCount = 100,
}) => {
  const [operations, setOperations] = useState<
    firebase.firestore.QueryDocumentSnapshot[]
  >([]);
  const { currentUser, customClaims } = useContext(AppContext);
  const admin = customClaims?.role === 'admin';
  const classes = useStyles();

  useEffect(() => {
    if (currentUser) {
      try {
        if (showAll && admin) {
          firebase
            .firestore()
            .collectionGroup('operations')
            .orderBy('createdAt', 'desc')
            .limit(maxCount)
            .onSnapshot((snapshot) => {
              setOperations(snapshot.docs);
            });
        } else {
          const userCode = userCodeFromEmail(currentUser.email || '');
          if (userCode) {
            firebase
              .firestore()
              .collection('users')
              .doc(userCode)
              .collection('operations')
              .orderBy('createdAt', 'desc')
              .limit(maxCount)
              .onSnapshot((snapshot) => {
                setOperations(snapshot.docs);
              });
          }
        }
      } catch (error) {
        console.log({ error });
      }
    }
  }, [currentUser, maxCount, showAll, admin]);

  return (
    <Box m={5}>
      <Container maxWidth="lg">
        {showAll && (
          <Typography
            component="h2"
            variant="inherit"
            align="center"
            className={classes.title}
          >
            操作履歴
          </Typography>
        )}
        <Paper className={classes.paper}>
          {!showAll && (
            <Typography component="h2" variant="body2" align="center">
              操作履歴
            </Typography>
          )}
          <Table size="small" className={classes.table}>
            <colgroup>
              <col className={classes.createdAt} />
              <col className={classes.actionName} />
              <col className={classes.userName} />
              <col className={classes.message} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <small>操作日時</small>
                </TableCell>
                <TableCell>操作名</TableCell>
                <TableCell>氏名</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {operations.map((doc, index) => {
                const operation = doc.data();
                const action = operation.action as
                  | 'importIncome'
                  | 'clearIncome';
                return (
                  <TableRow key={index} className={classes[action]}>
                    <TableCell>
                      {operation.createdAt?.toDate()?.toLocaleDateString()}
                      &nbsp;
                      {operation.createdAt?.toDate()?.toLocaleTimeString()}
                    </TableCell>
                    <TableCell>{operation.actionName}</TableCell>
                    <TableCell>{operation.userName}</TableCell>
                    <TableCell>{operation.message}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        {showAll && (
          <Box mt={2}>
            <Link to="/" className={classes.link}>
              <Button variant="outlined" color="inherit" size="medium">
                トップ画面に戻る
              </Button>
            </Link>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default OperationList;
