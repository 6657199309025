import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AppContext from './AppContext';
import App from './App';
import TopBar from './TopBar';
import UserList from './UserList';
import IncomeList from './IncomeList';
import GensenList from './GensenList';
import BonusList from './BonusList';
import CompanyList from './CompanyList';
import IncomeLayoutList from './IncomeLayoutList';
import GensenLayoutList from './GensenLayoutList';
import BonusLayoutList from './BonusLayoutList';
import ChangePassword from './ChangePassword';
import ChangePhoneNumber from './ChangePhoneNumber';
import ChangeEmail from './ChangeEmail';
import GeneratePassword from './GeneratePassword';
import ImportIncome from './ImportIncome';
import ImportBonus from './ImportBonus';
import ImportGensen from './ImportGensen';
import ImportPayment from './ImportPayment';
import ImportUser from './ImportUser';
import ClearIncome from './ClearIncome';
import ClearBonus from './ClearBonus';
import ClearGensen from './ClearGensen';
import ExportIncome from './ExportIncome';
import MyIncomePage from './MyIncomePage';
import MyBonusPage from './MyBonusPage';
import MyGensenPage from './MyGensenPage';
import OperationList from './OperationList';
import NotFound from './NotFound';
import './index.css';
import ImportRevision from './ImportRevision';

const AuthRouter: React.FC = () => {
  const { customClaims } = useContext(AppContext);
  const role = customClaims?.role;
  const admin = role === 'admin';
  const manager = admin || role === 'manager';
  const viewer = manager || role === 'viewer';

  return (
    <Router>
      <Route path="/" component={TopBar}></Route>
      <Switch>
        <Route exact path="/" component={App}></Route>
        {admin && <Route exact path="/users/list" component={UserList}></Route>}
        {admin && (
          <Route exact path="/users/import" component={ImportUser}></Route>
        )}
        {admin && (
          <Route
            exact
            path="/users/generate_password"
            component={GeneratePassword}
          ></Route>
        )}
        {admin && (
          <Route exact path="/companies/list" component={CompanyList}></Route>
        )}
        {admin && (
          <Route
            exact
            path="/incomeLayouts/list"
            component={IncomeLayoutList}
          ></Route>
        )}
        {admin && (
          <Route
            exact
            path="/bonusLayouts/list"
            component={BonusLayoutList}
          ></Route>
        )}
        {admin && (
          <Route
            exact
            path="/gensenLayouts/list"
            component={GensenLayoutList}
          ></Route>
        )}
        {admin && (
          <Route
            exact
            path="/operations/list"
            component={OperationList}
          ></Route>
        )}
        {manager && (
          <Route exact path="/incomes/import" component={ImportIncome}></Route>
        )}
        {manager && (
          <Route exact path="/incomes/clear" component={ClearIncome}></Route>
        )}
        {manager && (
          <Route
            exact
            path="/incomes/exportIncome"
            component={ExportIncome}
          ></Route>
        )}
        {manager && (
          <Route exact path="/bonuses/import" component={ImportBonus}></Route>
        )}
        {manager && (
          <Route exact path="/bonuses/clear" component={ClearBonus}></Route>
        )}
        {manager && (
          <Route exact path="/gensens/import" component={ImportGensen}></Route>
        )}
        {manager && (
          <Route
            exact
            path="/payments/import"
            component={ImportPayment}
          ></Route>
        )}
        {manager && (
          <Route exact path="/gensens/clear" component={ClearGensen}></Route>
        )}
        {viewer && (
          <Route exact path="/incomes/list" component={IncomeList}></Route>
        )}
        {viewer && (
          <Route exact path="/bonuses/list" component={BonusList}></Route>
        )}
        {viewer && (
          <Route exact path="/gensens/list" component={GensenList}></Route>
        )}
        <Route exact path="/users/change_email" component={ChangeEmail}></Route>
        <Route
          exact
          path="/users/change_phone_number"
          component={ChangePhoneNumber}
        ></Route>
        <Route
          exact
          path="/users/change_password"
          component={ChangePassword}
        ></Route>
        <Route exact path="/incomes/mypage" component={MyIncomePage}></Route>
        <Route
          exact
          path="/incomes/mypage/:month/:token"
          component={MyIncomePage}
        ></Route>
        <Route exact path="/bonuses/mypage" component={MyBonusPage}></Route>
        <Route exact path="/gensens/mypage" component={MyGensenPage}></Route>
        <Route
          exact
          path="/incomes/import_revision"
          component={ImportRevision}
        ></Route>
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AuthRouter;
