import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  link: {
    textDecoration: 'none',
  },
  button: {
    width: 200,
    borderColor: '#FF0000',
    color: '#FF0000',
    '&:hover': {
      borderColor: '#FF000080',
      color: '#FF000080',
    },
  },
}));

interface AdminMenuProp {
  setLoading: React.Dispatch<boolean>;
}

const AdminMenu: React.FC<AdminMenuProp> = () => {
  const classes = useStyles();

  return (
    <Box mt={5}>
      <Box m={2}>
        <Link to="/companies/list" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            会社一覧
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/users/list" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            社員一覧
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/users/import" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            社員情報取込
          </Button>
        </Link>
      </Box>
      <Box m={2}>&nbsp;</Box>
      <Box m={2}>
        <Link to="/incomeLayouts/list" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            給与明細レイアウト
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/bonusLayouts/list" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            賞与明細レイアウト
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/gensenLayouts/list" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            源泉徴収レイアウト
          </Button>
        </Link>
      </Box>
      <Box m={2}>&nbsp;</Box>
      <Box m={2}>
        <Link to="/incomes/import_revision" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            職能給明細取込
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/operations/list" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            操作履歴
          </Button>
        </Link>
      </Box>
      <Box m={2}>
        <Link to="/users/generate_password" className={classes.link}>
          <Button
            variant="outlined"
            color="inherit"
            size="large"
            className={classes.button}
          >
            パスワード一括生成
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default AdminMenu;
