import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { warekiFromYear } from './tools';
import { Gensen, GensenHeader, GensenLayout, Company } from './types';
import AppContext from './AppContext';

import firebase from './firebase';
import 'firebase/firestore';
import 'firebase/auth';

import './App.css';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
  },
  frame: {
    border: '3px solid black',
    boxSizing: 'border-box',
    padding: 0,
    margin: 0,
  },
  cell: {
    border: '1px solid black',
    boxSizing: 'border-box',
    fontSize: 12,
    padding: 0,
    margin: 0,
  },
  center: {
    textAlign: 'center',
  },
  centerV: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alert: {
    margin: 20,
    backgroundColor: 'orangered',
  },
  view: {
    position: 'relative',
    margin: 0,
    padding: 0,
    pageBreakAfter: 'always',
    '@media print': {
      height: '100vh !important',
    },
  },
}));

interface GensenViewerProps {
  year: number;
  gensen: Gensen;
  gensenHeader: GensenHeader;
}

const GensenViewer: React.FC<GensenViewerProps> = ({
  year,
  gensen,
  gensenHeader,
}) => {
  const [gensenLayout, setGensenLayout] = useState<GensenLayout | null>(null);
  const [templateUrl, setTemplateUrl] = useState<string>('');
  const [height, setHeight] = useState<number | string>('1vh');
  const [company, setCompany] = useState<Company | null>(null);
  const svgId = `${gensen.year}-${gensen.companyCode}`;
  const classes = useStyles();

  const registeredListener = useCallback(() => {
    const elem = document.getElementById(svgId);
    if (elem && gensenLayout && gensenLayout.width) {
      let client_w = elem.clientWidth;
      const h = (gensenLayout.height / gensenLayout.width) * client_w;
      setHeight(h);
    }
  }, [svgId, gensenLayout]);

  useEffect(() => {
    if (gensenLayout) {
      registeredListener();
      window.addEventListener('resize', registeredListener);
      return () => window.removeEventListener('resize', registeredListener);
    }
  }, [gensenLayout, registeredListener]);

  useEffect(() => {
    const f = async () => {
      if (year) {
        try {
          const storage = firebase.storage();
          const pathReference = storage.ref(`gensens/${year}.svg`);
          const url = await pathReference.getDownloadURL();
          setTemplateUrl(url || '');
        } catch (error) {
          console.log({ error });
          alert(error.message);
        }
      } else {
        setTemplateUrl('');
      }
    };
    f();
  }, [year]);

  useEffect(() => {
    const f = async () => {
      const db = firebase.firestore();
      if (year) {
        try {
          // レイアウト
          const snapshot = await db
            .collection('gensenLayouts')
            .doc(String(year))
            .get();
          const data = snapshot.data();
          if (data) {
            const layout = data as GensenLayout;
            setGensenLayout(layout);
          }
        } catch (error) {
          console.log({ error });
          alert(error.message);
        }
      } else {
        setGensenLayout(null);
      }
    };
    f();
  }, [year]);

  useEffect(() => {
    const f = async () => {
      const db = firebase.firestore();
      if (gensen.companyCode) {
        try {
          // 会社情報
          const query = await db
            .collection('companies')
            .doc(gensen.companyCode)
            .get();
          setCompany(query.data() as Company);
        } catch (error) {
          console.log({ error });
          alert(error.message);
        }
      } else {
        setGensenLayout(null);
      }
    };
    f();
  }, [gensen.companyCode]);

  const extractTimestamp = (
    t: firebase.firestore.Timestamp,
    additional: string
  ) => {
    const date = toDate(t);
    switch (additional) {
      case 'era':
      case 'j-year':
        const wareki = warekiFromYear(date);
        if (wareki) {
          if (additional === 'era') return wareki.era;
          if (additional === 'j-year') return wareki.year;
        }
        return '';
      case 'month':
        return String(date.getMonth() + 1);
      case 'day':
        return String(date.getDate());
    }
    return '';
  };

  const toDate = (t: firebase.firestore.Timestamp) => {
    // const date = t.toDate();
    const seconds = t.seconds || (t as any)._seconds; // for cloud functions
    const nanoseconds = 0;
    const t2 = new firebase.firestore.Timestamp(seconds, nanoseconds);
    const date = t2.toDate();
    return date;
  };

  const toString = (value: string | firebase.firestore.Timestamp) => {
    if (typeof value === 'string') {
      return value;
    } else {
      const date = toDate(value);
      const wareki = warekiFromYear(date);
      return wareki
        ? `${wareki.era}${wareki.year}年${
            date.getMonth() + 1
          }月${date.getDate()}日`
        : '';
    }
  };

  const mergeShortWord = (base: string, building: string) => {
    return building.trim().length <= 5
      ? [base + building, '']
      : [base, building.trim()];
  };

  // 住所と建物に分割
  // 数字の入った市区町村(https://uub.jp/zat/suji.html)
  const getAddress = (orgAddress: string) => {
    let address = orgAddress;
    address = address.replace(/^〒[0-9０-９]{3}-?[0-9０-９]{4}/, '').trim();

    // ?: => キャプチャ除外
    const num = '(?:[0-9０-９])'; // 漢数字除外 => 数字の入った市区町村のため
    const sep = '(?:番町|丁目|丁|番地|番|号|階|棟|の)';
    const hyphen = '(?:-|‐|–|ー|−|－)';

    const reg = `(${num}|${sep}|${hyphen}){2,}`;
    const m = address.match(new RegExp(reg));
    if (m && typeof m.index !== 'undefined') {
      const base = address.slice(0, m.index + m[0].length);
      const building = address.slice(base.length);
      // base が - で終わっている場合
      if (base.match(new RegExp(`${hyphen}$`))) {
        const num2 = '(?:[0-9０-９a-zａ-ｚA-ZＡ-Ｚ])';
        const reg2 = `^\s*(${num2}|${hyphen})*\s*`;
        const m2 = building.match(new RegExp(reg2));
        if (m2 && typeof m2.index !== 'undefined') {
          const base2 = building.slice(0, m2.index + m2[0].length);
          const building2 = building.slice(base2.length);
          return mergeShortWord(base + base2, building2);
        } else {
          return mergeShortWord(base, building);
        }
      } else {
        return mergeShortWord(base, building);
      }
    } else {
      return [address, ''];
    }
  };

  const getGensenValue = (name: string, additional?: string) => {
    const index = gensenHeader.contents.findIndex(
      (itemName) => itemName === name
    );
    if (index >= 0 || name === 'none') {
      const value = gensen.contents[index];
      const params = String(additional).split(',');
      const func = params[0];
      const args = params.splice(1);
      switch (func) {
        case 'era':
        case 'j-year':
        case 'month':
        case 'day':
          return value
            ? extractTimestamp(value as firebase.firestore.Timestamp, func)
            : '';
        case 'check-meiji':
        case 'check-taisyo':
        case 'check-syowa':
        case 'check-heisei':
        case 'check-reiwa':
          const eras = {
            'check-meiji': '明治',
            'check-taisyo': '大正',
            'check-syowa': '昭和',
            'check-heisei': '平成',
            'check-reiwa': '令和',
          };
          if (value) {
            const era = extractTimestamp(
              value as firebase.firestore.Timestamp,
              'era'
            );
            return era === eras[func] ? '○' : '';
          } else {
            return '';
          }
        case 'exist':
          return !!value ? '○' : '';
        case 'names':
          return args
            .map((name) => {
              const index2 = gensenHeader.contents.findIndex(
                (name2) => name === name2
              );
              return String(gensen.contents[index2] ? name : '');
            })
            .filter((name) => !!name)
            .join('　');
        case 'labeled-values': // args[0]: prefix, args[1]: suffix, args[2]: header-name, args[3]: prefix,...
          if (args.length > 3) {
            const results: string[] = [];
            for (let i = 0; i < args.length / 3; i++) {
              const prefix = args[3 * i];
              const suffix = args[3 * i + 1];
              const name = args[3 * i + 2];
              const index2 = gensenHeader.contents.findIndex(
                (name2) => name === name2
              );
              const value = gensen.contents[index2];
              if (value) results.push(prefix + toString(value) + suffix);
            }

            return results.join('　');
          } else {
            return '';
          }
        case 'address':
          return getAddress(value as string)[0];
        case 'building':
          return getAddress(value as string)[1];
        case 'comapany-address':
          return String(company?.address);
        case 'comapany-name':
          return String(company?.formalName);
        case 'comapany-tel':
          return String(company?.tel);
        case 'target-year':
          const wareki = warekiFromYear(new Date(year, 11, 31)); // 12/31 時点での年号
          return wareki ? String(wareki.year) : '';
        default:
          return String(gensen.contents[index]);
      }
    } else {
      return 'x';
    }
  };

  return (
    <Box
      id={svgId}
      className={classes.view}
      style={{
        height,
      }}
    >
      {gensenLayout && templateUrl && company && (
        <>
          <svg
            version="1.1"
            x="0"
            y="0"
            width="100%"
            viewBox={`${gensenLayout.offsetX} ${gensenLayout.offsetY} ${
              gensenLayout.width / gensenLayout.scaleX
            } ${gensenLayout.height / gensenLayout.scaleY}`}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1000,
            }}
          >
            {gensenLayout.items.map((item, index) => (
              <text
                key={index}
                x={item.x}
                y={item.y}
                fontSize={item.fontSize}
                textAnchor={item.textAnchor}
                dominantBaseline={item.dominantBaseline}
              >
                {getGensenValue(item.name, item.additional)}
              </text>
            ))}
          </svg>
          <img
            src={templateUrl}
            alt="源泉徴収票"
            width="100%"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 0,
            }}
          ></img>
        </>
      )}
    </Box>
  );
};

export default GensenViewer;
